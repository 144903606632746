export interface ActivityItem {
  user_id: number
  timestamp_ms: number
  datetime: string
  quantity: number // number of gems or tickets allocated
  reason: string // short description of the activity
  icon_url?: string // URL of the icon to display for custom task actions
}

export interface FriendActivity {
  user_id: number
  username: string
  joined: string // ex- "2025-02-05 15:01:52"
  gems_earned: number
  verified: boolean
  last_game_played: string; // ex- "2025-02-05 15:01:52" or returns null if no tokens ever awarded
}

export enum ACTIVITY_TYPES {
  GEMS = "Gems",
  TICKETS = "Tickets"
}

export enum FRIEND_ACTIVITIES {
  JOINED = "Joined",
  VERIFIED = "Verified",
  GEMS_EARNED = "Earning",
  INACTIVE = "Inactive"
}