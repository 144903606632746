const ArrowButton = ({
  className = "",
  direction = "left",
  disabled = false
}: {
  className?: string
  direction?: "up" | "down" | "left" | "right"
  disabled?: boolean
}) => {
  const rotationValues = {
    up: "rotate(-90 36 27)",
    down: "rotate(90 36 27)",
    left: "", // No rotation needed for default left arrow
    right: "rotate(180 36 27)"
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="66"
      viewBox="0 0 74 66"
      fill="none"
      className={className}
    >
      <g id="Button">
        <rect
          id="Rectangle 355"
          width="58"
          height="4"
          transform="matrix(-1 0 0 1 66 0)"
          fill="black"
        />
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66 4H8V8H4V44H8V48H66V44H70V8H66V4Z"
          fill={disabled ? "#BCBEC0" : "#FF6A3C"}
        />
        <rect
          id="Rectangle 358"
          width="58"
          height="4"
          transform="matrix(-1 0 0 1 66 62)"
          fill="black"
        />
        <rect
          id="Rectangle 363"
          width="58"
          height="4"
          transform="matrix(-1 0 0 1 66 48)"
          fill={disabled ? "#D1D3D4" : "#FF9D00"}
        />
        <rect
          id="Rectangle 356"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 70 4)"
          fill="black"
        />
        <rect
          id="Rectangle 366"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 8 4)"
          fill="black"
        />
        <rect
          id="Rectangle 359"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 70 58)"
          fill="black"
        />
        <rect
          id="Rectangle 364"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 70 44)"
          fill={disabled ? "#D1D3D4" : "#FF9D00"}
        />
        <rect
          id="Rectangle 361"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 8 58)"
          fill="black"
        />
        <rect
          id="Rectangle 365"
          width="4"
          height="4"
          transform="matrix(-1 0 0 1 8 44)"
          fill={disabled ? "#D1D3D4" : "#FF9D00"}
        />
        <path
          id="Union_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70 48H66V52H8V48H4V58H8V62H66V58H70V48Z"
          fill={disabled ? "#888A8E":"#E63A00"}
        />
        <rect
          id="Rectangle 357"
          width="4"
          height="50"
          transform="matrix(-1 0 0 1 74 8)"
          fill="black"
        />
        <rect
          id="Rectangle 362"
          width="4"
          height="50"
          transform="matrix(-1 0 0 1 4 8)"
          fill="black"
        />

        {/* Arrow */}
        <path
          id="Union_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5 14.75H43H39.5V18.25H36H32.5V21.75H29H25.5V25.25H22V28.75H25.5V32.25H29H32.5V35.75H36H39.5V39.25H43H46.5V35.75H50V18.25H46.5V14.75Z"
          fill="white"
          transform={rotationValues[direction]}
        />
      </g>
    </svg>
  );
};

export default ArrowButton;
