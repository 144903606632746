import Box from "../Box";
import Close from "../icons/Close";

interface PopUpModalProps {
  content: JSX.Element
  bottomBorderColor?: string
  bottomAccentColor?: string
  topColor?: string
  backgroundColor?: string
  backgroundImage?: string
  closeModal?: () => void
}

const PopUpModal = ({
  content,
  bottomBorderColor = "black",
  bottomAccentColor = "#D1D3D4",
  topColor = "white",
  backgroundColor = "white",
  backgroundImage,
  closeModal,
}: PopUpModalProps) => {
  return (
    <section className="flex items-center justify-center h-full w-full absolute top-0 left-0">
      <div className="fixed top-0 left-0 w-full h-full overflow-hidden bg-black bg-opacity-80 z-30" />
      <div className="z-40 relative">
        {closeModal && (
          <button
            className="absolute z-50 top-[16px] right-[16px] cursor-pointer"
            onClick={() => closeModal()}
          >
            <Close fill="white" />
          </button>
        )}
        <Box
          content={content}
          bottomBorderColor={bottomBorderColor}
          bottomAccentColor={bottomAccentColor}
          topColor={topColor}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundImage}
        />
      </div>
    </section>
  );
};

export default PopUpModal;
