export enum COMMUNITY_TABS {
  INVITE = "Invite Friends",
  LEADERBOARDS = "Leaderboards"
}

export enum RANKING_TYPES {
  TOP_EARNERS = "Top Earners",
  ICE_SNAKE = "Ice Snake",
  GLOVE_HERO = "Glove Hero"
}

export type RankingRecord = {
  username: string;
  rank: number;
  score: number;
}
