export enum NAVIGATION_TABS {
  PLAY = "Play",
  EARN = "Earn",
  COMMUNITY = "Community",
  ACCOUNT = "Account",
  ACTIVITY_LOG = "Activity Log"
}

export enum GAME_SCREENS {
  GLOVE_HERO = "Glove Hero",
  ICE_SNAKE = "Ice Snake"
}

export enum GAMES {
  GLOVE_HERO = "glove-hero",
  ICE_SNAKE = "ice-snake"
}

export enum GAME_SCORE_STORAGE_KEYS {
  GLOVE_HERO_HIGH_SCORE = "glove-hero-high-score",
  GLOVE_HERO_LAST_SCORE = "glove-hero-last-score",
  ICE_SNAKE_HIGH_SCORE = "ice-snake-high-score",
  ICE_SNAKE_LAST_SCORE = "ice-snake-last-score"
}
