import React from "react";

interface SortDropdownProps {
  options: { value: string; label: string }[];
  selected: string;
  onSortChange: (value: string) => void;
  className?: string;
}

const SortDropdown: React.FC<SortDropdownProps> = ({
  options,
  selected,
  onSortChange,
}) => {
  return (
    <div className={"relative flex flex-row items-center"}>
      <div className="text-white pr-2">Sort by</div>
      <select
        className="bg-black text-white border-2 border-white py-2 px-4 rounded text-lg focus:outline-none focus:ring-2 focus:ring-orange-400"
        value={selected}
        onChange={(e) => onSortChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SortDropdown;
