import { useMemo } from "react";
import Button from "../Form/Button";
import Football from "../icons/Football";
import HockeyPuck from "../icons/HockeyPuck";
import HockeyStick from "../icons/HockeyStick";
import SoccerBall from "../icons/SoccerBall";
import Streak from "./Streak";
import RewardTickets from "./RewardTickets";
import useStore from "../../lib/store";
import { useAuth } from "../../providers/AuthProvider";

const DailyReward = () => {
  const {
    dailyRewardTickets,
    setIsClaimingDailyReward,
  } = useAuth();

  const streak = useStore(state => state.streak);
  // Rewards are calculated in 1 week increments, ensure adjustedStreak is between 1 and 7
  const adjustedStreak = useMemo(() => {
    try {
      return ((streak - 1) % 7) + 1;
    } catch (error) {
      console.error("Error adjusting streak", error);
      return 1;
    }
  }, [streak]);

  const getStreakHeading = (streak: number) => {
    if (streak === 7) {
      return "You've had a streak for 7 days!";
    }
    if (7 > streak && streak > 1) {
      return `You're on Day ${streak} of your streak!`;
    }
    return "First day of your streak!";
  };

  const getStreakSubheading = (streak: number) => {
    if (streak < 7) {
      return "Rewards get bigger as your daily streak continues. Check in tomorrow for your next reward!";
    }
    return "That’s the biggest reward you can get! Congratulations! Tomorrow we will reset at one day!";
  };

  return (
    <section className="bg-[url('/image/purple-background.png')] bg-cover bg-center h-screen w-full">
      {/* BACKGROUND CONTAINER WITH FLOATING SPORTS OBJECTS */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <div className="absolute top-4 left-11">
          <HockeyPuck className="absolute top-8 left-0" />
          <HockeyStick className="absolute top-0 left-3" />
        </div>
        <SoccerBall width={58} height={58} className="absolute top-4 -right-4" />
        <Football className="absolute top-72 left-20" />
        <SoccerBall width={58} height={58} className="absolute bottom-14 -left-4" />
        <div className="absolute bottom-10 left-1/3 -rotate-[60deg]">
          <HockeyPuck className="absolute top-8 left-0" />
          <HockeyStick className="absolute top-0 left-3" />
        </div>
        <Football className="absolute bottom-10 -right-6 -rotate-45" />
      </div>
      {/* MAIN CONTENT */}
      <div className="flex flex-col items-center justify-between pt-20 px-2 h-screen text-secondaryWhite">
        <Streak streak={adjustedStreak} />
        <div className="flex flex-col w-[319px] z-10 -mt-10">
          <h1 className="text-2xl text-center">{getStreakHeading(adjustedStreak)}</h1>
          <p className="text-center">{getStreakSubheading(adjustedStreak)}</p>
        </div>
        <RewardTickets dailyRewardTickets={dailyRewardTickets} />
        <div className="h-[100px] z-10">
          <Button onClick={() => setIsClaimingDailyReward(false)} text="Claim" />
        </div>
      </div>
    </section>
  );
};

export default DailyReward;
