import InviteFriends from "./InviteFriends";
import Close from "../icons/Close";
import Gem from "../icons/Gem";
import InviteButton from "./InviteButton";
import Box from "../Box";

interface ReferralLinkProps {
  setIsDisplayingReferralCodeModal: (value: boolean) => void
}

const ReferralLinkModal = ({ setIsDisplayingReferralCodeModal }: ReferralLinkProps) => {
  const contents = (
    <>
      <button
        onClick={() => setIsDisplayingReferralCodeModal(false)}
        className="absolute top-4 right-4"
      >
        <Close />
      </button>
      <div className="flex flex-col bg-secondaryGrey w-full text-2xl items-center pb-2">
        <div>Play with friends,</div>
        <div className="flex items-center justify-center gap-2">
          <div>Earn Gems</div>
          <Gem width={24} height={24} />
        </div>
      </div>
      <div className="bg-secondaryWhite w-full flex items-center justify-center pl-2">
        <InviteFriends />
      </div>
      <div className="flex flex-col bg-secondaryGrey h-[87px] w-full text-center text-2xl items-center justify-center">
        <InviteButton text={"INVITE"} />
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <Box content={contents} topColor="#D9D9D9" width={375} />
    </div>
  );
};

export default ReferralLinkModal;
