const IceSnakePlayerShadow = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="35"
    viewBox="0 0 158 35"
    fill="none"
    className={className}
  >
    <ellipse cx="79" cy="17.5" rx="79" ry="17.5" fill="#1772DA" />
  </svg>
);

export default IceSnakePlayerShadow;
