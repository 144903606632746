import Box from "../../Box";
import Gem from "../../icons/Gem";
interface LeaderboardRecordProps {
  user: string
  rank: number
  score: number
  backgroundColor?: string
  bottomAccentColor?: string
  width?: number
  height?: number
  boxStyle?: boolean
  rankTitle?: JSX.Element
}

const LeaderboardRecord = ({
  user,
  rank,
  score,
  backgroundColor = "#F1F2F2",
  bottomAccentColor = "#D9D9D9",
  width = 315,
  height = 32,
  boxStyle = false,
  rankTitle = undefined
}: LeaderboardRecordProps) => {
  const content = (
    <div
      className="flex justify-between items-center px-2"
      style={{ backgroundColor: backgroundColor, width: `${width}px`, height: `${height}px` }}
    >
      <div className="flex gap-1">
        <div className="flex gap-4">
          <div>{rank}</div>
          <div>{user}</div>
        </div>
        { rankTitle && (
          <div className="flex items-center gap-2">
          <div>|</div>
          {rankTitle}
          </div>
        )}
      </div>
      <div className="flex gap-1 items-center">
        <Gem />
        <span>{score}</span>
      </div>
    </div>
  );

  return (
    <>
      {boxStyle ? (
        <Box content={content} width={width + 8} bottomBorderColor="black" backgroundColor={backgroundColor} topColor={backgroundColor} bottomAccentColor={bottomAccentColor} />
      ) : (
        <div className="flex flex-col items-center">
          <div className="h-[2px]" style={{ backgroundColor, width: `${width - 4}px` }} />
          {content}
          <div className="h-[2px]" style={{ backgroundColor, width: `${width - 4}px` }} />
        </div>
      )}
    </>
  );
};

export default LeaderboardRecord;
