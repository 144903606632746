import { useEffect, useState } from "react";
import useStore from "../../lib/store";
import Button from "../Form/Button";
import PopUpModal from "../PopUpModal";
import Loader from "../Loader";
import OptimizedImage from "../OptimizedImage";

interface DailyRewardPopupProps {
  onClose: () => void
}
const DailyRewardPopup = ({ onClose }: DailyRewardPopupProps) => {
  const setSendAppStart = useStore(state => state.setSendAppStart);
  const sendAppStart = useStore(state => state.sendAppStart);
  const [isFetchingReward, setIsFetchingReward] = useState(false);

  const content = (
    <div className="flex flex-col items-center justify-center relative gap-[24px]">
      <OptimizedImage
        src="/image/fire.png"
        alt="Claim Reward"
        width={200}
        height={200}
        className="h-auto mt-3 mb-[-16px]"
      />
      <div className="text-white text-2xl w-48 text-center">Claim your Daily Streak Reward!</div>
      <div className="h-24 flex items-center justify-center">
        {isFetchingReward && <Loader width={30} height={30} />}
        {!isFetchingReward && (
          <Button
            onClick={() => {
              setIsFetchingReward(true);
              setSendAppStart(true);
            }}
            className="ml-2"
            text="Claim"
          />
        )}
      </div>
    </div>
  );
  useEffect(() => {
    if (isFetchingReward) {
      if (!sendAppStart) {
        setIsFetchingReward(false);
        onClose();
      }
    }
  }, [isFetchingReward, sendAppStart]);

  return (
    <PopUpModal
      content={content}
      bottomAccentColor="#0E0B3580"
      backgroundImage="/image/purple-background.png"
    />
  );
};

export default DailyRewardPopup;
