"use client";

import { useTonConnectUI, useTonWallet, useTonAddress } from "@tonconnect/ui-react";
import Button from "../Form/Button";
import Box from "../Box";
import { truncateWalletAddress } from "../../utils/wallet";
import IconTon from "../icons/IconTon";

const TonWalletConnect = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const friendlyAddress = useTonAddress();

  const handleClick = async () => {
    if (wallet) {
      tonConnectUI.disconnect();
    } else {
      tonConnectUI.openModal();
    }
  };

  const renderButton = () => (
    <Button
      text={wallet ? "DISCONNECT" : "CONNECT TON WALLET"}
      textSize="base"
      width={250}
      height={48}
      onClick={handleClick}
      className="mt-3 mb-4 text-nowrap"
      thickBottom={true}
      textPosition="start"
    />
  );

  const renderWalletConnected = () => (
    <div className="bg-secondaryWhite flex flex-col items-center justify-center gap-1 px-3">
      <div className="relative py-2 text-center text-lg">Get prepped for $SCOR Rewards</div>
      <div className="bg-lightGrey flex w-full justify-between p-2 py-5 items-center">
        <div className="w-full leading-none text-center">
          You’re all set!
        </div>
      </div>
      {wallet && (
        <div className="flex gap-4 my-4 items-center">
          <IconTon />
          <div className="flex flex-col justify-center items-start leading-none gap-[4px]">
            <span className="opacity-50">{`Connected to TON wallet ${truncateWalletAddress(friendlyAddress)}`}</span>
            <button className="underline" onClick={handleClick}>DISCONNECT</button>
          </div>
        </div>
      )}
    </div>
  );

  const renderWalletNotConnected = () => (
    <div className="bg-secondaryWhite flex flex-col items-center justify-center gap-1 px-3">
      <div className="relative py-2 text-center text-lg">Get prepped for $SCOR Rewards</div>
      <div className="bg-lightGrey flex w-full justify-center p-2 py-3 items-center text-center">
        Connect using Wallet, TonKeeper, MyTonWallet or 10+ other options!
      </div>
      {renderButton()}
    </div>
  );

  const renderWalletContent = () => {
    if (wallet) {
      return renderWalletConnected();
    } else {
      return renderWalletNotConnected();
    }
  };

  return (
    <div className="flex items-center justify-center w-full mt-8 px-2">
      <Box content={renderWalletContent()} bottomBorderColor="black" width={346} />
    </div>
  );
};

export default TonWalletConnect;
