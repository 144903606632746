"use client";

import Activity from "./Activity";
import { ActivityItem,  ACTIVITY_TYPES } from "../../types/activity";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getRequest } from "../../utils/requests";
import { ErrorResponseData, ActivityResponse } from "../../types/requestTypes";
import Loader from "../Loader";

interface ActivityListProps {
  activeActivityTab: ACTIVITY_TYPES
}

const ActivityList = ({ activeActivityTab }: ActivityListProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [hasMoreGems, setHasMoreGems] = useState(true);
  const [hasMoreTickets, setHasMoreTickets] = useState(true);
  const [lastTimestampGems, setLastTimestampGems] = useState<number | null>(null);
  const [lastTimestampTickets, setLastTimestampTickets] = useState<number | null>(null);
  const [gemActivities, setGemActivities] = useState<ActivityItem[]>([]);
  const [ticketActivities, setTicketActivities] = useState<ActivityItem[]>([]);

  const activities = useMemo(
    () => (activeActivityTab === ACTIVITY_TYPES.GEMS ? gemActivities : ticketActivities),
    [activeActivityTab, gemActivities, ticketActivities]
  );

  const loadMoreItems = async () => {
    if (!isFetching) {
      setIsFetching(true);
      const activityType = activeActivityTab === ACTIVITY_TYPES.GEMS ? "game-points" : "game-tickets";
      let url = `miniapp/user/activity/${activityType}?limit=10`;
      if (activeActivityTab === ACTIVITY_TYPES.GEMS && lastTimestampGems) {
        url += `&before_ms=${lastTimestampGems}`;
      } else if (activeActivityTab === ACTIVITY_TYPES.TICKETS && lastTimestampTickets) {
        url += `&before_ms=${lastTimestampTickets}`;
      }
      const response: ActivityResponse | ErrorResponseData = await getRequest(url);
      const { status, data } = response;
      if (status === 200 && data?.items) {
        const { has_more, last_timestamp_ms, items } = data;
        if (activeActivityTab === ACTIVITY_TYPES.GEMS) {
          // do not diplay entries in the gems activities when losing game without a point
          const item_activities = items.filter(item => !(item.quantity <= 0 && ["glove-hero", "ice-snake"].includes(item.reason)));
          const lastTimestamp = last_timestamp_ms ?? item_activities.at(-1)?.timestamp_ms;
          setGemActivities([...gemActivities, ...item_activities]);
          setHasMoreGems(has_more);
          setLastTimestampGems(lastTimestamp);
        } else {
          setTicketActivities([...ticketActivities, ...items]);
          setHasMoreTickets(has_more);
          setLastTimestampTickets(last_timestamp_ms || items[items.length - 1]?.timestamp_ms);
        }
      } else {
        console.error(`Error: ${status} ${response.message}`);
      }
      setIsFetching(false);
    }
  };

  useEffect(() => {
    // initial load
    if (activeActivityTab === ACTIVITY_TYPES.GEMS && gemActivities.length === 0) loadMoreItems();
    if (activeActivityTab === ACTIVITY_TYPES.TICKETS && ticketActivities.length === 0) loadMoreItems();
  }, [activeActivityTab]);

  return (
    <section className="flex flex-col overflow-visible">
      <InfiniteScroll
        dataLength={activities.length}
        className="flex flex-col items-center"
        hasMore={activeActivityTab === ACTIVITY_TYPES.GEMS ? hasMoreGems : hasMoreTickets}
        next={loadMoreItems}
        loader={
          <div className="loader flex items-center justify-center mt-[100px]" key={0}>
            <Loader width={32} height={32}/>
          </div>
        }
        style={{ overflow: "visible" }} // Prevents internal scrolling
        scrollableTarget={null} // Ensures it listens to the page scroll
      >
        {activities?.map((activity, index) => (
          <Activity key={`${index}${activity.timestamp_ms}`} activity={activity} activeTab={activeActivityTab}/>
        ))}
      </InfiniteScroll>
    </section>
  );
};

export default ActivityList;
