import axios, { AxiosError } from "axios";

interface ErrorResponseData {
  message: string;
}

const apiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((config) => {
  const token = retrieveAuthToken();
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const getRequest = async (path: string) => {
  try {
    const response = await apiClient.get(`/${path}`);
    return { status: response.status, data: response.data, message: response.data?.message || response.statusText };
  } catch (error) {
    return handleRequestError(error as AxiosError<ErrorResponseData>);
  }
};

export const postRequest = async (path: string, data?: object) => {
  try {
    const response = await apiClient.post(`/${path}`, data);
    return { status: response.status, data: response.data, message: response.data?.message || response.statusText };
  } catch (error) {
    return handleRequestError(error as AxiosError<ErrorResponseData>);
  }
};

const handleRequestError = (error: AxiosError<ErrorResponseData>) => {
  if (error.response) {
    return { status: error.response.status, message: error.response.data?.message || error.response.statusText };
  } else {
    return { status: 500, message: "An error occurred", data: null };
  }
};

export const saveTelegramInitData = (token: string, locale?: string) => {
  try {
    localStorage.setItem("token", token);
    if (!!locale) {
      localStorage.setItem("locale", locale);
    }
    return true;
  } catch (error) {
    console.error("Error setting token:", error);
    return false;
  }
};

export const retrieveAuthToken = () => {
  try {
    return localStorage.getItem("token") || "";
  } catch (error) {
    console.error("Error retrieving token:", error);
    return "";
  }
};
