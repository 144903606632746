const Headset = ({ className = " " }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="34" viewBox="0 0 29 34" fill="none" className={className}>
    <g id="Group 99">
      <path id="Vector" d="M26.6153 5.2821H25.7179V16.9488H26.6153V5.2821Z" fill="black" />
      <path id="Vector_2" d="M3.28202 6.17957H2.38458V16.9488H3.28202V6.17957Z" fill="black" />
      <path id="Vector_3" d="M4.17948 16.0514H3.28204V25.0258H4.17948V16.0514Z" fill="black" />
      <path id="Vector_4" d="M5.07688 16.0514H4.17944V25.0258H5.07688V16.0514Z" fill="#C62C2C" />
      <path id="Vector_5" d="M24.8205 16.0514H23.923V25.0258H24.8205V16.0514Z" fill="#C62C2C" />
      <path id="Vector_6" d="M7.7692 16.0514H6.87177V25.0258H7.7692V16.0514Z" fill="#A61D1D" />
      <path id="Vector_7" d="M22.1282 16.0514H21.2308V25.0258H22.1282V16.0514Z" fill="#A61D1D" />
      <path id="Vector_8" d="M6.87178 15.1539H5.0769V25.9232H6.87178V15.1539Z" fill="#C62C2C" />
      <path id="Vector_9" d="M23.923 15.1539H22.1282V25.9232H23.923V15.1539Z" fill="#C62C2C" />
      <path id="Vector_10" d="M6.87178 15.1539H5.0769V16.0514H6.87178V15.1539Z" fill="#A61D1D" />
      <path id="Vector_11" d="M23.923 15.1539H22.1282V16.0514H23.923V15.1539Z" fill="#A61D1D" />
      <path id="Vector_12" d="M6.87178 25.0256H5.0769V25.9231H6.87178V25.0256Z" fill="#A61D1D" />
      <path id="Vector_13" d="M23.923 25.0256H22.1282V25.9231H23.923V25.0256Z" fill="#A61D1D" />
      <path id="Vector_14" d="M15.8462 32.2052H12.2564V33.1026H15.8462V32.2052Z" fill="#A61D1D" />
      <path id="Vector_15" d="M15.8461 31.3077H14.9487V32.2052H15.8461V31.3077Z" fill="#A61D1D" />
      <path id="Vector_16" d="M3.28202 16.9487H2.38458V22.3333H3.28202V16.9487Z" fill="#A61D1D" />
      <path id="Vector_17" d="M26.6153 16.9487H25.7179V22.3333H26.6153V16.9487Z" fill="#A61D1D" />
      <path id="Vector_18" d="M2.38462 18.7437H1.48718V20.5385H2.38462V18.7437Z" fill="#C62C2C" />
      <path id="Vector_19" d="M27.5128 18.7437H26.6154V20.5385H27.5128V18.7437Z" fill="#C62C2C" />
      <path id="Vector_20" d="M25.7179 16.0514H24.8205V25.0258H25.7179V16.0514Z" fill="black" />
      <path id="Vector_21" d="M5.07688 25.0256H4.17944V25.9231H5.07688V25.0256Z" fill="black" />
      <path id="Vector_22" d="M5.07688 15.1539H4.17944V16.0514H5.07688V15.1539Z" fill="black" />
      <path id="Vector_23" d="M24.8205 15.1539H23.923V16.0514H24.8205V15.1539Z" fill="black" />
      <path id="Vector_24" d="M24.8205 25.0256H23.923V25.9231H24.8205V25.0256Z" fill="black" />
      <path id="Vector_25" d="M6.87178 25.9231H5.0769V26.8205H6.87178V25.9231Z" fill="black" />
      <path id="Vector_26" d="M23.923 25.9231H22.1282V26.8205H23.923V25.9231Z" fill="black" />
      <path id="Vector_27" d="M6.87178 14.2565H5.0769V15.1539H6.87178V14.2565Z" fill="black" />
      <path id="Vector_28" d="M23.923 14.2565H22.1282V15.1539H23.923V14.2565Z" fill="black" />
      <path id="Vector_29" d="M8.66666 15.1539H7.76923V25.9232H8.66666V15.1539Z" fill="black" />
      <path id="Vector_30" d="M21.2307 15.1539H20.3333V25.9232H21.2307V15.1539Z" fill="black" />
      <path id="Vector_31" d="M22.1282 25.0256H21.2308V25.9231H22.1282V25.0256Z" fill="black" />
      <path id="Vector_32" d="M22.1282 15.1539H21.2308V16.0514H22.1282V15.1539Z" fill="black" />
      <path id="Vector_33" d="M7.7692 25.0256H6.87177V25.9231H7.7692V25.0256Z" fill="black" />
      <path id="Vector_34" d="M7.7692 15.1539H6.87177V16.0514H7.7692V15.1539Z" fill="black" />
      <path id="Vector_35" d="M24.8205 3.48718H23.923V6.17949H24.8205V3.48718Z" fill="black" />
      <path id="Vector_36" d="M5.07688 3.48718H4.17944V6.17949H5.07688V3.48718Z" fill="black" />
      <path id="Vector_37" d="M23.9231 2.58984H23.0256V5.28215H23.9231V2.58984Z" fill="black" />
      <path id="Vector_38" d="M5.97434 2.58984H5.0769V5.28215H5.97434V2.58984Z" fill="black" />
      <path id="Vector_39" d="M23.0256 2.58984H22.1282V4.38472H23.0256V2.58984Z" fill="black" />
      <path id="Vector_40" d="M6.8718 2.58984H5.97437V4.38472H6.8718V2.58984Z" fill="black" />
      <path id="Vector_41" d="M22.1282 1.69238H19.4359V3.48725H22.1282V1.69238Z" fill="black" />
      <path id="Vector_42" d="M9.56407 1.69238H6.87177V3.48725H9.56407V1.69238Z" fill="black" />
      <path id="Vector_43" d="M19.4359 0.794922H9.56409V2.58979H19.4359V0.794922Z" fill="black" />
      <path id="Vector_44" d="M25.7179 4.38464H24.8205V7.97439H25.7179V4.38464Z" fill="black" />
      <path id="Vector_45" d="M4.17948 5.2821H3.28204V8.87185H4.17948V5.2821Z" fill="black" />
      <path id="Vector_46" d="M27.5128 8.45691H26.6154V18.3287H27.5128V8.45691Z" fill="black" />
      <path id="Vector_47" d="M2.38462 8.87183H1.48718V18.7436H2.38462V8.87183Z" fill="black" />
      <rect
        id="Rectangle 860"
        x="0.589722"
        y="18.7437"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <rect
        id="Rectangle 865"
        x="27.5128"
        y="18.7437"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <path id="Vector_48" d="M15.8462 30.4103H12.2564V32.2052H15.8462V30.4103Z" fill="#C62C2C" />
      <rect
        id="Rectangle 861"
        x="1.48718"
        y="20.5385"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <rect
        id="Rectangle 864"
        x="26.6154"
        y="20.5385"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <rect
        id="Rectangle 862"
        x="2.38458"
        y="22.3334"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <rect
        id="Rectangle 863"
        x="25.7179"
        y="22.3334"
        width="0.897436"
        height="1.79487"
        fill="black"
      />
      <rect
        id="Rectangle 866"
        x="22.1282"
        y="26.8206"
        width="0.897436"
        height="2.69231"
        fill="black"
      />
      <rect
        id="Rectangle 867"
        x="21.2308"
        y="29.5128"
        width="0.897434"
        height="0.897436"
        fill="black"
      />
      <rect
        id="Rectangle 868"
        x="20.3333"
        y="30.4103"
        width="0.897436"
        height="0.897434"
        fill="black"
      />
      <rect
        id="Rectangle 869"
        x="15.8461"
        y="31.3077"
        width="4.48718"
        height="0.897438"
        fill="black"
      />
      <rect
        id="Rectangle 870"
        x="15.8461"
        y="30.4103"
        width="0.897436"
        height="2.69231"
        fill="black"
      />
      <rect
        id="Rectangle 872"
        x="11.3589"
        y="30.4103"
        width="0.897436"
        height="2.69231"
        fill="black"
      />
      <rect
        id="Rectangle 871"
        x="12.2564"
        y="29.5128"
        width="3.58974"
        height="0.897436"
        fill="black"
      />
      <rect
        id="Rectangle 873"
        x="12.2564"
        y="33.1027"
        width="3.58974"
        height="0.897438"
        fill="black"
      />
      <path id="Vector_49" d="M15.8461 31.3077H14.9487V32.2052H15.8461V31.3077Z" fill="#A61D1D" />
    </g>
  </svg>
);

export default Headset;
