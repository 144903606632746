const Flame = ({ className = "" }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="68" viewBox="0 0 42 68" fill="none" className={className}>
    <g id="Frame 156">
      <g id="Group 97">
        <rect
          id="Rectangle 357"
          x="12.7154"
          y="63.1703"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 443"
          x="14.7865"
          y="65.2414"
          width="10.3558"
          height="2.07116"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 393"
          x="12.7154"
          y="51.2611"
          width="2.07116"
          height="11.9091"
          fill="#FFCC00"
        />
        <rect
          id="Rectangle 394"
          x="10.6442"
          y="53.3322"
          width="2.07116"
          height="8.28462"
          fill="#FFCC00"
        />
        <rect
          id="Rectangle 395"
          x="25.1422"
          y="51.2611"
          width="2.07116"
          height="11.9091"
          fill="#FFCC00"
        />
        <rect
          id="Rectangle 438"
          x="23.0712"
          y="48.6721"
          width="2.07116"
          height="16.5692"
          fill="white"
        />
        <rect
          id="Rectangle 439"
          x="21.0001"
          y="50.7433"
          width="2.07116"
          height="14.4981"
          fill="white"
        />
        <rect
          id="Rectangle 440"
          x="18.9289"
          y="50.7433"
          width="2.07116"
          height="14.4981"
          fill="white"
        />
        <rect
          id="Rectangle 441"
          x="16.8577"
          y="50.7433"
          width="2.07116"
          height="14.4981"
          fill="white"
        />
        <rect
          id="Rectangle 442"
          x="14.7865"
          y="50.7433"
          width="2.07116"
          height="14.4981"
          fill="white"
        />
        <rect
          id="Rectangle 396"
          x="27.2134"
          y="53.3322"
          width="2.07116"
          height="9.83799"
          fill="#FFCC00"
        />
        <rect
          id="Rectangle 398"
          x="27.2134"
          y="42.9765"
          width="2.07116"
          height="10.3558"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 400"
          x="25.1422"
          y="40.9053"
          width="2.07116"
          height="10.3558"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 401"
          x="23.0712"
          y="38.8341"
          width="2.07116"
          height="9.83799"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 402"
          x="21.0001"
          y="40.9053"
          width="2.07116"
          height="9.83799"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 403"
          x="18.9289"
          y="42.9765"
          width="2.07116"
          height="9.83799"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 404"
          x="16.8577"
          y="45.0477"
          width="2.07116"
          height="9.83799"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 405"
          x="14.7865"
          y="47.1188"
          width="2.07116"
          height="6.21347"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 406"
          x="12.7154"
          y="49.19"
          width="2.07116"
          height="2.07116"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 407"
          x="12.7154"
          y="40.9053"
          width="2.07116"
          height="8.28462"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 418"
          x="10.6442"
          y="42.9765"
          width="2.07116"
          height="10.3558"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 419"
          x="8.573"
          y="45.0477"
          width="2.07116"
          height="16.5692"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 420"
          x="6.50195"
          y="47.1188"
          width="2.07116"
          height="10.8736"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 421"
          x="4.43079"
          y="49.19"
          width="2.07116"
          height="8.80241"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 422"
          x="4.43079"
          y="36.2452"
          width="2.07116"
          height="12.9447"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 423"
          x="6.50195"
          y="36.2452"
          width="2.07116"
          height="10.8736"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 424"
          x="8.573"
          y="32.6207"
          width="2.07116"
          height="12.4269"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 425"
          x="10.6442"
          y="25.3717"
          width="2.07116"
          height="17.6048"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 426"
          x="12.7154"
          y="23.3005"
          width="2.07116"
          height="17.6048"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 427"
          x="14.7865"
          y="7.76688"
          width="2.07116"
          height="31.0673"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 428"
          x="16.8577"
          y="9.83797"
          width="2.07116"
          height="26.925"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 429"
          x="18.9289"
          y="11.9091"
          width="2.07116"
          height="22.7827"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 430"
          x="21.0001"
          y="13.9803"
          width="2.07116"
          height="9.3202"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 431"
          x="23.0712"
          y="16.0514"
          width="2.07116"
          height="9.3202"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 432"
          x="25.1422"
          y="23.3005"
          width="2.07116"
          height="4.14231"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 433"
          x="27.2134"
          y="25.3717"
          width="2.07116"
          height="4.14231"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 434"
          x="29.2847"
          y="23.3005"
          width="2.07116"
          height="4.14231"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 435"
          x="31.3557"
          y="21.2294"
          width="2.07116"
          height="39.8697"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 436"
          x="33.427"
          y="23.3005"
          width="2.07116"
          height="35.7274"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 437"
          x="35.4982"
          y="32.1029"
          width="2.07116"
          height="24.8539"
          fill="#FF4000"
        />
        <rect
          id="Rectangle 408"
          x="14.7865"
          y="38.8341"
          width="2.07116"
          height="8.28462"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 409"
          x="16.8577"
          y="36.763"
          width="2.07116"
          height="8.28462"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 410"
          x="18.9289"
          y="34.6918"
          width="2.07116"
          height="8.28462"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 411"
          x="21.0001"
          y="23.3005"
          width="2.07116"
          height="17.6048"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 412"
          x="23.0712"
          y="25.3717"
          width="2.07116"
          height="13.4625"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 413"
          x="25.1422"
          y="27.4428"
          width="2.07116"
          height="13.4625"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 414"
          x="27.2134"
          y="29.514"
          width="2.07116"
          height="13.4625"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 415"
          x="29.2847"
          y="27.4428"
          width="2.07116"
          height="17.6048"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 416"
          x="31.3557"
          y="32.1029"
          width="2.07116"
          height="25.3717"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 417"
          x="33.427"
          y="34.1741"
          width="2.07116"
          height="21.2293"
          fill="#FF7700"
        />
        <rect
          id="Rectangle 399"
          x="29.2847"
          y="45.0477"
          width="2.07116"
          height="10.3558"
          fill="#FF9900"
        />
        <rect
          id="Rectangle 397"
          x="29.2847"
          y="55.4034"
          width="2.07116"
          height="7.76683"
          fill="#FFCC00"
        />
        <rect
          id="Rectangle 358"
          x="10.6442"
          y="61.0991"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 359"
          x="8.573"
          y="61.0991"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 360"
          x="6.50195"
          y="57.4746"
          width="2.07116"
          height="6.21347"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 361"
          x="4.43079"
          y="57.4746"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 362"
          x="2.35962"
          y="34.1741"
          width="2.07116"
          height="25.3717"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 363"
          x="0.288452"
          y="38.3164"
          width="2.07116"
          height="19.1582"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 364"
          x="4.43079"
          y="32.1029"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 365"
          x="6.50195"
          y="30.0318"
          width="2.07116"
          height="6.21347"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 366"
          x="8.573"
          y="13.9803"
          width="2.07116"
          height="18.6404"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 367"
          x="10.6442"
          y="11.9091"
          width="2.07116"
          height="13.4625"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 368"
          x="12.7154"
          y="9.83797"
          width="2.07116"
          height="13.4625"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 369"
          x="12.7154"
          y="3.62451"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 374"
          x="14.7865"
          y="3.62451"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 375"
          x="16.8577"
          y="5.69569"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 376"
          x="18.9289"
          y="7.76688"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 377"
          x="21.0001"
          y="9.83797"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 378"
          x="23.0712"
          y="11.9091"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 379"
          x="25.1422"
          y="19.1582"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 380"
          x="27.2134"
          y="21.2294"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 381"
          x="29.2847"
          y="19.1582"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 382"
          x="31.3557"
          y="17.087"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 383"
          x="33.427"
          y="19.1582"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 384"
          x="35.4982"
          y="21.2294"
          width="2.07116"
          height="10.8736"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 385"
          x="37.5693"
          y="32.1029"
          width="2.07116"
          height="24.8539"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 386"
          x="39.6403"
          y="33.6563"
          width="2.07116"
          height="21.7471"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 387"
          width="2.07116"
          height="4.14231"
          transform="matrix(1 0 0 -1 35.4982 61.0991)"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 388"
          width="2.07116"
          height="4.14231"
          transform="matrix(1 0 0 -1 33.427 63.1703)"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 389"
          width="2.07116"
          height="4.14231"
          transform="matrix(1 0 0 -1 31.3557 65.2414)"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 390"
          width="6.21347"
          height="4.14231"
          transform="matrix(1 0 0 -1 25.1422 67.3126)"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 370"
          x="10.6442"
          y="2.07118"
          width="2.07116"
          height="4.14231"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 371"
          x="8.573"
          y="2.07118"
          width="2.07116"
          height="2.07116"
          fill="#DA0000"
        />
        <rect id="Rectangle 372" x="6.50195" width="2.07116" height="2.07116" fill="#DA0000" />
        <rect
          id="Rectangle 391"
          x="31.3557"
          y="11.9091"
          width="2.07116"
          height="2.07116"
          fill="#DA0000"
        />
        <rect
          id="Rectangle 392"
          x="33.427"
          y="9.83797"
          width="2.07116"
          height="2.07116"
          fill="#DA0000"
        />
        <rect id="Rectangle 373" x="10.6442" width="2.07116" height="2.07116" fill="#DA0000" />
      </g>
    </g>
  </svg>
);

export default Flame;
