import Button from "../Form/Button";
import PopUpModal from "../PopUpModal";
import { closeMiniApp } from "@telegram-apps/sdk";

const AuthPopup = () => {
  const content = (
    <div className="flex flex-col justify-center items-center p-4">
      <div className="text-center text-lg">Session Expired</div>
      <div className="text-center opacity-50">Please close and re-open this app to continue</div>
      <Button
        onClick={() => closeMiniApp()}
        className="mt-4"
        text="Close Application"
        textSize="md"
        height={48}
      />
    </div>
  );
  return <PopUpModal content={content} />;
};

export default AuthPopup;
