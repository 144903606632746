import { GAME_SCREENS } from "../../types/home";
import GloveHero from "../icons/GloveHero";
import IceSnake from "../icons/IceSnake";

interface GameTitleProps {
  gameScreen: string;
}

const GameTitle = ({ gameScreen }: GameTitleProps) => {
  switch (gameScreen) {
    case GAME_SCREENS.GLOVE_HERO:
      return <GloveHero className="z-10" />;
    case GAME_SCREENS.ICE_SNAKE:
      return <IceSnake className="z-10" />;
    default:
      return null;
  }
};

export default GameTitle;