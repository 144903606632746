"use client";

import Gem from "../icons/Gem";
import Ticket from "../icons/Ticket";
import { ACTIVITY_TYPES } from "../../types/activity";

interface ActivityTabsProps {
  activeActivityTab: ACTIVITY_TYPES
  setActiveActivityTab: (value: ACTIVITY_TYPES) => void
}

const ActivityTabs = ({ activeActivityTab, setActiveActivityTab }: ActivityTabsProps) => {
  return (
    <section className="grid grid-cols-2 w-full mt-4 h-[30px]">
      <button
        onClick={() => setActiveActivityTab(ACTIVITY_TYPES.GEMS)}
        className={
          activeActivityTab === ACTIVITY_TYPES.GEMS
            ? "bg-pink"
            : "bg-black text-grey border-b-[3px] border-pink"
        }
      >
        <div className="flex items-center justify-center gap-2">
          <Gem className={`opacity-${activeActivityTab === ACTIVITY_TYPES.GEMS ? "100" : "50"}`} />
          <div>{ACTIVITY_TYPES.GEMS}</div>
        </div>
      </button>
      <button
        onClick={() => setActiveActivityTab(ACTIVITY_TYPES.TICKETS)}
        className={
          activeActivityTab === ACTIVITY_TYPES.TICKETS
            ? "bg-teal"
            : "bg-black text-grey border-b-[3px] border-teal"
        }
      >
        <div className="flex items-center justify-center gap-2">
          <Ticket className={activeActivityTab === ACTIVITY_TYPES.TICKETS ? "opacity-100" : "opacity-50"} />
          <div>{ACTIVITY_TYPES.TICKETS}</div>
        </div>
      </button>
    </section>
  );
};

export default ActivityTabs;
