const Tickets = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <rect
      x="5.62784"
      y="15.753"
      width="7.31049"
      height="1.04436"
      transform="rotate(-24.1076 5.62784 15.753)"
      fill="black"
    />
    <rect
      x="1.36194"
      y="6.22018"
      width="7.31049"
      height="1.04436"
      transform="rotate(-24.1076 1.36194 6.22018)"
      fill="black"
    />
    <rect
      x="13.2536"
      y="12.3404"
      width="5.22178"
      height="1.04436"
      transform="rotate(-24.1076 13.2536 12.3404)"
      fill="black"
    />
    <rect
      x="8.98773"
      y="2.80765"
      width="5.22178"
      height="1.04436"
      transform="rotate(-24.1076 8.98773 2.80765)"
      fill="black"
    />
    <rect
      x="4.24768"
      y="15.2261"
      width="8.35485"
      height="1.04436"
      transform="rotate(-24.1076 4.24768 15.2261)"
      fill="#16A48A"
    />
    <rect
      x="11.8734"
      y="11.8139"
      width="6.26614"
      height="1.04436"
      transform="rotate(-24.1076 11.8734 11.8139)"
      fill="#A1AAB7"
    />
    <rect
      x="8.46118"
      y="4.18753"
      width="6.26614"
      height="8.35485"
      transform="rotate(-24.1076 8.46118 4.18753)"
      fill="#C7D1DB"
    />
    <rect
      x="1.78876"
      y="7.17355"
      width="7.31049"
      height="8.35485"
      transform="rotate(-24.1076 1.78876 7.17355)"
      fill="#25C4A7"
    />
    <rect
      x="8.46118"
      y="4.18753"
      width="1.04436"
      height="1.04436"
      transform="rotate(-24.1076 8.46118 4.18753)"
      fill="#A1AAB7"
    />
    <rect
      x="9.31369"
      y="6.09396"
      width="1.04436"
      height="1.04436"
      transform="rotate(-24.1076 9.31369 6.09396)"
      fill="#A1AAB7"
    />
    <rect
      x="10.1673"
      y="8.0007"
      width="1.04436"
      height="1.04436"
      transform="rotate(-24.1076 10.1673 8.0007)"
      fill="#A1AAB7"
    />
    <rect
      x="11.0204"
      y="9.90714"
      width="1.04436"
      height="1.04436"
      transform="rotate(-24.1076 11.0204 9.90714)"
      fill="#A1AAB7"
    />
    <rect
      x="0.834839"
      y="7.60007"
      width="1.04436"
      height="4.17742"
      transform="rotate(-24.1076 0.834839 7.60007)"
      fill="black"
    />
    <rect
      x="2.9678"
      y="12.3666"
      width="1.04436"
      height="4.17742"
      transform="rotate(-24.1076 2.9678 12.3666)"
      fill="black"
    />
    <rect
      x="3.49435"
      y="10.9867"
      width="2.08871"
      height="1.04436"
      transform="rotate(-24.1076 3.49435 10.9867)"
      fill="black"
    />
    <rect
      x="13.9802"
      y="6.29428"
      width="2.08871"
      height="1.04436"
      transform="rotate(-24.1076 13.9802 6.29428)"
      fill="black"
    />
    <rect
      x="14.1808"
      y="1.62805"
      width="1.04436"
      height="4.17742"
      transform="rotate(-24.1076 14.1808 1.62805)"
      fill="black"
    />
    <rect
      x="16.3138"
      y="6.39459"
      width="1.04436"
      height="4.17742"
      transform="rotate(-24.1076 16.3138 6.39459)"
      fill="black"
    />
    <rect
      x="5.92761"
      y="8.75375"
      width="3.13307"
      height="3.13307"
      transform="rotate(-24.1076 5.92761 8.75375)"
      fill="#79EED9"
    />
    <rect
      x="1.78876"
      y="7.17355"
      width="7.31049"
      height="1.04436"
      transform="rotate(-24.1076 1.78876 7.17355)"
      fill="#79EED9"
    />
    <rect
      x="9.414"
      y="3.76099"
      width="5.22178"
      height="1.04436"
      transform="rotate(-24.1076 9.414 3.76099)"
      fill="#EBEBEB"
    />
    <rect
      x="3.38052"
      y="18.0558"
      width="7.31049"
      height="1.04436"
      transform="rotate(-13.0767 3.38052 18.0558)"
      fill="black"
    />
    <rect
      x="1.01743"
      y="7.88281"
      width="7.31049"
      height="1.04436"
      transform="rotate(-13.0767 1.01743 7.88281)"
      fill="black"
    />
    <rect
      x="11.5184"
      y="16.1653"
      width="5.22178"
      height="1.04436"
      transform="rotate(-13.0767 11.5184 16.1653)"
      fill="black"
    />
    <rect
      x="9.1553"
      y="5.99252"
      width="5.22178"
      height="1.04436"
      transform="rotate(-13.0767 9.1553 5.99252)"
      fill="black"
    />
    <rect
      x="2.12683"
      y="17.2746"
      width="8.35485"
      height="1.04436"
      transform="rotate(-13.0767 2.12683 17.2746)"
      fill="#16A48A"
    />
    <rect
      x="10.2652"
      y="15.3844"
      width="6.26614"
      height="1.04436"
      transform="rotate(-13.0767 10.2652 15.3844)"
      fill="#A1AAB7"
    />
    <rect
      x="8.37466"
      y="7.24622"
      width="6.26614"
      height="8.35485"
      transform="rotate(-13.0767 8.37466 7.24622)"
      fill="#C7D1DB"
    />
    <rect
      x="1.25369"
      y="8.90027"
      width="7.31049"
      height="8.35485"
      transform="rotate(-13.0767 1.25369 8.90027)"
      fill="#25C4A7"
    />
    <rect
      x="8.37466"
      y="7.24622"
      width="1.04436"
      height="1.04436"
      transform="rotate(-13.0767 8.37466 7.24622)"
      fill="#A1AAB7"
    />
    <rect
      x="8.84717"
      y="9.28055"
      width="1.04436"
      height="1.04436"
      transform="rotate(-13.0767 8.84717 9.28055)"
      fill="#A1AAB7"
    />
    <rect
      x="9.31967"
      y="11.3154"
      width="1.04436"
      height="1.04436"
      transform="rotate(-13.0767 9.31967 11.3154)"
      fill="#A1AAB7"
    />
    <rect
      x="9.79218"
      y="13.3498"
      width="1.04436"
      height="1.04436"
      transform="rotate(-13.0767 9.79218 13.3498)"
      fill="#A1AAB7"
    />
    <rect
      x="0.236237"
      y="9.13651"
      width="1.04436"
      height="4.17742"
      transform="rotate(-13.0767 0.236237 9.13651)"
      fill="black"
    />
    <rect
      x="1.41806"
      y="14.2231"
      width="1.04436"
      height="4.17742"
      transform="rotate(-13.0767 1.41806 14.2231)"
      fill="black"
    />
    <rect
      x="2.1987"
      y="12.9692"
      width="2.08871"
      height="1.04436"
      transform="rotate(-13.0767 2.1987 12.9692)"
      fill="black"
    />
    <rect
      x="13.3889"
      y="10.3704"
      width="2.08871"
      height="1.04436"
      transform="rotate(-13.0767 13.3889 10.3704)"
      fill="black"
    />
    <rect
      x="14.4776"
      y="5.82843"
      width="1.04436"
      height="4.17742"
      transform="rotate(-13.0767 14.4776 5.82843)"
      fill="black"
    />
    <rect
      x="15.66"
      y="10.9148"
      width="1.04436"
      height="4.17742"
      transform="rotate(-13.0767 15.66 10.9148)"
      fill="black"
    />
    <rect
      x="5.01422"
      y="11.243"
      width="3.13307"
      height="3.13307"
      transform="rotate(-13.0767 5.01422 11.243)"
      fill="#79EED9"
    />
    <rect
      x="1.25369"
      y="8.90027"
      width="7.31049"
      height="1.04436"
      transform="rotate(-13.0767 1.25369 8.90027)"
      fill="#79EED9"
    />
    <rect
      x="9.39209"
      y="7.00998"
      width="5.22178"
      height="1.04436"
      transform="rotate(-13.0767 9.39209 7.00998)"
      fill="#EBEBEB"
    />
  </svg>
);

export default Tickets;
