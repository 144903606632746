import AccountIcon from "../icons/AccountIcon";
import useStore from "../../lib/store";
import OptimizedImage from "../OptimizedImage";
import { NAVIGATION_TABS } from "../../types/home";
import { COMMUNITY_TABS } from "../../types/community";

interface UserProfileProps {
  playerOverallRank: string;
}

const UserProfile = ({ playerOverallRank }: UserProfileProps) => {
  const user = useStore(state => state.user);
  const setActiveTab = useStore(state => state.setActiveTab);
  const setActiveCommunityTab = useStore((state) => state.setActiveCommunityTab);

  const handleClickProfile = () => {
    setActiveTab(NAVIGATION_TABS.COMMUNITY);
    setActiveCommunityTab(COMMUNITY_TABS.LEADERBOARDS);
  };

  return (
    <button className="flex gap-2 z-10" onClick={handleClickProfile}>
      {user?.profile_photo ? (
        <OptimizedImage
          src={user.profile_photo}
          alt="profile-photo"
          className="rounded-full object-cover border-2 border-black"
          width={48}
          height={48}
        />
      ) : (
        <div className="w-[48px] h-[48px] rounded-full border-2 border-black flex items-center justify-center bg-black">
          <AccountIcon width={48} height={48} />
        </div>
      )}
      <div className="text-secondaryWhite flex flex-col items-start">
        <div>{user?.username}</div>
        <div className="text-xs">{`Rank ${playerOverallRank !== "Unranked" ? "#" : ""}${playerOverallRank}`}</div>
      </div>
    </button>
  );
};

export default UserProfile;
