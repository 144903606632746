const Football = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="87" height="73" viewBox="0 0 87 73" fill="none" className={className}>
    <path
      d="M67.303 24.9773L38.9319 10.1759L38.0068 11.9491L66.3779 26.7505L67.303 24.9773Z"
      fill="black"
    />
    <path
      d="M48.8013 60.4412L20.4302 45.6398L19.5051 47.413L47.8763 62.2144L48.8013 60.4412Z"
      fill="black"
    />
    <path
      d="M51.5766 55.1216L23.2055 40.3202L20.4302 45.6398L48.8013 60.4411L51.5766 55.1216Z"
      fill="#7D342D"
    />
    <path
      d="M24.1306 38.547L20.5842 36.6969L17.8089 42.0165L21.3553 43.8666L24.1306 38.547Z"
      fill="#7D342D"
    />
    <path
      d="M56.0481 55.1986L52.5017 53.3484L49.7264 58.668L53.2728 60.5181L56.0481 55.1986Z"
      fill="#7D342D"
    />
    <path
      d="M60.5195 55.2755L56.9731 53.4254L54.1979 58.7449L57.7443 60.5951L60.5195 55.2755Z"
      fill="#7D342D"
    />
    <path
      d="M63.2178 54.4274L61.4446 53.5023L58.6694 58.8219L60.4426 59.747L63.2178 54.4274Z"
      fill="#7D342D"
    />
    <path
      d="M64.991 55.3525L63.2178 54.4274L61.3677 57.9738L63.1408 58.8989L64.991 55.3525Z"
      fill="#7D342D"
    />
    <path
      d="M67.6893 54.5044L65.9161 53.5793L64.0659 57.1257L65.8391 58.0508L67.6893 54.5044Z"
      fill="#7D342D"
    />
    <path
      d="M70.3876 53.6563L68.6144 52.7312L66.7642 56.2776L68.5374 57.2026L70.3876 53.6563Z"
      fill="#7D342D"
    />
    <path
      d="M72.1608 54.5814L70.3876 53.6563L69.4625 55.4295L71.2357 56.3546L72.1608 54.5814Z"
      fill="#7D342D"
    />
    <path
      d="M74.859 53.7333L71.3127 51.8831L70.3876 53.6563L73.934 55.5064L74.859 53.7333Z"
      fill="#7D342D"
    />
    <path
      d="M21.5092 34.9237L17.9629 33.0735L15.1876 38.3931L18.734 40.2433L21.5092 34.9237Z"
      fill="#7D342D"
    />
    <path
      d="M18.888 31.3003L17.1148 30.3752L14.3395 35.6948L16.1127 36.6199L18.888 31.3003Z"
      fill="#7D342D"
    />
    <path
      d="M18.0398 28.602L16.2666 27.6769L13.4914 32.9965L15.2646 33.9216L18.0398 28.602Z"
      fill="#7D342D"
    />
    <path
      d="M17.1917 25.9038L15.4185 24.9787L12.6433 30.2983L14.4165 31.2233L17.1917 25.9038Z"
      fill="#7D342D"
    />
    <path
      d="M15.4185 24.9787L13.6453 24.0536L11.7952 27.6L13.5684 28.5251L15.4185 24.9787Z"
      fill="#7D342D"
    />
    <path
      d="M14.5704 22.2804L12.7972 21.3553L10.9471 24.9017L12.7203 25.8268L14.5704 22.2804Z"
      fill="#7D342D"
    />
    <path
      d="M12.7972 21.3553L11.024 20.4302L10.0989 22.2034L11.8721 23.1285L12.7972 21.3553Z"
      fill="#7D342D"
    />
    <path
      d="M18.1168 24.1306L14.5704 22.2804L13.6453 24.0536L17.1917 25.9038L18.1168 24.1306Z"
      fill="#AB5232"
    />
    <path
      d="M30.6061 26.1347L27.0598 24.2845L24.747 28.7175L28.2934 30.5677L30.6061 26.1347Z"
      fill="#D87241"
    />
    <path
      d="M34.3065 19.0419L30.7601 17.1917L27.9848 22.5113L31.5312 24.3615L34.3065 19.0419Z"
      fill="#D87241"
    />
    <path
      d="M34.1525 27.9848L30.6061 26.1347L26.9058 33.2275L30.4522 35.0776L34.1525 27.9848Z"
      fill="#D87241"
    />
    <path
      d="M38.778 19.1189L35.2316 17.2687L31.5312 24.3615L35.0776 26.2117L38.778 19.1189Z"
      fill="#D87241"
    />
    <path
      d="M65.3759 32.9951L63.6027 32.07L59.9023 39.1628L61.6755 40.0879L65.3759 32.9951Z"
      fill="#D87241"
    />
    <path
      d="M66.224 35.6934L64.4508 34.7683L61.6755 40.0879L63.4487 41.013L66.224 35.6934Z"
      fill="#D87241"
    />
    <path
      d="M67.0721 38.3917L65.2989 37.4666L63.4487 41.013L65.2219 41.9381L67.0721 38.3917Z"
      fill="#D87241"
    />
    <path
      d="M67.9202 41.09L66.147 40.1649L65.2219 41.9381L66.9951 42.8632L67.9202 41.09Z"
      fill="#D87241"
    />
    <path
      d="M68.7683 43.7882L66.9951 42.8632L66.07 44.6364L67.8432 45.5614L68.7683 43.7882Z"
      fill="#D87241"
    />
    <path
      d="M70.6185 40.2419L68.8453 39.3168L66.9951 42.8632L68.7683 43.7882L70.6185 40.2419Z"
      fill="#7D342D"
    />
    <path
      d="M71.6205 33.9972L69.8474 33.0721L66.147 40.1649L67.9202 41.0899L71.6205 33.9972Z"
      fill="#7D342D"
    />
    <path
      d="M69.8474 33.0721L68.0742 32.147L65.2989 37.4666L67.0721 38.3917L69.8474 33.0721Z"
      fill="#7D342D"
    />
    <path
      d="M68.9992 30.3738L67.226 29.4487L64.4508 34.7683L66.224 35.6934L68.9992 30.3738Z"
      fill="#7D342D"
    />
    <path
      d="M67.226 29.4487L65.4529 28.5237L63.6027 32.07L65.3759 32.9951L67.226 29.4487Z"
      fill="#7D342D"
    />
    <path
      d="M41.5532 13.7993L38.0068 11.9491L35.2316 17.2687L38.778 19.1189L41.5532 13.7993Z"
      fill="#7D342D"
    />
    <path
      d="M37.0817 13.7223L33.5353 11.8721L30.7601 17.1917L34.3065 19.0419L37.0817 13.7223Z"
      fill="#7D342D"
    />
    <path
      d="M32.6103 13.6453L29.0639 11.7952L26.2886 17.1148L29.835 18.9649L32.6103 13.6453Z"
      fill="#7D342D"
    />
    <path
      d="M28.1388 13.5684L26.3656 12.6433L23.5903 17.9629L25.3635 18.8879L28.1388 13.5684Z"
      fill="#7D342D"
    />
    <path
      d="M25.4405 14.4165L23.6673 13.4914L20.8921 18.811L22.6653 19.7361L25.4405 14.4165Z"
      fill="#7D342D"
    />
    <path
      d="M22.7422 15.2646L20.969 14.3395L18.1938 19.6591L19.967 20.5842L22.7422 15.2646Z"
      fill="#7D342D"
    />
    <path
      d="M20.0439 16.1127L18.2708 15.1876L16.4206 18.734L18.1938 19.6591L20.0439 16.1127Z"
      fill="#7D342D"
    />
    <path
      d="M17.3457 16.9608L15.5725 16.0357L13.7223 19.5821L15.4955 20.5072L17.3457 16.9608Z"
      fill="#7D342D"
    />
    <path
      d="M14.6474 17.8089L12.8742 16.8839L11.9491 18.657L13.7223 19.5821L14.6474 17.8089Z"
      fill="#7D342D"
    />
    <path
      d="M25.3635 18.8879L23.5903 17.9629L21.7402 21.5092L23.5134 22.4343L25.3635 18.8879Z"
      fill="#AB5232"
    />
    <path
      d="M22.6653 19.7361L20.8921 18.811L19.967 20.5842L21.7402 21.5093L22.6653 19.7361Z"
      fill="#AB5232"
    />
    <path
      d="M19.967 20.5842L16.4206 18.734L15.4955 20.5072L19.0419 22.3574L19.967 20.5842Z"
      fill="#AB5232"
    />
    <path
      d="M30.7601 17.1917L27.2137 15.3416L23.5134 22.4343L27.0598 24.2845L30.7601 17.1917Z"
      fill="#AB5232"
    />
    <path
      d="M35.2316 17.2687L31.6852 15.4185L30.7601 17.1917L34.3065 19.0419L35.2316 17.2687Z"
      fill="#AB5232"
    />
    <path
      d="M39.703 17.3457L36.1566 15.4955L35.2316 17.2687L38.778 19.1189L39.703 17.3457Z"
      fill="#AB5232"
    />
    <path
      d="M66.3779 26.7505L41.5532 13.7993L39.703 17.3457L64.5278 30.2968L66.3779 26.7505Z"
      fill="#7D342D"
    />
    <path
      d="M73.2398 43.8652L69.6934 42.015L67.8432 45.5614L71.3896 47.4116L73.2398 43.8652Z"
      fill="white"
    />
    <path
      d="M74.1649 42.092L70.6185 40.2419L69.6934 42.015L73.2398 43.8652L74.1649 42.092Z"
      fill="#BDBDBD"
    />
    <path
      d="M26.2886 17.1148L22.7422 15.2646L21.8171 17.0378L25.3635 18.8879L26.2886 17.1148Z"
      fill="#BDBDBD"
    />
    <path
      d="M73.3168 39.3937L69.7704 37.5436L68.8453 39.3168L72.3917 41.1669L73.3168 39.3937Z"
      fill="#717171"
    />
    <path
      d="M28.1388 13.5684L24.5924 11.7182L22.7422 15.2646L26.2886 17.1148L28.1388 13.5684Z"
      fill="#717171"
    />
    <path
      d="M19.7361 33.9986L16.1897 32.1484L13.4144 37.468L16.9608 39.3182L19.7361 33.9986Z"
      fill="#717171"
    />
    <path
      d="M72.4687 36.6955L70.6955 35.7704L69.7704 37.5436L71.5436 38.4687L72.4687 36.6955Z"
      fill="#717171"
    />
    <path
      d="M66.9951 42.8631L35.0776 26.2116L30.4522 35.0776L62.3697 51.7291L66.9951 42.8631Z"
      fill="#D87241"
    />
    <path
      d="M64.5278 30.2968L39.703 17.3457L36.0027 24.4385L60.8274 37.3896L64.5278 30.2968Z"
      fill="#D87241"
    />
    <path
      d="M20.8151 23.2824L19.0419 22.3574L16.2666 27.6769L18.0398 28.602L20.8151 23.2824Z"
      fill="#AB5232"
    />
    <path
      d="M64.5278 30.2968L62.7546 29.3718L61.8295 31.145L63.6027 32.07L64.5278 30.2968Z"
      fill="#AB5232"
    />
    <path
      d="M65.3759 32.9951L63.6027 32.07L62.6776 33.8432L64.4508 34.7683L65.3759 32.9951Z"
      fill="#AB5232"
    />
    <path
      d="M66.224 35.6934L64.4508 34.7683L63.5257 36.5415L65.2989 37.4666L66.224 35.6934Z"
      fill="#AB5232"
    />
    <path
      d="M67.0721 38.3917L65.2989 37.4666L64.3738 39.2398L66.147 40.1649L67.0721 38.3917Z"
      fill="#AB5232"
    />
    <path
      d="M67.9202 41.09L66.147 40.1649L65.2219 41.9381L66.9951 42.8632L67.9202 41.09Z"
      fill="#AB5232"
    />
    <path
      d="M68.7683 43.7882L66.9951 42.8632L66.07 44.6364L67.8432 45.5614L68.7683 43.7882Z"
      fill="#AB5232"
    />
    <path
      d="M75.013 44.7903L73.2398 43.8652L71.3896 47.4116L73.1628 48.3367L75.013 44.7903Z"
      fill="#AB5232"
    />
    <path
      d="M75.8611 47.4886L74.0879 46.5635L72.2377 50.1099L74.0109 51.0349L75.8611 47.4886Z"
      fill="#AB5232"
    />
    <path
      d="M76.7092 50.1869L74.936 49.2618L74.0109 51.035L75.7841 51.9601L76.7092 50.1869Z"
      fill="#AB5232"
    />
    <path
      d="M22.5883 24.2075L20.8151 23.2824L17.1148 30.3752L18.888 31.3003L22.5883 24.2075Z"
      fill="#AB5232"
    />
    <path
      d="M63.6797 27.5986L40.6281 15.5725L39.703 17.3457L62.7546 29.3718L63.6797 27.5986Z"
      fill="#AB5232"
    />
    <path
      d="M26.1347 26.0577L22.5883 24.2075L17.9629 33.0735L21.5093 34.9237L26.1347 26.0577Z"
      fill="#AB5232"
    />
    <path
      d="M28.2934 30.5677L24.7471 28.7175L20.5842 36.6969L24.1306 38.547L28.2934 30.5677Z"
      fill="#AB5232"
    />
    <path
      d="M60.2109 47.2192L56.6646 45.369L52.5017 53.3484L56.0481 55.1985L60.2109 47.2192Z"
      fill="#AB5232"
    />
    <path
      d="M65.145 46.4096L61.5986 44.5594L56.9732 53.4254L60.5195 55.2755L65.145 46.4096Z"
      fill="#AB5232"
    />
    <path
      d="M68.6913 48.2597L65.145 46.4095L61.4446 53.5023L64.991 55.3525L68.6913 48.2597Z"
      fill="#AB5232"
    />
    <path
      d="M19.813 29.5271L16.2666 27.6769L15.3416 29.4501L18.888 31.3003L19.813 29.5271Z"
      fill="#BDBDBD"
    />
    <path
      d="M20.6612 32.2254L17.1148 30.3752L16.1897 32.1484L19.7361 33.9986L20.6612 32.2254Z"
      fill="#BDBDBD"
    />
    <path
      d="M70.4645 49.1848L68.6913 48.2597L65.9161 53.5793L67.6893 54.5044L70.4645 49.1848Z"
      fill="#AB5232"
    />
    <path
      d="M72.2377 50.1099L70.4645 49.1848L68.6144 52.7312L70.3876 53.6563L72.2377 50.1099Z"
      fill="#AB5232"
    />
    <path
      d="M55.2769 48.0288L26.9058 33.2274L23.2055 40.3202L51.5766 55.1216L55.2769 48.0288Z"
      fill="#AB5232"
    />
    <path
      d="M29.0637 11.7951L27.2907 10.8701L26.3656 12.6433L28.1386 13.5683L29.0637 11.7951Z"
      fill="black"
    />
    <path
      d="M26.3654 12.6432L24.5924 11.7182L23.6673 13.4914L25.4404 14.4164L26.3654 12.6432Z"
      fill="black"
    />
    <path
      d="M23.6672 13.4913L21.8941 12.5663L20.969 14.3395L22.7421 15.2645L23.6672 13.4913Z"
      fill="black"
    />
    <path
      d="M67.6893 54.5044L64.1429 52.6542L63.2178 54.4274L66.7642 56.2776L67.6893 54.5044Z"
      fill="#BDBDBD"
    />
    <path
      d="M66.7642 56.2776L63.2178 54.4274L62.2927 56.2006L65.8391 58.0508L66.7642 56.2776Z"
      fill="#717171"
    />
    <path
      d="M64.0659 57.1257L60.5195 55.2755L59.5945 57.0487L63.1409 58.8989L64.0659 57.1257Z"
      fill="#717171"
    />
    <path
      d="M63.1408 58.8989L59.5945 57.0487L58.6694 58.8219L62.2158 60.6721L63.1408 58.8989Z"
      fill="#717171"
    />
    <path
      d="M70.4645 49.1848L66.9182 47.3346L64.1429 52.6542L67.6893 54.5044L70.4645 49.1848Z"
      fill="white"
    />
    <path
      d="M22.5883 24.2075L19.0419 22.3574L16.2666 27.6769L19.813 29.5271L22.5883 24.2075Z"
      fill="white"
    />
    <path
      d="M25.3635 18.8879L21.8171 17.0378L19.967 20.5842L23.5134 22.4343L25.3635 18.8879Z"
      fill="white"
    />
    <path
      d="M20.9689 14.3394L19.1958 13.4144L18.2708 15.1876L20.0438 16.1126L20.9689 14.3394Z"
      fill="black"
    />
    <path
      d="M18.2706 15.1876L16.4976 14.2625L15.5725 16.0357L17.3455 16.9607L18.2706 15.1876Z"
      fill="black"
    />
    <path
      d="M12.7201 25.8267L10.9471 24.9017L10.022 26.6749L11.795 27.5999L12.7201 25.8267Z"
      fill="black"
    />
    <path
      d="M13.5682 28.525L11.7952 27.6L10.8701 29.3732L12.6431 30.2982L13.5682 28.525Z"
      fill="black"
    />
    <path
      d="M14.4163 31.2233L12.6433 30.2982L11.7182 32.0714L13.4912 32.9965L14.4163 31.2233Z"
      fill="black"
    />
    <path
      d="M15.2644 33.9215L13.4914 32.9965L12.5663 34.7697L14.3394 35.6947L15.2644 33.9215Z"
      fill="black"
    />
    <path
      d="M16.1126 36.6198L14.3395 35.6948L13.4144 37.468L15.1875 38.393L16.1126 36.6198Z"
      fill="black"
    />
    <path
      d="M18.734 40.2433L15.1876 38.3931L14.2625 40.1663L17.8089 42.0165L18.734 40.2433Z"
      fill="black"
    />
    <path
      d="M21.3553 43.8666L17.8089 42.0164L16.8838 43.7896L20.4302 45.6398L21.3553 43.8666Z"
      fill="black"
    />
    <path
      d="M15.5723 16.0357L13.7993 15.1107L12.8742 16.8839L14.6473 17.8089L15.5723 16.0357Z"
      fill="black"
    />
    <path
      d="M11.872 23.1284L10.099 22.2034L9.17387 23.9766L10.9469 24.9016L11.872 23.1284Z"
      fill="black"
    />
    <path
      d="M12.8742 16.8838L11.101 15.9587L8.32575 21.2783L10.0989 22.2034L12.8742 16.8838Z"
      fill="black"
    />
    <path
      d="M38.0068 11.9491L34.4604 10.0989L33.5354 11.8721L37.0817 13.7223L38.0068 11.9491Z"
      fill="black"
    />
    <path
      d="M69.9243 28.6006L66.3779 26.7505L65.4528 28.5237L68.9992 30.3738L69.9243 28.6006Z"
      fill="black"
    />
    <path
      d="M72.5456 32.224L68.9992 30.3738L68.0742 32.147L71.6205 33.9972L72.5456 32.224Z"
      fill="black"
    />
    <path
      d="M73.3937 34.9223L71.6205 33.9972L70.6955 35.7704L72.4687 36.6955L73.3937 34.9223Z"
      fill="black"
    />
    <path
      d="M74.2419 37.6205L72.4687 36.6955L71.5436 38.4687L73.3168 39.3937L74.2419 37.6205Z"
      fill="black"
    />
    <path
      d="M75.09 40.3188L73.3168 39.3937L72.3917 41.1669L74.1649 42.092L75.09 40.3188Z"
      fill="black"
    />
    <path
      d="M75.9381 43.0171L74.1649 42.092L73.2398 43.8652L75.013 44.7903L75.9381 43.0171Z"
      fill="black"
    />
    <path
      d="M76.7862 45.7154L75.013 44.7903L74.0879 46.5635L75.8611 47.4886L76.7862 45.7154Z"
      fill="black"
    />
    <path
      d="M77.6343 48.4137L75.8611 47.4886L74.936 49.2618L76.7092 50.1869L77.6343 48.4137Z"
      fill="black"
    />
    <path
      d="M75.7841 51.9601L72.2377 50.1099L71.3126 51.8831L74.859 53.7333L75.7841 51.9601Z"
      fill="black"
    />
    <path
      d="M73.1628 48.3367L66.07 44.6364L65.145 46.4095L72.2377 50.1099L73.1628 48.3367Z"
      fill="black"
    />
    <path
      d="M66.9951 42.8631L59.9023 39.1628L58.9773 40.936L66.07 44.6363L66.9951 42.8631Z"
      fill="black"
    />
    <path
      d="M19.0419 22.3574L11.9491 18.657L11.024 20.4302L18.1168 24.1306L19.0419 22.3574Z"
      fill="black"
    />
    <path
      d="M27.0598 24.2845L19.967 20.5842L19.0419 22.3574L26.1347 26.0577L27.0598 24.2845Z"
      fill="black"
    />
    <path
      d="M35.0776 26.2117L27.9848 22.5113L27.0598 24.2845L34.1525 27.9849L35.0776 26.2117Z"
      fill="black"
    />
    <path
      d="M39.5876 24.0529L36.8123 29.3725L38.5855 30.2976L41.3608 24.978L39.5876 24.0529Z"
      fill="white"
    />
    <path
      d="M44.9072 26.8281L42.1319 32.1477L43.9051 33.0728L46.6804 27.7532L44.9072 26.8281Z"
      fill="white"
    />
    <path
      d="M50.2267 29.6034L47.4515 34.923L49.2247 35.8481L51.9999 30.5285L50.2267 29.6034Z"
      fill="white"
    />
    <path
      d="M55.5463 32.3786L52.7711 37.6982L54.5443 38.6233L57.3195 33.3037L55.5463 32.3786Z"
      fill="white"
    />
    <path
      d="M60.8274 37.3896L36.0027 24.4384L35.0776 26.2116L59.9023 39.1628L60.8274 37.3896Z"
      fill="white"
    />
    <path
      d="M73.934 55.5064L72.1608 54.5814L71.2357 56.3546L73.0089 57.2796L73.934 55.5064Z"
      fill="black"
    />
    <path
      d="M71.2357 56.3546L69.4625 55.4295L68.5374 57.2027L70.3106 58.1278L71.2357 56.3546Z"
      fill="black"
    />
    <path
      d="M68.5374 57.2027L66.7642 56.2776L65.8391 58.0508L67.6123 58.9759L68.5374 57.2027Z"
      fill="black"
    />
    <path
      d="M65.8391 58.0508L64.0659 57.1257L63.1408 58.8989L64.914 59.8239L65.8391 58.0508Z"
      fill="black"
    />
    <path
      d="M63.1408 58.8989L61.3676 57.9738L60.4426 59.747L62.2158 60.6721L63.1408 58.8989Z"
      fill="black"
    />
    <path
      d="M60.4426 59.747L58.6694 58.8219L57.7443 60.5951L59.5175 61.5202L60.4426 59.747Z"
      fill="black"
    />
    <path
      d="M57.7443 60.5951L54.1979 58.7449L53.2728 60.5181L56.8192 62.3683L57.7443 60.5951Z"
      fill="black"
    />
    <path
      d="M53.2728 60.5181L49.7264 58.668L48.8013 60.4412L52.3477 62.2913L53.2728 60.5181Z"
      fill="black"
    />
    <path
      d="M78.4824 51.1119L76.7092 50.1869L73.934 55.5064L75.7072 56.4315L78.4824 51.1119Z"
      fill="black"
    />
    <path
      d="M33.5353 11.8721L29.989 10.022L29.0639 11.7952L32.6103 13.6453L33.5353 11.8721Z"
      fill="black"
    />
  </svg>
);

export default Football;
