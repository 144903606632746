"use client";

import useStore from "./lib/store";
import { useEffect, useRef, useState } from "react";
import Loader from "./Components/Loader";
import HomeContent from "./Components/Home";
import NavigationBar from "./Components/Home/NavigationBar";
import DailyReward from "./Components/DailyReward";
import DailyRewardPopup from "./Components/DailyReward/DailyRewardPopup";
import { useAuth } from "./providers/AuthProvider";
import TicketTracker from "./Components/TicketTracker";
import ZeroTicketPopup from "./Components/TicketTracker/ZeroTicketPopup";
import AuthPopup from "./Components/Home/AuthPopup";

const Home = () => {
  const {
    isLoading,
    isClaimingDailyReward,
    isInitTelegram,
    isShowingAuthExpiredModal,
  } = useAuth();

  const user = useStore(state => state.user);
  const nextDailyReward = useStore(state => state.nextDailyReward);
  const showZeroTicketModal = useStore(state => state.showZeroTicketModal);
  const [isShowingDailyRewardNotification, setIsShowingDailyRewardNotification] = useState(false);
  const inactivityTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const debounceTimer = useRef<NodeJS.Timeout | undefined>(undefined);
  const isInactive = useRef(false);

  useEffect(() => {
    if (nextDailyReward) {
      const now = new Date().getTime();
      const difference = nextDailyReward - now + 1000; // Add 1 second buffer to allow for state change of Daily Timer icon
      const timer = setTimeout(() => {
        setIsShowingDailyRewardNotification(true);
      }, difference);

      return () => clearTimeout(timer); // Cleanup on dependency change
    }
  }, [nextDailyReward]);

  useEffect(() => {
  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(() => {
      isInactive.current = true;
    }, 1000 * 60 * 30); // 30 minutes
  };

  const debounceReset = () => {
    if (isInactive.current) {
      // inactive for 30 minutes or more, reload app on user return to prevent telegram context timeout
      window.location.reload();
    }
    if (debounceTimer.current) return;
    debounceTimer.current = setTimeout(() => {
      resetInactivityTimeout();
      debounceTimer.current = undefined;
    }, 10000); // 10 second debounce
  };

  ["mousemove", "keydown", "touchstart"].forEach(event =>
    window.addEventListener(event, debounceReset)
  );

  resetInactivityTimeout();

  return () => {
    ["mousemove", "keydown", "touchstart"].forEach(event =>
      window.removeEventListener(event, debounceReset)
    );
    if (inactivityTimeout.current) {
      clearTimeout(inactivityTimeout.current);
      inactivityTimeout.current = undefined;
    }
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
      debounceTimer.current = undefined;
    }
  };
}, []);

  if (isLoading) {
    return (
      <div className="w-full h-screen relative flex justify-center items-center">
        <div
          className={`absolute w-full h-full inset-0 bg-splash bg-cover bg-center ${
            isInitTelegram ? "" : "opacity-50"
          }`}
        />
        {!isInitTelegram && (
          <div className="relative z-10">
            <Loader />
          </div>
        )}
      </div>
    );
  }

  if (!user && !isShowingAuthExpiredModal) {
    return null;
  }

  const isZeroTicketModalVisible = showZeroTicketModal && !isClaimingDailyReward && !isShowingDailyRewardNotification && !isShowingAuthExpiredModal;
  const isDailyRewardPopupVisible = isShowingDailyRewardNotification && !isShowingAuthExpiredModal;
  const isDailyRewardModalVisible = isClaimingDailyReward && !isShowingAuthExpiredModal;

  return (
    <>
      <main className="w-full">
        <TicketTracker />
        {isDailyRewardModalVisible && <DailyReward />}
        {!isClaimingDailyReward && <HomeContent />}
        {isDailyRewardPopupVisible && (
          <DailyRewardPopup
            onClose={() => {
              setIsShowingDailyRewardNotification(false);
            }}
          />
        )}
        {isZeroTicketModalVisible && <ZeroTicketPopup />}
        {isShowingAuthExpiredModal && <AuthPopup />}
      </main>
      {!isClaimingDailyReward && <NavigationBar />}
    </>
  );
};

export default Home;