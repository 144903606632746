const Block = ({
  width = 78,
  height = 36,
  primaryColor = "",
  backgroundColorLeftTop = "",
  backgroundColorRightBottom = "",
  rightBottomCornerColor = "",
  leftBottomCornerColor = "",
  rightTopCornerColor = "",
  leftTopCornerColor = ""
}) => {
  const originalWidth = 78;
  const originalHeight = 36;

  const scaleX = width / originalWidth;
  const scaleY = height / originalHeight;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <rect y={4 * scaleY} width={2 * scaleX} height={28 * scaleY} fill="black" />
      <rect x={76 * scaleX} y={4 * scaleY} width={2 * scaleX} height={28 * scaleY} fill="black" />
      <rect
        x={2 * scaleX}
        y={4 * scaleY}
        width={2 * scaleX}
        height={26 * scaleY}
        fill={backgroundColorLeftTop || "#F1F2F2"}
      />
      <rect
        x={74 * scaleX}
        y={4 * scaleY}
        width={2 * scaleX}
        height={26 * scaleY}
        fill={backgroundColorRightBottom || "#F1F2F2"}
      />
      <rect x={4 * scaleX} y={32 * scaleY} width={70 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={4 * scaleX} y={34 * scaleY} width={70 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={2 * scaleX} y={30 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={2 * scaleX} y={32 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect
        x={4 * scaleX}
        y={30 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={leftBottomCornerColor || "#D1D3D4"}
      />
      <rect
        x={2 * scaleX}
        y={28 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={leftBottomCornerColor || "#D1D3D4"}
      />
      <rect x={4 * scaleX} width={70 * scaleX} height={2 * scaleY} fill="black" />
      <rect
        x={4 * scaleX}
        y={30 * scaleY}
        width={70 * scaleX}
        height={2 * scaleY}
        fill={backgroundColorRightBottom || "#D1D3D4"}
      />
      <rect x={2 * scaleX} y={2 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={74 * scaleX} y={2 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={74 * scaleX} y={30 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect x={74 * scaleX} y={32 * scaleY} width={2 * scaleX} height={2 * scaleY} fill="black" />
      <rect
        x={72 * scaleX}
        y={30 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={rightBottomCornerColor || "#D1D3D4"}
      />
      <rect
        x={74 * scaleX}
        y={28 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={rightBottomCornerColor || "#D1D3D4"}
      />
      <rect
        x={4 * scaleX}
        y={2 * scaleY}
        width={70 * scaleX}
        height={2 * scaleY}
        fill={backgroundColorLeftTop || "#F1F2F2"}
      />
      <rect
        x={4 * scaleX}
        y={4 * scaleY}
        width={70 * scaleX}
        height={26 * scaleY}
        fill={primaryColor || "#F1F2F2"}
      />
      <rect
        x={2 * scaleX}
        y={4 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={leftTopCornerColor || "#F1F2F2"}
      />
      <rect
        x={4 * scaleX}
        y={30 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={leftBottomCornerColor || "#D1D3D4"}
      />
      <rect
        x={4 * scaleX}
        y={2 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={leftTopCornerColor || "#F1F2F2"}
      />
      <rect
        x={72 * scaleX}
        y={2 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={rightTopCornerColor || "#F1F2F2"}
      />
      <rect
        x={74 * scaleX}
        y={4 * scaleY}
        width={2 * scaleX}
        height={2 * scaleY}
        fill={rightTopCornerColor || "#F1F2F2"}
      />
    </svg>
  );
};

export default Block;
