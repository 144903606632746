const CircleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <rect x="8.33335" y="23.3333" width="8.33335" height="1.66667" fill="black" />
    <rect x="8.33335" width="8.33335" height="1.66667" fill="black" />
    <rect x="8.33335" y="1.6667" width="8.33335" height="1.66667" fill="white" />
    <rect x="4.99995" y="5.00002" width="1.66667" height="1.66667" fill="white" />
    <rect x="6.66665" y="5.00002" width="1.66667" height="1.66667" fill="white" />
    <rect x="6.66665" y="6.66665" width="1.66667" height="1.66667" fill="white" />
    <rect x="4.99995" y="8.33335" width="5.00001" height="1.66667" fill="white" />
    <rect x="3.3334" y="10" width="8.33335" height="1.66667" fill="white" />
    <rect x="3.3334" y="11.6667" width="8.33335" height="1.66667" fill="white" />
    <rect x="4.99995" y="13.3334" width="5.00001" height="1.66667" fill="white" />
    <rect x="1.6667" y="13.3334" width="3.33334" height="1.66667" fill="white" />
    <rect x="1.6667" y="11.6667" width="1.66667" height="1.66667" fill="white" />
    <rect x="1.6667" y="15" width="1.66667" height="1.66667" fill="white" />
    <rect x="6.66665" y="15" width="1.66667" height="1.66667" fill="white" />
    <rect x="6.66665" y="3.33332" width="1.66667" height="1.66667" fill="white" />
    <rect x="8.33335" y="3.33332" width="1.66667" height="3.33334" fill="white" />
    <rect x="10.0001" y="5.00002" width="5.00001" height="1.66667" fill="white" />
    <rect x="8.33335" y="6.66665" width="8.33335" height="1.66667" fill="white" />
    <rect x="10.0001" y="8.33335" width="5.00001" height="1.66667" fill="white" />
    <rect x="11.6667" y="10" width="1.66667" height="1.66667" fill="white" />
    <rect x="3.3334" y="6.66665" width="3.33334" height="1.66667" fill="white" />
    <rect x="1.6667" y="8.33335" width="3.33334" height="1.66667" fill="white" />
    <rect x="1.6667" y="10" width="1.66667" height="1.66667" fill="white" />
    <rect x="10.0001" y="3.33332" width="8.33335" height="1.66667" fill="white" />
    <rect x="15" y="5.00002" width="5.00001" height="1.66667" fill="white" />
    <rect x="20.0001" y="8.33335" width="1.66667" height="1.66667" fill="white" />
    <rect x="21.6666" y="8.33335" width="1.66667" height="8.33335" fill="white" />
    <rect x="20.0001" y="13.3334" width="1.66667" height="5.00001" fill="white" />
    <rect x="18.3334" y="15" width="1.66667" height="5.00001" fill="white" />
    <rect x="16.6667" y="16.6667" width="1.66667" height="5.00001" fill="white" />
    <rect x="15" y="18.3334" width="1.66667" height="3.33334" fill="white" />
    <rect x="8.33335" y="21.6667" width="8.33335" height="1.66667" fill="white" />
    <rect x="10.0001" y="20" width="5.00001" height="1.66667" fill="white" />
    <rect x="6.66665" y="20" width="3.33334" height="1.66667" fill="white" />
    <rect x="4.99995" y="18.3334" width="5.00001" height="1.66667" fill="white" />
    <rect x="3.3334" y="16.6667" width="5.00001" height="1.66667" fill="white" />
    <rect x="3.3334" y="15" width="3.33334" height="1.66667" fill="white" />
    <rect x="10.0001" y="18.3334" width="5.00001" height="1.66667" fill="white" />
    <rect x="8.33335" y="16.6667" width="8.33335" height="1.66667" fill="white" />
    <rect x="8.33335" y="15" width="8.33335" height="1.66667" fill="white" />
    <rect x="10.0001" y="13.3334" width="5.00001" height="1.66667" fill="white" />
    <rect x="11.6667" y="11.6667" width="1.66667" height="1.66667" fill="white" />
    <rect x="18.3334" y="8.33335" width="1.66667" height="6.66668" fill="white" />
    <rect x="13.3333" y="10" width="1.66667" height="3.33334" fill="white" />
    <rect x="20.0001" y="10" width="1.66667" height="3.33334" fill="white" />
    <rect x="16.6667" y="6.66665" width="1.66667" height="10" fill="white" />
    <rect x="15" y="8.33335" width="1.66667" height="6.66668" fill="white" />
    <rect x="18.3334" y="6.66665" width="3.33334" height="1.66667" fill="white" />
    <rect x="16.6667" y="21.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="18.3334" y="20" width="1.66667" height="1.66667" fill="black" />
    <rect x="20.0001" y="18.3334" width="1.66667" height="1.66667" fill="black" />
    <rect x="21.6666" y="16.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="21.6666" y="6.66665" width="1.66667" height="1.66667" fill="black" />
    <rect x="20.0001" y="5.00002" width="1.66667" height="1.66667" fill="black" />
    <rect x="18.3334" y="3.33332" width="1.66667" height="1.66667" fill="black" />
    <rect x="16.6667" y="1.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="6.66665" y="1.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="4.99995" y="3.33332" width="1.66667" height="1.66667" fill="black" />
    <rect x="3.3334" y="5.00002" width="1.66667" height="1.66667" fill="black" />
    <rect x="1.6667" y="6.66665" width="1.66667" height="1.66667" fill="black" />
    <rect x="1.6667" y="16.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="3.3334" y="18.3334" width="1.66667" height="1.66667" fill="black" />
    <rect x="4.99995" y="20" width="1.66667" height="1.66667" fill="black" />
    <rect x="6.66665" y="21.6667" width="1.66667" height="1.66667" fill="black" />
    <rect x="23.3333" y="8.33335" width="1.66667" height="8.33335" fill="black" />
    <rect y="8.33335" width="1.66667" height="8.33335" fill="black" />
  </svg>
);

export default CircleIcon;
