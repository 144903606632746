const StreakCircle = ({ active = false, className }: { active?: boolean; className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    className={className}
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.8214 0H8.67857V2.89286H5.78572V5.78571H2.89286V8.67857H0V31.8214H2.89286V34.7143H5.78572V37.6071H8.67857V40.5H31.8214V37.6071H34.7143V34.7143H37.6071V31.8214H40.5V8.67857H37.6071V5.78571H34.7143V2.89286H31.8214V0Z"
      fill={active ? "#FF4000" : "black"}
      fillOpacity={active ? "100" : "0.6"}
    />
  </svg>
);

export default StreakCircle;
