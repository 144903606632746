import { useMemo } from "react";
import { GAME_SCREENS, GAMES } from "../../types/home";
import { getUserHighScore, getUserRank } from "../../utils/play";
import { UserStat } from "../../types/user";

interface GameRankProps {
  userStats: UserStat[];
  gameScreen: GAME_SCREENS;
}

const GameRank = ({ userStats, gameScreen }: GameRankProps) => {
  const playerRank = useMemo(() => getUserRank(userStats, gameScreen === GAME_SCREENS.GLOVE_HERO ? GAMES.GLOVE_HERO : GAMES.ICE_SNAKE), [gameScreen, userStats]);
  const highScore = useMemo(() => getUserHighScore(gameScreen === GAME_SCREENS.GLOVE_HERO ? GAMES.GLOVE_HERO : GAMES.ICE_SNAKE), [gameScreen]);

  return (
    <div className="flex items-center justify-between bg-black bg-opacity-60 w-full text-secondaryWhite p-4 relative left-1/2 transform -translate-x-1/2 h-[56px]">
      <div className="flex flex-col items-start justify-center">
        <div className="text-sm">Game Rank</div>
        <div className="text-2xl">{`${playerRank !== "Unranked" ? "#" : ""}${playerRank}`}</div>
      </div>
      <div className="flex flex-col items-end justify-center">
        <div className="text-sm">My High Score</div>
        <div className="text-2xl">{highScore}</div>
      </div>
    </div>
  );
};

export default GameRank;