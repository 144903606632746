const IconTimer = ({className="", fill="#797979"})=> (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M12 18.6C13.4852 18.6 14.9096 18.01 15.9598 16.9598C17.01 15.9096 17.6 14.4852 17.6 13C17.6 11.5148 17.01 10.0904 15.9598 9.0402C14.9096 7.99 13.4852 7.4 12 7.4C10.5148 7.4 9.09041 7.99 8.0402 9.0402C6.99 10.0904 6.4 11.5148 6.4 13C6.4 14.4852 6.99 15.9096 8.0402 16.9598C9.09041 18.01 10.5148 18.6 12 18.6ZM12 6C12.9193 6 13.8295 6.18106 14.6788 6.53284C15.5281 6.88463 16.2997 7.40024 16.9497 8.05025C17.5998 8.70026 18.1154 9.47194 18.4672 10.3212C18.8189 11.1705 19 12.0807 19 13C19 14.8565 18.2625 16.637 16.9497 17.9497C15.637 19.2625 13.8565 20 12 20C8.129 20 5 16.85 5 13C5 11.1435 5.7375 9.36301 7.05025 8.05025C8.36301 6.7375 10.1435 6 12 6ZM12.35 9.5V13.175L15.5 15.044L14.975 15.905L11.3 13.7V9.5H12.35Z"
      fill={fill}
    />
  </svg>
);

export default IconTimer;
