"use client";

import useStore from "../../lib/store";
import { COMMUNITY_TABS } from "../../types/community";

const CommunityTabs = () => {
  const activeTab = useStore((state) => state.activeCommunityTab);
  const setActiveTab = useStore((state) => state.setActiveCommunityTab);
  return (
    <section className="grid grid-cols-2 w-full mt-4 h-[30px] px-2">
      <button
        onClick={() => setActiveTab(COMMUNITY_TABS.INVITE)}
        className={
          activeTab === COMMUNITY_TABS.INVITE
            ? "bg-white"
            : "bg-black text-darkGrey border-b-[3px] border-white"
        }
      >
        <div>{COMMUNITY_TABS.INVITE}</div>
      </button>
      <button
        onClick={() => setActiveTab(COMMUNITY_TABS.LEADERBOARDS)}
        className={
          activeTab === COMMUNITY_TABS.LEADERBOARDS
            ? "bg-white"
            : "bg-black text-darkGrey border-b-[3px] border-white"
        }
      >
        <div>{COMMUNITY_TABS.LEADERBOARDS}</div>
      </button>
    </section>
  );
};

export default CommunityTabs;
