import InfiniteScroll from "react-infinite-scroll-component";
import { RANKING_TYPES, RankingRecord } from "../../../types/community";
import LeaderboardRecord from "./LeaderboardRecord";
import Loader from "../../Loader";
import { useEffect, useState } from "react";
import { getRankings, getTitleAndIcon, isCurrentUserRecord } from "../../../utils/leaderboard";
import useStore from "../../../lib/store";

const LIMIT = 10;

const LeaderboardRecordScrollContainer = ({ rankingType }: { rankingType: RANKING_TYPES }) => {
  const [ rankings, setRankings ] = useState<RankingRecord[]>([]);
  const [ hasMoreRecords, setHasMoreRecords ] = useState(true);
  const [ isFetching, setIsFetching ] = useState(false);

  const user = useStore(state => state.user);

  const fetchMoreRecords = async () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    const resp = await getRankings(rankingType, rankings.length, LIMIT);
    if (resp.status === 200 && resp.data) {
      const { leaderboard } = resp.data;
      setRankings([...rankings, ...leaderboard]);
      if (leaderboard.length < LIMIT) {
        setHasMoreRecords(false);
      }
    }
    setIsFetching(false);
  };

  const getRankTitle = (rank: number, rankingType: RANKING_TYPES) => {
    const { title, icon } = getTitleAndIcon(rankingType);
    if (rank === 1) {
      return <div className="flex gap-1"><span>{title}</span><span>{icon}</span></div>;
    } 
    return undefined;
  };

  useEffect(() => {
    // initial load
    fetchMoreRecords();
  }, []);

  return (
    <InfiniteScroll
      className="flex flex-col gap-2 items-center"
      dataLength={rankings.length}
      next={fetchMoreRecords}
      hasMore={hasMoreRecords}
      loader={<div className="loader flex items-center justify-center mt-[100px]"><Loader width={32} height={32} /></div>}
      scrollableTarget="scrollableDiv"
    >
      {rankings.length > 0 &&
        rankings.map((ranking, index) => (
          <LeaderboardRecord
            key={index}
            user={ranking.username}
            rank={ranking.rank}
            score={ranking.score}
            width={380}
            boxStyle={true}
            backgroundColor={ user && isCurrentUserRecord(user, ranking.username) ? "#90D6FF" : "#F1F2F2" }
            bottomAccentColor={ user && isCurrentUserRecord(user, ranking.username) ? "#519DCB" : "#D9D9D9" }
            rankTitle={getRankTitle(ranking.rank, rankingType)}
          />
        ))}
    </InfiniteScroll>
  );
};

export default LeaderboardRecordScrollContainer;
