import { RANKING_TYPES } from "../../../types/community";
import Gem from "../../icons/Gem";
import NavigationArrow from "../../icons/NavigationArrow";
import RankingsDropdown from "./RankingsDropdown";
import LeaderboardRecordScrollContainer from "./LeaderboardRecordScrollContainer";
interface RankingsProps {
  rankingType: RANKING_TYPES
  setViewingRecordType: (recordType: RANKING_TYPES | undefined) => void
  userStats: {
    userRank: number
    userRankDescription: string | undefined
    userScore: number
  }
}

const Rankings = ({
  rankingType,
  setViewingRecordType,
  userStats,
}: RankingsProps) => {
  const { userRank, userRankDescription, userScore } = userStats;
  return (
    <div className="flex flex-col gap-4 w-full">
      {/* HEADER */}
      <section className="flex mt-4 items-center justify-between px-4">
        <button className="flex gap-2 items-center" onClick={() => setViewingRecordType(undefined)}>
          <NavigationArrow />
          <h1 className="bg-black text-white text-2xl/6 px-2">{rankingType}</h1>
        </button>
        <RankingsDropdown />
      </section>
      {/* MY RANKING */}
      <section className="flex bg-lightBlue items-center justify-between px-2 h-[42px]">
        <div className="flex gap-4">
          <div>My Rank</div>
          <div>{`#${userRank}`}</div>
          <div className="opacity-50">{userRankDescription}</div>
        </div>
        <div className="flex gap-1 items-center">
          <Gem />
          {userScore}
        </div>
      </section>
      {/* RANKINGS */}
      <LeaderboardRecordScrollContainer rankingType={rankingType} />
    </div>
  );
};

export default Rankings;
