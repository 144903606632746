"use client";

import { CustomTask } from "../../types/earn";
import EarnTask from "./EarnTask";

interface ActivityListProps {
  tasks: CustomTask[];
}

const EarnTaskList = ({ tasks }: ActivityListProps) => {
  return (
    <div className="flex flex-col items-center">
      {tasks?.map((task) => (
        <EarnTask key={task.id} task={task} />
      ))}
    </div>
  );
};

export default EarnTaskList;
