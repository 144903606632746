const HockeyPuck = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="44" viewBox="0 0 46 44" fill="none" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.1351 12.9499L36.4121 14.8147L38.2769 15.5377L37.5538 17.4024L39.4186 18.1255L38.6956 19.9902L40.5603 20.7132L39.8373 22.578L39.1143 24.4427L38.3912 26.3074L37.6682 28.1722L35.8035 27.4491L35.0805 29.3139L33.2157 28.5909L32.4927 30.4556L30.628 29.7326L29.905 31.5973L11.2576 24.3671L11.9806 22.5024L10.1159 21.7794L10.8389 19.9146L8.97415 19.1916L9.69717 17.3269L7.83243 16.6039L8.55545 14.7391L9.27847 12.8744L10.0015 11.0097L10.7245 9.14492L12.5892 9.86793L13.3123 8.0032L15.177 8.72622L15.9 6.86148L17.7648 7.5845L18.4878 5.71976L37.1351 12.9499Z"
      fill="#30323D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1327 9.9629L21.8557 8.09817L33.0442 12.4363L32.3211 14.301L34.1859 15.024L33.4629 16.8888L35.3276 17.6118L34.6046 19.4765L33.8816 21.3413L32.0168 20.6182L31.2938 22.483L29.4291 21.76L28.7061 23.6247L17.5176 19.2866L18.2406 17.4219L16.3759 16.6988L17.0989 14.8341L15.2342 14.1111L15.9572 12.2463L16.6802 10.3816L18.545 11.1046L19.268 9.23989L21.1327 9.9629Z"
      fill="#5E6172"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4878 5.71976L37.1351 12.9499L36.4121 14.8147L38.2769 15.5377L37.5538 17.4024L39.4186 18.1255L38.6956 19.9902L40.5603 20.7132L37.6682 28.1722L35.8035 27.4491L35.0805 29.3139L33.2157 28.5909L32.4927 30.4556L30.628 29.7326L29.905 31.5973L11.2576 24.3671L11.9806 22.5024L10.1159 21.7794L10.8389 19.9146L8.97415 19.1916L9.69717 17.3269L7.83243 16.6039L10.7245 9.14492L12.5892 9.86794L13.3123 8.0032L15.177 8.72622L15.9 6.86148L17.7648 7.5845L18.4878 5.71976ZM18.4878 5.71976L16.623 4.99674L15.9 6.86148L14.0353 6.13846L13.3123 8.0032L11.4475 7.28018L10.7245 9.14492L8.85977 8.4219L3.07562 23.3398L4.94036 24.0628L4.21734 25.9276L6.08208 26.6506L5.35906 28.5153L7.2238 29.2383L6.50078 31.1031L8.36552 31.8261L7.6425 33.6908L26.2899 40.921L27.0129 39.0563L28.8776 39.7793L29.6006 37.9145L31.4654 38.6376L32.1884 36.7728L34.0531 37.4958L34.7762 35.6311L36.6409 36.3541L42.425 21.4362L40.5603 20.7132L41.2833 18.8485L39.4186 18.1255L40.1416 16.2607L38.2769 15.5377L38.9999 13.673L37.1351 12.9499L37.8582 11.0852L19.2108 3.85503L18.4878 5.71976Z"
      fill="black"
    />
  </svg>
);

export default HockeyPuck;
