const SmallArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <rect y="2" width="2" height="2" fill="white" />
    <rect x="2" y="4" width="2" height="2" fill="white" />
    <rect x="4" y="6" width="2" height="2" fill="white" />
    <rect x="6" y="4" width="2" height="2" fill="white" />
    <rect x="8" y="2" width="2" height="2" fill="white" />
  </svg>
);

export default SmallArrow;
