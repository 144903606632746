const CopyIcon = ({ color = "white" }: {color?: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
    <rect x="0.75" y="3.45654" width="11.8695" height="2.54347" fill={color} />
    <rect x="8.38062" y="0.913055" width="11.8695" height="2.54347" fill={color} />
    <rect x="10.9241" y="5.99997" width="1.69564" height="2.54347" fill={color} />
    <rect x="0.75" y="8.54346" width="11.8695" height="2.54347" fill={color} />
    <rect x="0.75" y="5.99997" width="3.39129" height="3.39129" fill={color} />
    <rect x="17.7065" y="3.45654" width="2.54347" height="2.54347" fill={color} />
    <rect x="8.38062" y="5.99997" width="11.8695" height="2.54347" fill={color} />
  </svg>
);

export default CopyIcon;
