import { RANKING_TYPES } from "../types/community";
import { GAMES } from "../types/home";
import { User } from "../types/user";
import { getRequest } from "./requests";

export const getGameQueryString = (game: RANKING_TYPES) => {
  switch (game) {
    case RANKING_TYPES.TOP_EARNERS:
      return undefined;
    case RANKING_TYPES.ICE_SNAKE:
      return GAMES.ICE_SNAKE;
    case RANKING_TYPES.GLOVE_HERO:
      return GAMES.GLOVE_HERO;
    default:
      return undefined;
  }
};

export const getRankings = async (rankingType: RANKING_TYPES, offset: number, limit: number) => {
  const game = getGameQueryString(rankingType);
  let url = `miniapp/leaderboard?limit=${limit}&&offset=${offset}`;
  if (game) {
    url += `&game=${game}`;
  }
  return await getRequest(url);
};

export const calculateRankingsPercent = (userRank: number, totalPlayers: number) => {
  // totalPlayers will reset to 0 every week
  // only players with points will be ranked on the leaderboard
  if (totalPlayers === 0 || userRank === 0 || userRank === null) {
    return "Unranked";
  }

  const rankPercent = ((totalPlayers - userRank) / totalPlayers) * 100;

  if (rankPercent >= 99) return "Top 1%";
  if (rankPercent >= 90) return "Top 10%";
  if (rankPercent >= 75) return "Top 25%";
  if (rankPercent >= 50) return "Top 50%";
  if (rankPercent > 0) return "Bottom 50%";
  
  return "Unranked"; // Default fallback
};

export const isCurrentUserRecord = (user: User, userId: string) => {
  return userId === user?.username || userId === `${user?.first_name}${user?.last_name}`;
};

export const getTitleAndIcon = (rankingType: RANKING_TYPES) => {
  switch (rankingType) {
    case RANKING_TYPES.TOP_EARNERS:
      return { title: "Top Earner", icon: "👑" };
    case RANKING_TYPES.ICE_SNAKE:
      return { title: "Ice Snake", icon: "🥶 KING" };
    case RANKING_TYPES.GLOVE_HERO:
      return { title: "Glove Hero", icon: "🧤 KING" };
    default:
      return { title: "", icon: "" };
  }
};
