"use client";

import { useEffect } from "react";
import { getRequest } from "../../utils/requests";
import useStore from "../../lib/store";

const TicketTracker = () => {
  const setTicketCount = useStore(state => state.setTicketCount);
  const setShowZeroTicketModal = useStore(state => state.setShowZeroTicketModal);

  const getTicketStatus = async () => {
    const res = await getRequest("miniapp/user/ticket-status");
    const { status, data } = res || {};
    if (status === 200) {
      const { balance, notification } = data || {};
      setTicketCount(balance);
      setShowZeroTicketModal(notification);
    }
  };

  useEffect(() => {
    getTicketStatus();

    const interval = setInterval(() => {
      getTicketStatus();
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return null;
};

export default TicketTracker;
