"use client";

import { NAVIGATION_TABS } from "../../types/home";
import AccountFooter from "./AccountFooter";
import AccountConnect from "./AccountConnect";
import AccountHeader from "./AccountHeader";
import TonWalletConnect from "./TonWalletConnect";
import useStore from "../../lib/store";

const Account = () => {
  const setActiveTab = useStore(state => state.setActiveTab);

  return (
    <section className="flex flex-col pt-6 px-2 pb-[79px] bg-[url('/image/purple-background.png')] bg-top bg-fixed bg-[length:100%_auto] min-h-screen justify-center gap-4">
      <div className="flex flex-col">
        <AccountHeader />
        <TonWalletConnect />
        <AccountConnect />
        <div className="flex justify-center mt-2 text-[14px] text-white mb-4">
          <button className="underline cursor-pointer" onClick={() => setActiveTab(NAVIGATION_TABS.ACTIVITY_LOG)}>ACTIVITY HISTORY</button>
        </div>
      </div>
      <AccountFooter />
    </section>
  );
};

export default Account;
