const Ticket = ({ className = "", width = 15, height = 11 }: { className?: string, width?: number, height?: number}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 11"
    fill="none"
    className={className}
  >
    <rect x="1" y="10" width="7" height="1" fill="black" />
    <rect x="1" width="7" height="1" fill="black" />
    <rect x="9" y="10" width="5" height="1" fill="black" />
    <rect x="9" width="5" height="1" fill="black" />
    <rect y="9" width="8" height="1" fill="#16A48A" />
    <rect x="8" y="9" width="6" height="1" fill="#A1AAB7" />
    <rect x="8" y="1" width="6" height="8" fill="#C7D1DB" />
    <rect x="1" y="1" width="7" height="8" fill="#25C4A7" />
    <rect x="8" y="1" width="1" height="1" fill="#A1AAB7" />
    <rect x="8" y="3" width="1" height="1" fill="#A1AAB7" />
    <rect x="8" y="5" width="1" height="1" fill="#A1AAB7" />
    <rect x="8" y="7" width="1" height="1" fill="#A1AAB7" />
    <rect y="1" width="1" height="4" fill="black" />
    <rect y="6" width="1" height="4" fill="black" />
    <rect x="1" y="5" width="2" height="1" fill="black" />
    <rect x="12" y="5" width="2" height="1" fill="black" />
    <rect x="14" y="1" width="1" height="4" fill="black" />
    <rect x="14" y="6" width="1" height="4" fill="black" />
    <rect x="4" y="4" width="3" height="3" fill="#79EED9" />
    <rect x="1" y="1" width="7" height="1" fill="#79EED9" />
    <rect x="9" y="1" width="5" height="1" fill="#EBEBEB" />
  </svg>
);

export default Ticket;
