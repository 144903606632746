"use client";

import ActivityList from "./ActivityList";
import ActivityLogHeader from "./ActivityLogHeader";
import ActivityTabs from "./ActivityTabs";
import useStore from "../../lib/store";

const ActivityLog = () => {
  const activeActivityTab = useStore(state => state.activeActivityTab);
  const setActiveActivityTab = useStore(state => state.setActiveActivityTab);

  return (
    <section className="flex flex-col pt-6 px-2 bg-[url('/image/purple-background.png')] bg-top bg-fixed bg-[length:100%_auto] min-h-[630px] pb-[77px]">
      <ActivityLogHeader />
      <ActivityTabs activeActivityTab={activeActivityTab} setActiveActivityTab={setActiveActivityTab} />
      <ActivityList activeActivityTab={activeActivityTab} />
    </section>
  );
};

export default ActivityLog;
