export const copyToClipboard = (text: string) => {
    try {
        navigator.clipboard.writeText(text);
    } catch (error) {
        console.error("Error copying to clipboard:", error);
    }
};

export const isTelegramLink = (url: string) => {
  try {
    const parsed = new URL(url);
    return parsed.hostname === "t.me";
  } catch (error) {
    console.error("Error parsing URL:", error);
    return false;
  }
};

export function openMiniappLink(url: string) {
  if (typeof window !== "undefined" && window.Telegram?.WebApp) {
    if (isTelegramLink(url)) {
      window.Telegram.WebApp.openTelegramLink(url);
    } else {
      window.Telegram.WebApp.openLink(url);
    }
  } else {
    window.open(url, "_blank");
  }
}
