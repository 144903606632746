import ReactGA from "react-ga4";

export interface Tracker {
  id: string;
  name: string;
}

export interface EventOption {
  event_category: string;
  event_label?: string;
  [key: string]: unknown;
}

export const resetGA = () => {
  ReactGA.reset();
};

export const initializeGA = () => {
  const options = {
    trackingId: process.env.NEXT_PUBLIC_GA4_TAG || "",
    gaOptions: { name: "miniapp" },
    gtagOptions: { send_page_view: false },
  };

  ReactGA.initialize([options]);
};

export const initializeGAByTrackers = (trackers: Tracker[]) => {
  resetGA();
  const initParams = trackers.map((tracker) => ({
    trackingId: tracker.id,
    gaOptions: { name: tracker.name },
    gtagOptions: { send_page_view: false },
  }));

  ReactGA.initialize(initParams);
};

export const createGaTracker = async (tracker: Tracker) => {
  ReactGA.ga("create", {
    trackingId: tracker.id,
    cookieDomain: "auto",
    name: tracker.name,
  });
};

export const setGaUser = (user_id: number) => {
  ReactGA.gtag("set", { user_id });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
export const sendPageViewByTracker = (pathname: string, tracker: Tracker) => {
  ReactGA.ga("send", {
    hitType: "pageview",
    page: pathname,
    send_to: tracker.id,
  });
};

export const sendPageView = (pathname = "/") => {
  ReactGA.send({ hitType: "pageview", page: pathname });
};

// https://developers.google.com/tag-platform/gtagjs/reference/parameters
// include send_to (ga4 tracker id) if sending to a specific tracker
export const sendGaEvent = (eventAction: string, eventOptions: EventOption) => {
  const { user_id, ...rest } = eventOptions;
  if (user_id) {
    ReactGA.gtag("set", { user_id });
  }
  ReactGA.event(eventAction, rest);
};
