const Bell = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
    <path d="M12.5195 28.4148H11.2728V29.664H12.5195V28.4148Z" fill="black" />
    <path d="M11.2728 28.4148H10.0261V29.664H11.2728V28.4148Z" fill="black" />
    <path d="M5.04436 28.4148H3.80017V29.664H5.04436V28.4148Z" fill="black" />
    <path d="M10.026 28.4148H8.78186V29.664H10.026V28.4148Z" fill="black" />
    <path d="M8.78191 28.4148H7.53522V29.664H8.78191V28.4148Z" fill="black" />
    <path d="M7.5352 28.4148H6.29102V29.664H7.5352V28.4148Z" fill="black" />
    <path d="M6.29106 28.4148H5.04437V29.664H6.29106V28.4148Z" fill="black" />
    <path d="M31.1998 28.4148H29.9556V29.664H31.1998V28.4148Z" fill="black" />
    <path d="M29.9557 28.4148H28.709V29.664H29.9557V28.4148Z" fill="black" />
    <path d="M28.709 28.4148H27.4648V29.664H28.709V28.4148Z" fill="black" />
    <path d="M27.4648 28.4148H26.2181V29.664H27.4648V28.4148Z" fill="black" />
    <path d="M26.2181 28.4148H24.9739V29.664H26.2181V28.4148Z" fill="black" />
    <path d="M24.9739 28.4148H23.7272V29.664H24.9739V28.4148Z" fill="black" />
    <path d="M23.7272 28.4148H22.483V29.664H23.7272V28.4148Z" fill="black" />
    <path d="M22.483 29.6641H21.2363V30.9108H22.483V29.6641Z" fill="black" />
    <path d="M22.483 28.4148H21.2363V29.664H22.483V28.4148Z" fill="black" />
    <path d="M21.2364 30.9106H19.9922V32.1598H21.2364V30.9106Z" fill="black" />
    <path d="M19.9922 32.1599H18.7455V33.4066H19.9922V32.1599Z" fill="black" />
    <path d="M18.7454 33.4065H17.5012V34.6557H18.7454V33.4065Z" fill="black" />
    <path d="M17.5012 33.4065H16.2545V34.6557H17.5012V33.4065Z" fill="black" />
    <path d="M16.2546 32.1599H15.0104V33.4066H16.2546V32.1599Z" fill="black" />
    <path d="M15.0104 30.9106H13.7637V32.1598H15.0104V30.9106Z" fill="black" />
    <path d="M13.7637 28.4148H12.5195V29.664H13.7637V28.4148Z" fill="black" />
    <path d="M13.7637 29.6641H12.5195V30.9108H13.7637V29.6641Z" fill="black" />
    <path d="M16.2546 0.960083H15.0104V2.20677H16.2546V0.960083Z" fill="black" />
    <path d="M15.0104 2.20679H13.7637V3.45598H15.0104V2.20679Z" fill="black" />
    <path d="M15.0104 3.45593H13.7637V4.70262H15.0104V3.45593Z" fill="black" />
    <path d="M13.7637 3.45593H12.5195V4.70262H13.7637V3.45593Z" fill="black" />
    <path d="M12.5195 4.70251H11.2728V5.95171H12.5195V4.70251Z" fill="black" />
    <path d="M11.2728 5.9519H10.0261V7.1986H11.2728V5.9519Z" fill="black" />
    <path d="M10.026 7.19849H8.78186V8.44768H10.026V7.19849Z" fill="black" />
    <path d="M8.78191 8.44775H7.53522V9.69445H8.78191V8.44775Z" fill="black" />
    <path d="M7.5352 13.4395H6.29102V14.6861H7.5352V13.4395Z" fill="black" />
    <path d="M7.5352 19.678H6.29102V20.9272H7.5352V19.678Z" fill="black" />
    <path d="M7.5352 12.1904H6.29102V13.4396H7.5352V12.1904Z" fill="black" />
    <path d="M7.5352 18.4312H6.29102V19.6778H7.5352V18.4312Z" fill="black" />
    <path d="M7.5352 20.9272H6.29102V22.1739H7.5352V20.9272Z" fill="black" />
    <path d="M7.5352 9.69434H6.29102V10.9435H7.5352V9.69434Z" fill="black" />
    <path d="M7.5352 14.686H6.29102V15.9352H7.5352V14.686Z" fill="black" />
    <path d="M7.5352 22.1738H6.29102V23.423H7.5352V22.1738Z" fill="black" />
    <path d="M7.5352 10.9436H6.29102V12.1903H7.5352V10.9436Z" fill="black" />
    <path d="M7.5352 15.9353H6.29102V17.182H7.5352V15.9353Z" fill="black" />
    <path d="M7.5352 17.1821H6.29102V18.4313H7.5352V17.1821Z" fill="black" />
    <path d="M6.29106 23.4229H5.04437V24.6695H6.29106V23.4229Z" fill="black" />
    <path d="M5.04436 24.6697H3.80017V25.9189H5.04436V24.6697Z" fill="black" />
    <path d="M3.80022 27.1655H2.55353V28.4147H3.80022V27.1655Z" fill="black" />
    <path d="M3.80022 25.9189H2.55353V27.1656H3.80022V25.9189Z" fill="black" />
    <path d="M32.4465 27.1655H31.1998V28.4147H32.4465V27.1655Z" fill="black" />
    <path d="M32.4465 25.9189H31.1998V27.1656H32.4465V25.9189Z" fill="black" />
    <path d="M31.1998 24.6697H29.9556V25.9189H31.1998V24.6697Z" fill="black" />
    <path d="M29.9557 23.4229H28.709V24.6695H29.9557V23.4229Z" fill="black" />
    <path d="M28.709 22.1738H27.4648V23.423H28.709V22.1738Z" fill="black" />
    <path d="M28.709 20.9272H27.4648V22.1739H28.709V20.9272Z" fill="black" />
    <path d="M28.709 19.678H27.4648V20.9272H28.709V19.678Z" fill="black" />
    <path d="M28.709 18.4312H27.4648V19.6778H28.709V18.4312Z" fill="black" />
    <path d="M28.709 17.1821H27.4648V18.4313H28.709V17.1821Z" fill="black" />
    <path d="M28.709 15.9353H27.4648V17.182H28.709V15.9353Z" fill="black" />
    <path d="M28.709 14.686H27.4648V15.9352H28.709V14.686Z" fill="black" />
    <path d="M28.709 13.4395H27.4648V14.6861H28.709V13.4395Z" fill="black" />
    <path d="M28.709 12.1904H27.4648V13.4396H28.709V12.1904Z" fill="black" />
    <path d="M28.709 10.9436H27.4648V12.1903H28.709V10.9436Z" fill="black" />
    <path d="M28.709 9.69434H27.4648V10.9435H28.709V9.69434Z" fill="black" />
    <path d="M27.4648 8.44775H26.2181V9.69445H27.4648V8.44775Z" fill="black" />
    <path d="M26.2181 7.19849H24.9739V8.44768H26.2181V7.19849Z" fill="black" />
    <path d="M24.9739 5.9519H23.7272V7.1986H24.9739V5.9519Z" fill="black" />
    <path d="M23.7272 4.70251H22.483V5.95171H23.7272V4.70251Z" fill="black" />
    <path d="M22.483 3.45593H21.2363V4.70262H22.483V3.45593Z" fill="black" />
    <path d="M21.2364 3.45593H19.9922V4.70262H21.2364V3.45593Z" fill="black" />
    <path d="M21.2364 2.20679H19.9922V3.45598H21.2364V2.20679Z" fill="black" />
    <path d="M19.9922 0.960083H18.7455V2.20677H19.9922V0.960083Z" fill="black" />
    <path d="M16.2546 28.4148H15.0104V29.664H16.2546V28.4148Z" fill="#F2A122" />
    <path d="M15.0104 28.4148H13.7637V29.664H15.0104V28.4148Z" fill="#F2A122" />
    <path d="M19.9922 28.4148H18.7455V29.664H19.9922V28.4148Z" fill="#F2A122" />
    <path d="M21.2364 28.4148H19.9922V29.664H21.2364V28.4148Z" fill="#F2A122" />
    <path d="M18.7454 28.4148H17.5012V29.664H18.7454V28.4148Z" fill="#F2A122" />
    <path d="M17.5012 28.4148H16.2545V29.664H17.5012V28.4148Z" fill="#F2A122" />
    <path d="M31.1998 27.1655H29.9556V28.4147H31.1998V27.1655Z" fill="#FFDE17" />
    <path d="M31.1998 25.9189H29.9556V27.1656H31.1998V25.9189Z" fill="#FFDE17" />
    <path d="M29.9557 27.1655H28.709V28.4147H29.9557V27.1655Z" fill="#FFDE17" />
    <path d="M29.9557 25.9189H28.709V27.1656H29.9557V25.9189Z" fill="#FFDE17" />
    <path d="M29.9557 24.6697H28.709V25.9189H29.9557V24.6697Z" fill="#FFDE17" />
    <path d="M28.709 27.1655H27.4648V28.4147H28.709V27.1655Z" fill="#FFDE17" />
    <path d="M28.709 25.9189H27.4648V27.1656H28.709V25.9189Z" fill="#FFF267" />
    <path d="M28.709 24.6697H27.4648V25.9189H28.709V24.6697Z" fill="#FFDE17" />
    <path d="M28.709 23.4229H27.4648V24.6695H28.709V23.4229Z" fill="#FFDE17" />
    <path d="M27.4648 27.1655H26.2181V28.4147H27.4648V27.1655Z" fill="#FFDE17" />
    <path d="M27.4648 25.9189H26.2181V27.1656H27.4648V25.9189Z" fill="#FFF267" />
    <path d="M27.4648 24.6697H26.2181V25.9189H27.4648V24.6697Z" fill="#FFF267" />
    <path d="M27.4648 23.4229H26.2181V24.6695H27.4648V23.4229Z" fill="#FFDE17" />
    <path d="M27.4648 22.1738H26.2181V23.423H27.4648V22.1738Z" fill="#FFDE17" />
    <path d="M27.4648 20.9272H26.2181V22.1739H27.4648V20.9272Z" fill="#FFDE17" />
    <path d="M27.4648 19.678H26.2181V20.9272H27.4648V19.678Z" fill="#FFDE17" />
    <path d="M27.4648 18.4312H26.2181V19.6778H27.4648V18.4312Z" fill="#FFDE17" />
    <path d="M27.4648 17.1821H26.2181V18.4313H27.4648V17.1821Z" fill="#FFDE17" />
    <path d="M27.4648 15.9353H26.2181V17.182H27.4648V15.9353Z" fill="#FFDE17" />
    <path d="M27.4648 14.686H26.2181V15.9352H27.4648V14.686Z" fill="#FFDE17" />
    <path d="M27.4648 13.4395H26.2181V14.6861H27.4648V13.4395Z" fill="#FFDE17" />
    <path d="M27.4648 12.1904H26.2181V13.4396H27.4648V12.1904Z" fill="#FFDE17" />
    <path d="M27.4648 10.9436H26.2181V12.1903H27.4648V10.9436Z" fill="#FFDE17" />
    <path d="M27.4648 9.69434H26.2181V10.9435H27.4648V9.69434Z" fill="#FFDE17" />
    <path d="M26.2181 27.1655H24.9739V28.4147H26.2181V27.1655Z" fill="#FFDE17" />
    <path d="M26.2181 25.9189H24.9739V27.1656H26.2181V25.9189Z" fill="#FFF267" />
    <path d="M26.2181 24.6697H24.9739V25.9189H26.2181V24.6697Z" fill="#FFF267" />
    <path d="M26.2181 23.4229H24.9739V24.6695H26.2181V23.4229Z" fill="#FFDE17" />
    <path d="M26.2181 22.1738H24.9739V23.423H26.2181V22.1738Z" fill="#FFDE17" />
    <path d="M26.2181 20.9272H24.9739V22.1739H26.2181V20.9272Z" fill="#FFDE17" />
    <path d="M26.2181 19.678H24.9739V20.9272H26.2181V19.678Z" fill="#FFDE17" />
    <path d="M26.2181 18.4312H24.9739V19.6778H26.2181V18.4312Z" fill="#FFDE17" />
    <path d="M26.2181 17.1821H24.9739V18.4313H26.2181V17.1821Z" fill="#FFDE17" />
    <path d="M26.2181 15.9353H24.9739V17.182H26.2181V15.9353Z" fill="#FFDE17" />
    <path d="M26.2181 14.686H24.9739V15.9352H26.2181V14.686Z" fill="#FFDE17" />
    <path d="M26.2181 13.4395H24.9739V14.6861H26.2181V13.4395Z" fill="#FFDE17" />
    <path d="M26.2181 12.1904H24.9739V13.4396H26.2181V12.1904Z" fill="#FFDE17" />
    <path d="M26.2181 10.9436H24.9739V12.1903H26.2181V10.9436Z" fill="#FFDE17" />
    <path d="M26.2181 9.69434H24.9739V10.9435H26.2181V9.69434Z" fill="#FFDE17" />
    <path d="M26.2181 8.44775H24.9739V9.69445H26.2181V8.44775Z" fill="#FFDE17" />
    <path d="M24.9739 27.1655H23.7272V28.4147H24.9739V27.1655Z" fill="#FFDE17" />
    <path d="M24.9739 25.9189H23.7272V27.1656H24.9739V25.9189Z" fill="#FFF267" />
    <path d="M24.9739 24.6697H23.7272V25.9189H24.9739V24.6697Z" fill="#FFF267" />
    <path d="M24.9739 23.4229H23.7272V24.6695H24.9739V23.4229Z" fill="#FFDE17" />
    <path d="M24.9739 22.1738H23.7272V23.423H24.9739V22.1738Z" fill="#FFF267" />
    <path d="M24.9739 20.9272H23.7272V22.1739H24.9739V20.9272Z" fill="#FFF267" />
    <path d="M24.9739 19.678H23.7272V20.9272H24.9739V19.678Z" fill="#FFF267" />
    <path d="M24.9739 18.4312H23.7272V19.6778H24.9739V18.4312Z" fill="#FFF267" />
    <path d="M24.9739 17.1821H23.7272V18.4313H24.9739V17.1821Z" fill="#FFF267" />
    <path d="M24.9739 15.9353H23.7272V17.182H24.9739V15.9353Z" fill="#FFF267" />
    <path d="M24.9739 14.686H23.7272V15.9352H24.9739V14.686Z" fill="#FFF267" />
    <path d="M24.9739 13.4395H23.7272V14.6861H24.9739V13.4395Z" fill="#FFF267" />
    <path d="M24.9739 12.1904H23.7272V13.4396H24.9739V12.1904Z" fill="#FFF267" />
    <path d="M24.9739 10.9436H23.7272V12.1903H24.9739V10.9436Z" fill="#FFF267" />
    <path d="M24.9739 9.69434H23.7272V10.9435H24.9739V9.69434Z" fill="#FFF267" />
    <path d="M24.9739 8.44775H23.7272V9.69445H24.9739V8.44775Z" fill="#FFDE17" />
    <path d="M24.9739 7.19849H23.7272V8.44768H24.9739V7.19849Z" fill="#FFDE17" />
    <path d="M23.7272 27.1655H22.483V28.4147H23.7272V27.1655Z" fill="#FFDE17" />
    <path d="M23.7272 25.9189H22.483V27.1656H23.7272V25.9189Z" fill="#FFF267" />
    <path d="M23.7272 24.6697H22.483V25.9189H23.7272V24.6697Z" fill="#FFF267" />
    <path d="M23.7272 23.4229H22.483V24.6695H23.7272V23.4229Z" fill="#FFDE17" />
    <path d="M23.7272 22.1738H22.483V23.423H23.7272V22.1738Z" fill="#FFF267" />
    <path d="M23.7272 20.9272H22.483V22.1739H23.7272V20.9272Z" fill="#FFF267" />
    <path d="M23.7272 19.678H22.483V20.9272H23.7272V19.678Z" fill="#FFF267" />
    <path d="M23.7272 18.4312H22.483V19.6778H23.7272V18.4312Z" fill="#FFF267" />
    <path d="M23.7272 17.1821H22.483V18.4313H23.7272V17.1821Z" fill="#FFF267" />
    <path d="M23.7272 15.9353H22.483V17.182H23.7272V15.9353Z" fill="#FFF267" />
    <path d="M23.7272 14.686H22.483V15.9352H23.7272V14.686Z" fill="#FFF267" />
    <path d="M23.7272 13.4395H22.483V14.6861H23.7272V13.4395Z" fill="#FFF267" />
    <path d="M23.7272 12.1904H22.483V13.4396H23.7272V12.1904Z" fill="#FFF267" />
    <path d="M23.7272 10.9436H22.483V12.1903H23.7272V10.9436Z" fill="#FFF267" />
    <path d="M23.7272 9.69434H22.483V10.9435H23.7272V9.69434Z" fill="#FFF267" />
    <path d="M23.7272 8.44775H22.483V9.69445H23.7272V8.44775Z" fill="#FFF267" />
    <path d="M23.7272 7.19849H22.483V8.44768H23.7272V7.19849Z" fill="#FFDE17" />
    <path d="M23.7272 5.9519H22.483V7.1986H23.7272V5.9519Z" fill="#FFDE17" />
    <path d="M22.483 27.1655H21.2363V28.4147H22.483V27.1655Z" fill="#FFDE17" />
    <path d="M22.483 25.9189H21.2363V27.1656H22.483V25.9189Z" fill="#FFDE17" />
    <path d="M22.483 24.6697H21.2363V25.9189H22.483V24.6697Z" fill="#FFF267" />
    <path d="M22.483 23.4229H21.2363V24.6695H22.483V23.4229Z" fill="#FFDE17" />
    <path d="M22.483 22.1738H21.2363V23.423H22.483V22.1738Z" fill="#FFF267" />
    <path d="M22.483 20.9272H21.2363V22.1739H22.483V20.9272Z" fill="#FFF267" />
    <path d="M22.483 19.678H21.2363V20.9272H22.483V19.678Z" fill="#FFF267" />
    <path d="M22.483 18.4312H21.2363V19.6778H22.483V18.4312Z" fill="#FFF267" />
    <path d="M22.483 17.1821H21.2363V18.4313H22.483V17.1821Z" fill="#FFF267" />
    <path d="M22.483 15.9353H21.2363V17.182H22.483V15.9353Z" fill="#FFF267" />
    <path d="M22.483 14.686H21.2363V15.9352H22.483V14.686Z" fill="#FFF267" />
    <path d="M22.483 13.4395H21.2363V14.6861H22.483V13.4395Z" fill="#FFF267" />
    <path d="M22.483 12.1904H21.2363V13.4396H22.483V12.1904Z" fill="#FFF267" />
    <path d="M22.483 10.9436H21.2363V12.1903H22.483V10.9436Z" fill="#FFF267" />
    <path d="M22.483 9.69434H21.2363V10.9435H22.483V9.69434Z" fill="#FFF267" />
    <path d="M22.483 8.44775H21.2363V9.69445H22.483V8.44775Z" fill="#FFF267" />
    <path d="M22.483 7.19849H21.2363V8.44768H22.483V7.19849Z" fill="#FFF267" />
    <path d="M22.483 5.9519H21.2363V7.1986H22.483V5.9519Z" fill="#FFDE17" />
    <path d="M22.483 4.70251H21.2363V5.95171H22.483V4.70251Z" fill="#FFDE17" />
    <path d="M21.2364 29.6641H19.9922V30.9108H21.2364V29.6641Z" fill="#F2A122" />
    <path d="M21.2364 27.1655H19.9922V28.4147H21.2364V27.1655Z" fill="#FFDE17" />
    <path d="M21.2364 25.9189H19.9922V27.1656H21.2364V25.9189Z" fill="#FFDE17" />
    <path d="M21.2364 24.6697H19.9922V25.9189H21.2364V24.6697Z" fill="#FFDE17" />
    <path d="M21.2364 23.4229H19.9922V24.6695H21.2364V23.4229Z" fill="#FFDE17" />
    <path d="M21.2364 22.1738H19.9922V23.423H21.2364V22.1738Z" fill="#FFF267" />
    <path d="M21.2364 20.9272H19.9922V22.1739H21.2364V20.9272Z" fill="#FFDE17" />
    <path d="M21.2364 19.678H19.9922V20.9272H21.2364V19.678Z" fill="#FFDE17" />
    <path d="M21.2364 18.4312H19.9922V19.6778H21.2364V18.4312Z" fill="#FFDE17" />
    <path d="M21.2364 17.1821H19.9922V18.4313H21.2364V17.1821Z" fill="#FFDE17" />
    <path d="M21.2364 15.9353H19.9922V17.182H21.2364V15.9353Z" fill="#FFDE17" />
    <path d="M21.2364 14.686H19.9922V15.9352H21.2364V14.686Z" fill="#FFDE17" />
    <path d="M21.2364 13.4395H19.9922V14.6861H21.2364V13.4395Z" fill="#FFDE17" />
    <path d="M21.2364 12.1904H19.9922V13.4396H21.2364V12.1904Z" fill="#FFDE17" />
    <path d="M21.2364 10.9436H19.9922V12.1903H21.2364V10.9436Z" fill="#FFDE17" />
    <path d="M21.2364 9.69434H19.9922V10.9435H21.2364V9.69434Z" fill="#FFDE17" />
    <path d="M21.2364 8.44775H19.9922V9.69445H21.2364V8.44775Z" fill="#FFF267" />
    <path d="M21.2364 7.19849H19.9922V8.44768H21.2364V7.19849Z" fill="#FFF267" />
    <path d="M21.2364 5.9519H19.9922V7.1986H21.2364V5.9519Z" fill="#FFF267" />
    <path d="M21.2364 4.70251H19.9922V5.95171H21.2364V4.70251Z" fill="#FFDE17" />
    <path d="M19.9922 30.9106H18.7455V32.1598H19.9922V30.9106Z" fill="#FFDE17" />
    <path d="M19.9922 29.6641H18.7455V30.9108H19.9922V29.6641Z" fill="#FFDE17" />
    <path d="M19.9922 27.1655H18.7455V28.4147H19.9922V27.1655Z" fill="#FFDE17" />
    <path d="M19.9922 25.9189H18.7455V27.1656H19.9922V25.9189Z" fill="#FFDE17" />
    <path d="M19.9922 24.6697H18.7455V25.9189H19.9922V24.6697Z" fill="#FFDE17" />
    <path d="M19.9922 23.4229H18.7455V24.6695H19.9922V23.4229Z" fill="#FFDE17" />
    <path d="M19.9922 22.1738H18.7455V23.423H19.9922V22.1738Z" fill="#FFF267" />
    <path d="M19.9922 20.9272H18.7455V22.1739H19.9922V20.9272Z" fill="#FFDE17" />
    <path d="M19.9922 19.678H18.7455V20.9272H19.9922V19.678Z" fill="#FFDE17" />
    <path d="M19.9922 18.4312H18.7455V19.6778H19.9922V18.4312Z" fill="#FFDE17" />
    <path d="M19.9922 17.1821H18.7455V18.4313H19.9922V17.1821Z" fill="#FFDE17" />
    <path d="M19.9922 15.9353H18.7455V17.182H19.9922V15.9353Z" fill="#FFDE17" />
    <path d="M19.9922 14.686H18.7455V15.9352H19.9922V14.686Z" fill="#FFDE17" />
    <path d="M19.9922 13.4395H18.7455V14.6861H19.9922V13.4395Z" fill="#FFDE17" />
    <path d="M19.9922 12.1904H18.7455V13.4396H19.9922V12.1904Z" fill="#FFDE17" />
    <path d="M19.9922 10.9436H18.7455V12.1903H19.9922V10.9436Z" fill="#FFDE17" />
    <path d="M19.9922 9.69434H18.7455V10.9435H19.9922V9.69434Z" fill="#FFDE17" />
    <path d="M19.9922 8.44775H18.7455V9.69445H19.9922V8.44775Z" fill="#FFF267" />
    <path d="M19.9922 7.19849H18.7455V8.44768H19.9922V7.19849Z" fill="#FFDE17" />
    <path d="M19.9922 5.9519H18.7455V7.1986H19.9922V5.9519Z" fill="#FFF267" />
    <path d="M19.9922 4.70251H18.7455V5.95171H19.9922V4.70251Z" fill="#FFDE17" />
    <path d="M19.9922 3.45593H18.7455V4.70262H19.9922V3.45593Z" fill="#F2A122" />
    <path d="M19.9922 2.20679H18.7455V3.45598H19.9922V2.20679Z" fill="#FFDE17" />
    <path d="M18.7454 32.1599H17.5012V33.4066H18.7454V32.1599Z" fill="#FFDE17" />
    <path d="M18.7454 30.9106H17.5012V32.1598H18.7454V30.9106Z" fill="#FFDE17" />
    <path d="M18.7454 29.6641H17.5012V30.9108H18.7454V29.6641Z" fill="#FFDE17" />
    <path d="M18.7454 27.1655H17.5012V28.4147H18.7454V27.1655Z" fill="#FFDE17" />
    <path d="M18.7454 25.9189H17.5012V27.1656H18.7454V25.9189Z" fill="#FFDE17" />
    <path d="M18.7454 24.6697H17.5012V25.9189H18.7454V24.6697Z" fill="#FFDE17" />
    <path d="M18.7454 23.4229H17.5012V24.6695H18.7454V23.4229Z" fill="#FFDE17" />
    <path d="M18.7454 22.1738H17.5012V23.423H18.7454V22.1738Z" fill="#FFF267" />
    <path d="M18.7454 20.9272H17.5012V22.1739H18.7454V20.9272Z" fill="#FFDE17" />
    <path d="M18.7454 19.678H17.5012V20.9272H18.7454V19.678Z" fill="#FFDE17" />
    <path d="M18.7454 18.4312H17.5012V19.6778H18.7454V18.4312Z" fill="#FFDE17" />
    <path d="M18.7454 17.1821H17.5012V18.4313H18.7454V17.1821Z" fill="#FFDE17" />
    <path d="M18.7454 15.9353H17.5012V17.182H18.7454V15.9353Z" fill="#FFDE17" />
    <path d="M18.7454 14.686H17.5012V15.9352H18.7454V14.686Z" fill="#FFDE17" />
    <path d="M18.7454 13.4395H17.5012V14.6861H18.7454V13.4395Z" fill="#FFDE17" />
    <path d="M18.7454 12.1904H17.5012V13.4396H18.7454V12.1904Z" fill="#FFDE17" />
    <path d="M18.7454 10.9436H17.5012V12.1903H18.7454V10.9436Z" fill="#FFDE17" />
    <path d="M18.7454 9.69434H17.5012V10.9435H18.7454V9.69434Z" fill="#FFDE17" />
    <path d="M18.7454 8.44775H17.5012V9.69445H18.7454V8.44775Z" fill="#FFF267" />
    <path d="M18.7454 7.19849H17.5012V8.44768H18.7454V7.19849Z" fill="#FFDE17" />
    <path d="M18.7454 5.9519H17.5012V7.1986H18.7454V5.9519Z" fill="#FFDE17" />
    <path d="M18.7454 4.70251H17.5012V5.95171H18.7454V4.70251Z" fill="#FFDE17" />
    <path d="M18.7454 3.45593H17.5012V4.70262H18.7454V3.45593Z" fill="#F2A122" />
    <path d="M18.7454 2.20679H17.5012V3.45598H18.7454V2.20679Z" fill="#FFDE17" />
    <path d="M18.7454 0.960083H17.5012V2.20677H18.7454V0.960083Z" fill="black" />
    <path d="M17.5012 32.1599H16.2545V33.4066H17.5012V32.1599Z" fill="#FFDE17" />
    <path d="M17.5012 30.9106H16.2545V32.1598H17.5012V30.9106Z" fill="#FFDE17" />
    <path d="M17.5012 29.6641H16.2545V30.9108H17.5012V29.6641Z" fill="#FFDE17" />
    <path d="M17.5012 27.1655H16.2545V28.4147H17.5012V27.1655Z" fill="#FFDE17" />
    <path d="M17.5012 25.9189H16.2545V27.1656H17.5012V25.9189Z" fill="#FFDE17" />
    <path d="M17.5012 24.6697H16.2545V25.9189H17.5012V24.6697Z" fill="#FFDE17" />
    <path d="M17.5012 23.4229H16.2545V24.6695H17.5012V23.4229Z" fill="#FFDE17" />
    <path d="M17.5012 22.1738H16.2545V23.423H17.5012V22.1738Z" fill="#FFF267" />
    <path d="M17.5012 20.9272H16.2545V22.1739H17.5012V20.9272Z" fill="#FFDE17" />
    <path d="M17.5012 19.678H16.2545V20.9272H17.5012V19.678Z" fill="#FFDE17" />
    <path d="M17.5012 18.4312H16.2545V19.6778H17.5012V18.4312Z" fill="#FFDE17" />
    <path d="M17.5012 17.1821H16.2545V18.4313H17.5012V17.1821Z" fill="#FFDE17" />
    <path d="M17.5012 15.9353H16.2545V17.182H17.5012V15.9353Z" fill="#FFDE17" />
    <path d="M17.5012 14.686H16.2545V15.9352H17.5012V14.686Z" fill="#FFDE17" />
    <path d="M17.5012 13.4395H16.2545V14.6861H17.5012V13.4395Z" fill="#FFDE17" />
    <path d="M17.5012 12.1904H16.2545V13.4396H17.5012V12.1904Z" fill="#FFDE17" />
    <path d="M17.5012 10.9436H16.2545V12.1903H17.5012V10.9436Z" fill="#FFDE17" />
    <path d="M17.5012 9.69434H16.2545V10.9435H17.5012V9.69434Z" fill="#FFDE17" />
    <path d="M17.5012 8.44775H16.2545V9.69445H17.5012V8.44775Z" fill="#FFF267" />
    <path d="M17.5012 7.19849H16.2545V8.44768H17.5012V7.19849Z" fill="#FFDE17" />
    <path d="M17.5012 5.9519H16.2545V7.1986H17.5012V5.9519Z" fill="#FFDE17" />
    <path d="M17.5012 4.70251H16.2545V5.95171H17.5012V4.70251Z" fill="#FFDE17" />
    <path d="M17.5012 3.45593H16.2545V4.70262H17.5012V3.45593Z" fill="#F2A122" />
    <path d="M17.5012 2.20679H16.2545V3.45598H17.5012V2.20679Z" fill="#FFDE17" />
    <path d="M17.5012 0.960083H16.2545V2.20677H17.5012V0.960083Z" fill="black" />
    <path d="M16.2546 30.9106H15.0104V32.1598H16.2546V30.9106Z" fill="#FFDE17" />
    <path d="M16.2546 29.6641H15.0104V30.9108H16.2546V29.6641Z" fill="#FFDE17" />
    <path d="M16.2546 27.1655H15.0104V28.4147H16.2546V27.1655Z" fill="#FFDE17" />
    <path d="M16.2546 25.9189H15.0104V27.1656H16.2546V25.9189Z" fill="#FFDE17" />
    <path d="M16.2546 24.6697H15.0104V25.9189H16.2546V24.6697Z" fill="#FFDE17" />
    <path d="M16.2546 23.4229H15.0104V24.6695H16.2546V23.4229Z" fill="#FFDE17" />
    <path d="M16.2546 22.1738H15.0104V23.423H16.2546V22.1738Z" fill="#FFF267" />
    <path d="M16.2546 20.9272H15.0104V22.1739H16.2546V20.9272Z" fill="#FFDE17" />
    <path d="M16.2546 19.678H15.0104V20.9272H16.2546V19.678Z" fill="#FFDE17" />
    <path d="M16.2546 18.4312H15.0104V19.6778H16.2546V18.4312Z" fill="#FFDE17" />
    <path d="M16.2546 17.1821H15.0104V18.4313H16.2546V17.1821Z" fill="#FFDE17" />
    <path d="M16.2546 15.9353H15.0104V17.182H16.2546V15.9353Z" fill="#FFDE17" />
    <path d="M16.2546 14.686H15.0104V15.9352H16.2546V14.686Z" fill="#FFDE17" />
    <path d="M16.2546 13.4395H15.0104V14.6861H16.2546V13.4395Z" fill="#FFDE17" />
    <path d="M16.2546 12.1904H15.0104V13.4396H16.2546V12.1904Z" fill="#FFDE17" />
    <path d="M16.2546 10.9436H15.0104V12.1903H16.2546V10.9436Z" fill="#FFDE17" />
    <path d="M16.2546 9.69434H15.0104V10.9435H16.2546V9.69434Z" fill="#FFDE17" />
    <path d="M16.2546 8.44775H15.0104V9.69445H16.2546V8.44775Z" fill="#FFF267" />
    <path d="M16.2546 7.19849H15.0104V8.44768H16.2546V7.19849Z" fill="#FFDE17" />
    <path d="M16.2546 5.9519H15.0104V7.1986H16.2546V5.9519Z" fill="#FFDE17" />
    <path d="M16.2546 4.70251H15.0104V5.95171H16.2546V4.70251Z" fill="#F2A122" />
    <path d="M16.2546 3.45593H15.0104V4.70262H16.2546V3.45593Z" fill="#F2A122" />
    <path d="M16.2546 2.20679H15.0104V3.45598H16.2546V2.20679Z" fill="#F2A122" />
    <path d="M15.0104 29.6641H13.7637V30.9108H15.0104V29.6641Z" fill="#F2A122" />
    <path d="M15.0104 27.1655H13.7637V28.4147H15.0104V27.1655Z" fill="#FFDE17" />
    <path d="M15.0104 25.9189H13.7637V27.1656H15.0104V25.9189Z" fill="#FFDE17" />
    <path d="M15.0104 24.6697H13.7637V25.9189H15.0104V24.6697Z" fill="#FFDE17" />
    <path d="M15.0104 23.4229H13.7637V24.6695H15.0104V23.4229Z" fill="#FFDE17" />
    <path d="M15.0104 22.1738H13.7637V23.423H15.0104V22.1738Z" fill="#FFF267" />
    <path d="M15.0104 20.9272H13.7637V22.1739H15.0104V20.9272Z" fill="#FFDE17" />
    <path d="M15.0104 19.678H13.7637V20.9272H15.0104V19.678Z" fill="#FFDE17" />
    <path d="M15.0104 18.4312H13.7637V19.6778H15.0104V18.4312Z" fill="#FFDE17" />
    <path d="M15.0104 17.1821H13.7637V18.4313H15.0104V17.1821Z" fill="#FFDE17" />
    <path d="M15.0104 15.9353H13.7637V17.182H15.0104V15.9353Z" fill="#FFDE17" />
    <path d="M15.0104 14.686H13.7637V15.9352H15.0104V14.686Z" fill="#FFDE17" />
    <path d="M15.0104 13.4395H13.7637V14.6861H15.0104V13.4395Z" fill="#FFDE17" />
    <path d="M15.0104 12.1904H13.7637V13.4396H15.0104V12.1904Z" fill="#FFDE17" />
    <path d="M15.0104 10.9436H13.7637V12.1903H15.0104V10.9436Z" fill="#FFDE17" />
    <path d="M15.0104 9.69434H13.7637V10.9435H15.0104V9.69434Z" fill="#FFDE17" />
    <path d="M15.0104 8.44775H13.7637V9.69445H15.0104V8.44775Z" fill="#FFDE17" />
    <path d="M15.0104 7.19849H13.7637V8.44768H15.0104V7.19849Z" fill="#FFDE17" />
    <path d="M15.0104 5.9519H13.7637V7.1986H15.0104V5.9519Z" fill="#F2A122" />
    <path d="M15.0104 4.70251H13.7637V5.95171H15.0104V4.70251Z" fill="#F2A122" />
    <path d="M13.7637 27.1655H12.5195V28.4147H13.7637V27.1655Z" fill="#FFDE17" />
    <path d="M13.7637 25.9189H12.5195V27.1656H13.7637V25.9189Z" fill="#FFDE17" />
    <path d="M13.7637 24.6697H12.5195V25.9189H13.7637V24.6697Z" fill="#FFDE17" />
    <path d="M13.7637 23.4229H12.5195V24.6695H13.7637V23.4229Z" fill="#FFDE17" />
    <path d="M13.7637 22.1738H12.5195V23.423H13.7637V22.1738Z" fill="#FFDE17" />
    <path d="M13.7637 20.9272H12.5195V22.1739H13.7637V20.9272Z" fill="#FFDE17" />
    <path d="M13.7637 19.678H12.5195V20.9272H13.7637V19.678Z" fill="#FFDE17" />
    <path d="M13.7637 18.4312H12.5195V19.6778H13.7637V18.4312Z" fill="#FFDE17" />
    <path d="M13.7637 17.1821H12.5195V18.4313H13.7637V17.1821Z" fill="#FFDE17" />
    <path d="M13.7637 15.9353H12.5195V17.182H13.7637V15.9353Z" fill="#FFDE17" />
    <path d="M13.7637 14.686H12.5195V15.9352H13.7637V14.686Z" fill="#FFDE17" />
    <path d="M13.7637 13.4395H12.5195V14.6861H13.7637V13.4395Z" fill="#FFDE17" />
    <path d="M13.7637 12.1904H12.5195V13.4396H13.7637V12.1904Z" fill="#FFDE17" />
    <path d="M13.7637 10.9436H12.5195V12.1903H13.7637V10.9436Z" fill="#FFDE17" />
    <path d="M13.7637 9.69434H12.5195V10.9435H13.7637V9.69434Z" fill="#FFDE17" />
    <path d="M13.7637 8.44775H12.5195V9.69445H13.7637V8.44775Z" fill="#FFDE17" />
    <path d="M13.7637 7.19849H12.5195V8.44768H13.7637V7.19849Z" fill="#F2A122" />
    <path d="M13.7637 5.9519H12.5195V7.1986H13.7637V5.9519Z" fill="#F2A122" />
    <path d="M13.7637 4.70251H12.5195V5.95171H13.7637V4.70251Z" fill="#FFDE17" />
    <path d="M12.5195 27.1655H11.2728V28.4147H12.5195V27.1655Z" fill="#FFDE17" />
    <path d="M12.5195 25.9189H11.2728V27.1656H12.5195V25.9189Z" fill="#FFDE17" />
    <path d="M12.5195 24.6697H11.2728V25.9189H12.5195V24.6697Z" fill="#FFDE17" />
    <path d="M12.5195 23.4229H11.2728V24.6695H12.5195V23.4229Z" fill="#FFDE17" />
    <path d="M12.5195 22.1738H11.2728V23.423H12.5195V22.1738Z" fill="#FFDE17" />
    <path d="M12.5195 20.9272H11.2728V22.1739H12.5195V20.9272Z" fill="#FFDE17" />
    <path d="M12.5195 19.678H11.2728V20.9272H12.5195V19.678Z" fill="#FFDE17" />
    <path d="M12.5195 18.4312H11.2728V19.6778H12.5195V18.4312Z" fill="#FFDE17" />
    <path d="M12.5195 17.1821H11.2728V18.4313H12.5195V17.1821Z" fill="#FFDE17" />
    <path d="M12.5195 15.9353H11.2728V17.182H12.5195V15.9353Z" fill="#FFDE17" />
    <path d="M12.5195 14.686H11.2728V15.9352H12.5195V14.686Z" fill="#FFDE17" />
    <path d="M12.5195 13.4395H11.2728V14.6861H12.5195V13.4395Z" fill="#FFDE17" />
    <path d="M12.5195 12.1904H11.2728V13.4396H12.5195V12.1904Z" fill="#FFDE17" />
    <path d="M12.5195 10.9436H11.2728V12.1903H12.5195V10.9436Z" fill="#FFDE17" />
    <path d="M12.5195 9.69434H11.2728V10.9435H12.5195V9.69434Z" fill="#FFDE17" />
    <path d="M12.5195 8.44775H11.2728V9.69445H12.5195V8.44775Z" fill="#F2A122" />
    <path d="M12.5195 7.19849H11.2728V8.44768H12.5195V7.19849Z" fill="#F2A122" />
    <path d="M12.5195 5.9519H11.2728V7.1986H12.5195V5.9519Z" fill="#FFDE17" />
    <path d="M11.2728 27.1655H10.0261V28.4147H11.2728V27.1655Z" fill="#FFDE17" />
    <path d="M11.2728 25.9189H10.0261V27.1656H11.2728V25.9189Z" fill="#FFDE17" />
    <path d="M11.2728 24.6697H10.0261V25.9189H11.2728V24.6697Z" fill="#FFDE17" />
    <path d="M11.2728 23.4229H10.0261V24.6695H11.2728V23.4229Z" fill="#FFDE17" />
    <path d="M11.2728 22.1738H10.0261V23.423H11.2728V22.1738Z" fill="#F2A122" />
    <path d="M11.2728 20.9272H10.0261V22.1739H11.2728V20.9272Z" fill="#F2A122" />
    <path d="M11.2728 19.678H10.0261V20.9272H11.2728V19.678Z" fill="#F2A122" />
    <path d="M11.2728 18.4312H10.0261V19.6778H11.2728V18.4312Z" fill="#F2A122" />
    <path d="M11.2728 17.1821H10.0261V18.4313H11.2728V17.1821Z" fill="#F2A122" />
    <path d="M11.2728 15.9353H10.0261V17.182H11.2728V15.9353Z" fill="#F2A122" />
    <path d="M11.2728 14.686H10.0261V15.9352H11.2728V14.686Z" fill="#F2A122" />
    <path d="M11.2728 13.4395H10.0261V14.6861H11.2728V13.4395Z" fill="#F2A122" />
    <path d="M11.2728 12.1904H10.0261V13.4396H11.2728V12.1904Z" fill="#F2A122" />
    <path d="M11.2728 10.9436H10.0261V12.1903H11.2728V10.9436Z" fill="#F2A122" />
    <path d="M11.2728 9.69434H10.0261V10.9435H11.2728V9.69434Z" fill="#F2A122" />
    <path d="M11.2728 8.44775H10.0261V9.69445H11.2728V8.44775Z" fill="#F2A122" />
    <path d="M11.2728 7.19849H10.0261V8.44768H11.2728V7.19849Z" fill="#FFDE17" />
    <path d="M10.026 27.1655H8.78186V28.4147H10.026V27.1655Z" fill="#FFDE17" />
    <path d="M10.026 25.9189H8.78186V27.1656H10.026V25.9189Z" fill="#FFDE17" />
    <path d="M10.026 24.6697H8.78186V25.9189H10.026V24.6697Z" fill="#FFDE17" />
    <path d="M10.026 23.4229H8.78186V24.6695H10.026V23.4229Z" fill="#F2A122" />
    <path d="M10.026 22.1738H8.78186V23.423H10.026V22.1738Z" fill="#F2A122" />
    <path d="M10.026 20.9272H8.78186V22.1739H10.026V20.9272Z" fill="#F2A122" />
    <path d="M10.026 19.678H8.78186V20.9272H10.026V19.678Z" fill="#F2A122" />
    <path d="M10.026 18.4312H8.78186V19.6778H10.026V18.4312Z" fill="#F2A122" />
    <path d="M10.026 17.1821H8.78186V18.4313H10.026V17.1821Z" fill="#F2A122" />
    <path d="M10.026 15.9353H8.78186V17.182H10.026V15.9353Z" fill="#F2A122" />
    <path d="M10.026 14.686H8.78186V15.9352H10.026V14.686Z" fill="#F2A122" />
    <path d="M10.026 13.4395H8.78186V14.6861H10.026V13.4395Z" fill="#F2A122" />
    <path d="M10.026 12.1904H8.78186V13.4396H10.026V12.1904Z" fill="#F2A122" />
    <path d="M10.026 10.9436H8.78186V12.1903H10.026V10.9436Z" fill="#F2A122" />
    <path d="M10.026 9.69434H8.78186V10.9435H10.026V9.69434Z" fill="#F2A122" />
    <path d="M10.026 8.44775H8.78186V9.69445H10.026V8.44775Z" fill="#FFDE17" />
    <path d="M8.78191 27.1655H7.53522V28.4147H8.78191V27.1655Z" fill="#FFDE17" />
    <path d="M8.78191 25.9189H7.53522V27.1656H8.78191V25.9189Z" fill="#FFDE17" />
    <path d="M8.78191 24.6697H7.53522V25.9189H8.78191V24.6697Z" fill="#F2A122" />
    <path d="M8.78191 23.4229H7.53522V24.6695H8.78191V23.4229Z" fill="#F2A122" />
    <path d="M8.78191 22.1738H7.53522V23.423H8.78191V22.1738Z" fill="#FFDE17" />
    <path d="M8.78191 20.9272H7.53522V22.1739H8.78191V20.9272Z" fill="#FFDE17" />
    <path d="M8.78191 19.678H7.53522V20.9272H8.78191V19.678Z" fill="#FFDE17" />
    <path d="M8.78191 18.4312H7.53522V19.6778H8.78191V18.4312Z" fill="#FFDE17" />
    <path d="M8.78191 17.1821H7.53522V18.4313H8.78191V17.1821Z" fill="#FFDE17" />
    <path d="M8.78191 15.9353H7.53522V17.182H8.78191V15.9353Z" fill="#FFDE17" />
    <path d="M8.78191 14.686H7.53522V15.9352H8.78191V14.686Z" fill="#FFDE17" />
    <path d="M8.78191 13.4395H7.53522V14.6861H8.78191V13.4395Z" fill="#FFDE17" />
    <path d="M8.78191 12.1904H7.53522V13.4396H8.78191V12.1904Z" fill="#FFDE17" />
    <path d="M8.78191 10.9436H7.53522V12.1903H8.78191V10.9436Z" fill="#FFDE17" />
    <path d="M8.78191 9.69434H7.53522V10.9435H8.78191V9.69434Z" fill="#FFDE17" />
    <path d="M7.5352 27.1655H6.29102V28.4147H7.5352V27.1655Z" fill="#FFDE17" />
    <path d="M7.5352 25.9189H6.29102V27.1656H7.5352V25.9189Z" fill="#F2A122" />
    <path d="M7.5352 24.6697H6.29102V25.9189H7.5352V24.6697Z" fill="#F2A122" />
    <path d="M7.5352 23.4229H6.29102V24.6695H7.5352V23.4229Z" fill="#FFDE17" />
    <path d="M6.29106 27.1655H5.04437V28.4147H6.29106V27.1655Z" fill="#FFDE17" />
    <path d="M6.29106 25.9189H5.04437V27.1656H6.29106V25.9189Z" fill="#F2A122" />
    <path d="M6.29106 24.6697H5.04437V25.9189H6.29106V24.6697Z" fill="#FFDE17" />
    <path d="M5.04436 27.1655H3.80017V28.4147H5.04436V27.1655Z" fill="#FFDE17" />
    <path d="M5.04436 25.9189H3.80017V27.1656H5.04436V25.9189Z" fill="#FFDE17" />
  </svg>
);

export default Bell;
