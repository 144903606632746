import { useState } from "react";
import useStore from "../../lib/store";
import Button from "../Form/Button";
import PopUpModal from "../PopUpModal";
import OptimizedImage from "../OptimizedImage";
import { postRequest } from "../../utils/requests";
import { NAVIGATION_TABS } from "../../types/home";
import { REWARD_TYPES } from "../../types/earn";

const ZeroTicketPopup = () => {
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const setShowZeroTicketModal = useStore(state => state.setShowZeroTicketModal);
  const setActiveTab = useStore(state => state.setActiveTab);
  const setActiveEarnTab = useStore(state => state.setActiveEarnTab);

  const handleConfirm = async () => {
    setIsBtnLoading(true);
    await postRequest("miniapp/user/ticket-status/acknowledge");
    setIsBtnLoading(false);
    setActiveTab(NAVIGATION_TABS.EARN);
    setActiveEarnTab(REWARD_TYPES.TICKETS);
    setShowZeroTicketModal(false);
  };

  const content = (
    <div className="flex flex-col items-center justify-center p-4 gap-[24px]">
      <OptimizedImage
        src="/image/earn-ticket.png"
        alt="Earn Ticket"
        width={200}
        height={200}
        className="h-auto mt-3"
      />
      <div className="text-white text-[24px] text-center leading-[26px] px-4">Get more tickets and jump back in the game!</div>
      <div className="flex items-center justify-center">
        <Button
          onClick={() => handleConfirm()}
          text="GET TICKETS"
          disabled={isBtnLoading}
        />
      </div>
    </div>
  );

  return (
    <PopUpModal
      content={content}
      bottomAccentColor="#0E0B3580"
      backgroundImage="/image/teal-background.png"
      closeModal={() => handleConfirm()}
    />
  );
};

export default ZeroTicketPopup;
