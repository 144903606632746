"use client";

import { useEffect, useState } from "react";
import Fire from "../icons/Fire";
import Tickets from "../icons/Tickets";
import useStore from "../../lib/store";

interface DailyRewardTimerProps {
  targetTimestamp: number // Unix timestamp in UTC milliseconds,
}

const DailyRewardTimer = ({ targetTimestamp }: DailyRewardTimerProps) => {
  const streak = useStore(state => state.streak);
  const [rewardDue, setRewardDue] = useState(false);
  const NEXT_PUBLIC_DAILY_REWARD_INTERVAL_IN_HOURS =
    process.env.NEXT_PUBLIC_DAILY_REWARD_INTERVAL_IN_HOURS || "24";
  const calculateTimeLeft = () => {
    const now = new Date().getTime(); // Local time in UTC milliseconds
    const difference = targetTimestamp - now;
    if (difference <= 0) return { hours: 0, minutes: 0, seconds: 0 };

    const hours = Math.floor(difference / (1000 * 60 * 60));
    let minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); 
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    if(hours === 0 && minutes > 0) {
      minutes += 1;// Round by 1 minute because we're not displaying seconds
    }

    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (rewardDue) setRewardDue(false);
      if (newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
        clearInterval(timer);
        setRewardDue(true);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTimestamp]);

  const calculateTimeLeftPercent = () => {
    const secondsInRewardInterval = parseInt(NEXT_PUBLIC_DAILY_REWARD_INTERVAL_IN_HOURS) * 3600;
    return ((timeLeft.hours * 3600 + timeLeft.minutes * 60) / secondsInRewardInterval) * 100;
  };

  const timeLeftDisplay = () => {
    if (timeLeft.hours > 0 || timeLeft.minutes > 0) {
      return `${timeLeft.hours}h ${timeLeft.minutes}m`;
    } 
    else if (timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds > 0) {
      return `${timeLeft.seconds}s`;
    }
    return "+Tickets";
  };

  // KAT update reward due display
  return (
    <section className="flex flex-col items-center justify-center">
      <div className="relative h-full ml-[-8px]">
        {/* Fire Icon */}
        <Fire className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />

        {/* Text Overlay */}
        <div className="relative flex flex-col items-center text-black justify-center mt-[5px]">
          <div className="text-lg font-bold">{streak}</div>
          <div className="text-[10px] mt-[-10px]">{streak === 1 ? "Day" : "Days"}</div>
        </div>
      </div>
      {/* Progress Box */}
      <div className="flex items-center justify-center z-10">
        <div className="flex flex-col items-center">
          <div className="w-[43px] h-[2px] bg-black" />
          <div className="h-[16px] flex items-center">
            <div className="w-[2px] h-full bg-black" />
            <div className="flex flex-col w-[2px] h-full">
              <div className="bg-black w-[2px] h-[2px]" />
              <div
                className="bg-darkTeal w-[2px] h-[12px]"
                style={{ background: rewardDue ? "#FF6A3C" : "#63c5b4" }}
              />
              <div className="bg-black w-[2px] h-[2px]" />
            </div>
            <div className="relative w-[41px] h-[16px]">
              {/* Background fill based on time left */}
              <div
                className="absolute top-0 left-0 h-full"
                style={{
                  width: `${calculateTimeLeftPercent()}%`,
                  background: rewardDue ? "#FF6A3C" : "#63c5b4"
                }} // Convert to percentage of NEXT_PUBLIC_DAILY_REWARD_INTERVAL_IN_HOURS
              />
              <div
                className="absolute top-0 right-0 h-full"
                style={{
                  width: `${100 - calculateTimeLeftPercent()}%`,
                  background: rewardDue ? "#FF6A3C" : "white"
                }}
              />

              {/* Time Left Display */}
              <div className="relative text-[10px] flex justify-center items-center w-full h-full" style={{ color: rewardDue ? "white" : "block" }}>
                {timeLeftDisplay()}
              </div>
            </div>
            <div className="flex flex-col w-[2px] h-full">
              <div className="bg-black w-[2px] h-[2px]" />
              <div
                className="bg-white w-[2px] h-[12px]"
                style={{ background: rewardDue ? "#FF6A3C" : "white" }}
              />
              <div className="bg-black w-[2px] h-[2px]" />
            </div>
            <div className="w-[2px] h-full bg-black" />
          </div>
          <div className="w-[43px] h-[2px] bg-black" />
        </div>
        <Tickets className="ml-[-8px] z-10" />
      </div>
    </section>
  );
};

export default DailyRewardTimer;
