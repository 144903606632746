import GatherIcon from "../icons/GatherIcon";
import GradientBox from "../icons/gradientBox";
import ShareIcon from "../icons/ShareIcon";
import OptimizedImage from "../OptimizedImage";
import ListNumber from "./ListNumber";

const InviteFriends = ({}) => {
  return (
    <div className="flex flex-col gap-4 text-lg py-4 items-center justify-center">
      <div className="relative inline-block h-[118px]">
        <GradientBox />
        <div className="absolute top-0 left-0 items-center justify-between h-full w-full flex px-4">
          <ListNumber numberToDisplay={1} />
          <div className="w-[132px] leading-none h-full flex justify-center items-center">
            Share your invitation link
          </div>
          <ShareIcon width={110} height={110} />
        </div>
      </div>
      <div className="relative inline-block h-[118px]">
        <GradientBox color1="#FF89B6" color2="#F47AC3" color3="#F161D2" color4="#D462D5" />
        <div className="absolute top-0 left-0 items-center justify-between h-full w-full flex px-4">
          <ListNumber numberToDisplay={2} />
          <div>
            <div className="w-[132px] leading-none h-full flex justify-center items-center">
            Your friends earn Gems from games, earn activities and referrals
            </div>
          </div>
          <OptimizedImage
        src="/image/Trophy.png"
        alt="Claim Reward"
        width={110}
        height={110}
      />
        </div>
      </div>
      <div className="relative inline-block h-[118px]">
        <GradientBox color1="#36F394" color2="#2FE1B7" color3="#32D6D6" color4="#34C5D6" />
        <div className="absolute top-0 left-0 items-center justify-between h-full w-full flex px-4">
          <ListNumber numberToDisplay={3} />
          <div className="w-[132px] leading-none h-full flex justify-center items-center">
            You earn 5 Gems for every 100 your friend earns!
          </div>
          <GatherIcon width={110} height={110} />
        </div>
      </div>
    </div>
  );
};

export default InviteFriends;
