"use client";

import { REWARD_TYPES } from "../../types/earn";
import Gem from "../icons/Gem";
import Ticket from "../icons/Ticket";
import { CustomTask } from "../../types/earn";
import Box from "../Box";
import Button from "../Form/Button";
import IconTimer from "../icons/IconTimer";
import { useState } from "react";
import { postRequest } from "../../utils/requests";
import { openMiniappLink } from "../../utils/utils";
import OptimizedImage from "../OptimizedImage";

interface EarnTaskProps {
  task: CustomTask;
}

const TASK_CLAIM_KEY_PREFIX = "task_claim";

const EarnTask = ({ task }: EarnTaskProps) => {
  const {
    id,
    name,
    link,
    reward_amount: rewardAmount,
    reward_type: rewardType,
    icon_url: iconUrl,
    action,
  } = task;
  const taskClaimKey = `${TASK_CLAIM_KEY_PREFIX}_${id}`;
  const isTaskClaimed = !!action?.rewarded_at;
  const [isTaskStarted, setIsTaskStarted] = useState(!isTaskClaimed && (!!action || !!localStorage.getItem(taskClaimKey)));
  const [isTaskStarting, setIsTaskStarting] = useState(false);

  const getButtonContent = () => {
    if (isTaskClaimed) {
      return "Claimed";
    } else if (isTaskStarting) {
      return "Claiming";
    } else if (isTaskStarted) {
      return <IconTimer className="pb-1" fill="white" />;
    } else {
      return "Go";
    }
  };

  const isButtonDisabled = isTaskClaimed || isTaskStarting || isTaskStarted;

  const handleClaim = async () => {
    setIsTaskStarting(true);
    try {
      await postRequest("miniapp/start_task", {task_id: id});
      localStorage.setItem(taskClaimKey, "true");
      openMiniappLink(link);
      setIsTaskStarted(true);
      setIsTaskStarting(false);
    } catch (error) {
      console.error("Error starting task:", error);
      setIsTaskStarting(false);
    }
  };

  const getBoxContent = () => {
    return (
      <div className="flex py-[2px] px-3 flex gap-3 items-center bg-secondaryWhite">
        <div><OptimizedImage src={iconUrl} alt="Sweet Icon" width={32} height={32} className="rounded-md" /></div>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center">
            <div>{name}</div>
            <div className={`flex gap-1 items-center bg-${rewardType === REWARD_TYPES.GEMS ? "pink" : "teal"} h-[19px] p-2`}>
              <div>
                {rewardType === REWARD_TYPES.GEMS ? <Gem /> : <Ticket />}
              </div>
              <div>{rewardAmount}</div>
            </div>
          </div>
          <div className="w-[98px] flex justify-end items-center mr-1">
              <Button
                text={getButtonContent()}
                textSize="lg"
                width={88}
                height={36}
                className="text-nowrap"
                disabled={isButtonDisabled}
                onClick={handleClaim}
                datadogActionName={`Earn ${rewardType}- ${name}`}
              />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative inline-block justify-center mt-2">
      <Box width={362} bottomBorderColor="black" content={getBoxContent()}/>
    </div>
  );
};

export default EarnTask;
