const Fire = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="56"
    viewBox="0 0 35 56"
    fill="none"
    className={className}
  >
    <rect x="10.2114" y="51.9087" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="11.9135" y="53.6103" width="8.50964" height="1.70193" fill="#FF5E00" />
    <rect x="10.2114" y="42.1225" width="1.70193" height="9.78608" fill="#FFF6A2" />
    <rect x="8.5098" y="43.8247" width="1.70193" height="6.80771" fill="#FFF6A2" />
    <rect x="20.4228" y="42.1225" width="1.70193" height="9.78608" fill="#FFF6A2" />
    <rect x="18.7212" y="39.9949" width="1.70193" height="13.6154" fill="#FFF6A2" />
    <rect x="17.0195" y="41.6975" width="1.70193" height="11.9135" fill="#FFF6A2" />
    <rect x="15.3175" y="41.6975" width="1.70193" height="11.9135" fill="#FFF6A2" />
    <rect x="13.6159" y="41.6975" width="1.70193" height="11.9135" fill="#FFF6A2" />
    <rect x="11.9135" y="41.6975" width="1.70193" height="11.9135" fill="#FFF6A2" />
    <rect x="22.1256" y="43.8247" width="1.70193" height="8.08416" fill="#FFF6A2" />
    <rect x="22.1256" y="35.3152" width="1.70193" height="8.50964" fill="#FFF6A2" />
    <rect x="20.4228" y="33.6129" width="1.70193" height="8.50964" fill="#FFF6A2" />
    <rect x="18.7212" y="31.9111" width="1.70193" height="8.08416" fill="#FFF6A2" />
    <rect x="17.0195" y="33.6129" width="1.70193" height="8.08416" fill="#FFF6A2" />
    <rect x="15.3175" y="35.3152" width="1.70193" height="8.08416" fill="#FFF6A2" />
    <rect x="13.6159" y="37.0168" width="1.70193" height="8.08416" fill="#FFF6A2" />
    <rect x="11.9135" y="38.719" width="1.70193" height="5.10578" fill="#FFF6A2" />
    <rect x="10.2114" y="40.4209" width="1.70237" height="1.70237" fill="#FFF6A2" />
    <rect x="10.2114" y="33.6129" width="1.70193" height="6.80771" fill="#FFF6A2" />
    <rect x="8.5098" y="35.3152" width="1.70193" height="8.50964" fill="#FFF6A2" />
    <rect x="6.80817" y="37.0168" width="1.70193" height="13.6154" fill="#FFF6A2" />
    <rect x="5.10614" y="38.719" width="1.70193" height="8.93512" fill="#FFF6A2" />
    <rect x="3.40372" y="40.4209" width="1.70193" height="7.23319" fill="#FFF6A2" />
    <rect x="3.40372" y="29.7835" width="1.70193" height="10.637" fill="#FFBF00" />
    <rect x="5.10614" y="29.7835" width="1.70193" height="8.93512" fill="#FFBF00" />
    <rect x="6.80817" y="26.8055" width="1.70193" height="10.2116" fill="#FFBF00" />
    <rect x="8.5098" y="20.8484" width="1.70193" height="14.4664" fill="#FFBF00" />
    <rect x="10.2114" y="19.1465" width="1.70193" height="14.4664" fill="#FFBF00" />
    <rect x="11.9135" y="6.38211" width="1.70193" height="25.5289" fill="#FFBF00" />
    <rect x="13.6159" y="8.08374" width="1.70193" height="22.1251" fill="#FFBF00" />
    <rect x="15.3175" y="9.78595" width="1.70193" height="18.7212" fill="#FFBF00" />
    <rect x="17.0195" y="11.4878" width="1.70193" height="7.65867" fill="#FFBF00" />
    <rect x="18.7212" y="13.1896" width="1.70193" height="7.65867" fill="#FFBF00" />
    <rect x="20.4228" y="19.1465" width="1.70193" height="3.40386" fill="#FFBF00" />
    <rect x="22.1256" y="20.8484" width="1.70193" height="3.40386" fill="#FFBF00" />
    <rect x="23.8272" y="19.1465" width="1.70193" height="3.40386" fill="#FFBF00" />
    <rect x="25.5293" y="17.4445" width="1.70193" height="32.7621" fill="#FFBF00" />
    <rect x="27.2309" y="19.1465" width="1.70193" height="29.3582" fill="#FFBF00" />
    <rect x="28.9333" y="26.38" width="1.70193" height="20.4231" fill="#FFBF00" />
    <rect x="11.9135" y="31.9111" width="1.70193" height="6.80771" fill="#FFF6A2" />
    <rect x="13.6159" y="30.2093" width="1.70193" height="6.80771" fill="#FFF6A2" />
    <rect x="15.3175" y="28.5071" width="1.70193" height="6.80771" fill="#FFF6A2" />
    <rect x="17.0195" y="19.1465" width="1.70193" height="14.4664" fill="#FFF6A2" />
    <rect x="18.7212" y="20.8484" width="1.70193" height="11.0625" fill="#FFF6A2" />
    <rect x="20.4228" y="22.5506" width="1.70193" height="11.0625" fill="#FFF6A2" />
    <rect x="22.1256" y="24.2524" width="1.70193" height="11.0625" fill="#FFF6A2" />
    <rect x="23.8272" y="22.5506" width="1.70193" height="14.4664" fill="#FFF6A2" />
    <rect x="25.5293" y="26.38" width="1.70193" height="20.8486" fill="#FFF6A2" />
    <rect x="27.2309" y="28.0821" width="1.70193" height="17.4448" fill="#FFF6A2" />
    <rect x="23.8272" y="37.0168" width="1.70193" height="8.50964" fill="#FFF6A2" />
    <rect x="23.8272" y="45.5266" width="1.70193" height="6.38223" fill="#FFF6A2" />
    <rect x="8.5098" y="50.2068" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="6.80817" y="50.2068" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="5.10614" y="47.2288" width="1.70193" height="5.10578" fill="#FF5E00" />
    <rect x="3.40372" y="47.2288" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="1.70169" y="28.0821" width="1.70193" height="20.8486" fill="#FF5E00" />
    <rect x="6.10352e-05" y="31.4861" width="1.70193" height="15.7428" fill="#FF5E00" />
    <rect x="3.40372" y="26.38" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="5.10614" y="24.6782" width="1.70193" height="5.10578" fill="#FF5E00" />
    <rect x="6.80817" y="11.4878" width="1.70193" height="15.3173" fill="#FF5E00" />
    <rect x="8.5098" y="9.78595" width="1.70193" height="11.0625" fill="#FF5E00" />
    <rect x="10.2114" y="8.08374" width="1.70193" height="11.0625" fill="#FF5E00" />
    <rect x="10.2114" y="2.97806" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="11.9135" y="2.97806" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="13.6159" y="4.68066" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="15.3175" y="6.38211" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="17.0195" y="8.08374" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="18.7212" y="9.78595" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="20.4228" y="15.7427" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="22.1256" y="17.4445" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="23.8272" y="15.7427" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="25.5293" y="14.0408" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="27.2309" y="15.7427" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="28.9333" y="17.4445" width="1.70193" height="8.93512" fill="#FF5E00" />
    <rect x="30.6353" y="26.38" width="1.70193" height="20.4231" fill="#FF5E00" />
    <rect x="32.337" y="27.6562" width="1.70193" height="17.8702" fill="#FF5E00" />
    <rect
      width="1.70193"
      height="3.40386"
      transform="matrix(1 0 0 -1 28.9333 50.2068)"
      fill="#FF5E00"
    />
    <rect
      width="1.70193"
      height="3.40386"
      transform="matrix(1 0 0 -1 27.2309 51.9087)"
      fill="#FF5E00"
    />
    <rect
      width="1.70193"
      height="3.40386"
      transform="matrix(1 0 0 -1 25.5293 53.6103)"
      fill="#FF5E00"
    />
    <rect
      width="5.10578"
      height="3.40386"
      transform="matrix(1 0 0 -1 20.4228 55.3125)"
      fill="#FF5E00"
    />
    <rect x="8.5098" y="1.70184" width="1.70193" height="3.40386" fill="#FF5E00" />
    <rect x="6.80817" y="1.70184" width="1.70237" height="1.70237" fill="#FF5E00" />
    <rect x="5.10614" width="1.70237" height="1.70237" fill="#FF5E00" />
    <rect x="25.5293" y="9.78595" width="1.70237" height="1.70237" fill="#FF5E00" />
    <rect x="27.2309" y="8.08374" width="1.70237" height="1.70237" fill="#FF5E00" />
    <rect x="8.5098" width="1.70237" height="1.70237" fill="#FF5E00" />
  </svg>
);

export default Fire;
