"use client";

import UserBalance from "../UserBalance";

const AccountHeader = () => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex">
        <div className="flex gap-2 items-center h-[24px]">
          <h1 className="text-secondaryWhite text-2xl not-italic font-normal leading-normal bg-transparent px-2 h-full flex items-center">
            My Account
          </h1>
        </div>
      </div>
      <UserBalance />
    </div>
  );
};

export default AccountHeader;
