"use client";

import NavigationArrow from "../icons/NavigationArrow";
import { NAVIGATION_TABS } from "../../types/home";
import UserBalance from "../UserBalance";
import useStore from "../../lib/store";

const ActivityLogHeader = () => {
  const setActiveTab = useStore(state => state.setActiveTab);

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex">
        <div className="flex gap-2 items-center h-[24px]">
          <div className="flex bg-black h-full justify-center items-center">
            <button onClick={() => setActiveTab(NAVIGATION_TABS.ACCOUNT)}><NavigationArrow /></button>
          </div>
          <h1 className="text-secondaryWhite text-2xl not-italic font-normal leading-normal bg-black px-2 h-full flex items-center">
            Activity Log
          </h1>
        </div>
      </div>
      <UserBalance />
    </div>
  );
};

export default ActivityLogHeader;
