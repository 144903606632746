const Gem = ({ className="", width = 15, height = 15}: { className?: string, width?: number, height?: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    className={className}
  >
    <rect x="5" y="14" width="5" height="1" fill="black" />
    <rect x="5" width="5" height="1" fill="black" />
    <rect x="5" y="1" width="5" height="1" fill="#FF00B2" />
    <rect x="3" y="3" width="1" height="1" fill="#8C01AB" />
    <rect x="4" y="3" width="1" height="1" fill="#8C01AB" />
    <rect x="4" y="4" width="1" height="1" fill="#990094" />
    <rect x="3" y="5" width="3" height="1" fill="#990094" />
    <rect x="2" y="6" width="5" height="1" fill="#990094" />
    <rect x="2" y="7" width="5" height="1" fill="#990094" />
    <rect x="3" y="8" width="3" height="1" fill="#990094" />
    <rect x="1" y="8" width="2" height="1" fill="#FF00B7" />
    <rect x="1" y="7" width="1" height="1" fill="#FF00B7" />
    <rect x="1" y="9" width="1" height="1" fill="#FF00B7" />
    <rect x="4" y="9" width="1" height="1" fill="#990094" />
    <rect x="4" y="2" width="1" height="1" fill="#FF00B7" />
    <rect x="5" y="2" width="1" height="2" fill="#FF00B7" />
    <rect x="6" y="3" width="3" height="1" fill="#4D007C" />
    <rect x="5" y="4" width="5" height="1" fill="#4D007C" />
    <rect x="6" y="5" width="3" height="1" fill="#4D007C" />
    <rect x="7" y="6" width="1" height="1" fill="#4D007C" />
    <rect x="2" y="4" width="2" height="1" fill="#8C01AB" />
    <rect x="1" y="5" width="2" height="1" fill="#8C01AB" />
    <rect x="1" y="6" width="1" height="1" fill="#8C01AB" />
    <rect x="6" y="2" width="5" height="1" fill="#FF00B2" />
    <rect x="9" y="3" width="3" height="1" fill="#FF00B2" />
    <rect x="12" y="5" width="1" height="1" fill="#FF00B2" />
    <rect x="13" y="5" width="1" height="5" fill="#1E0067" />
    <rect x="12" y="8" width="1" height="3" fill="#263CBC" />
    <rect x="11" y="9" width="1" height="3" fill="#263CBC" />
    <rect x="10" y="10" width="1" height="3" fill="#263CBC" />
    <rect x="9" y="11" width="1" height="2" fill="#263CBC" />
    <rect x="5" y="13" width="5" height="1" fill="#2E005E" />
    <rect x="6" y="12" width="3" height="1" fill="#2E005E" />
    <rect x="4" y="12" width="2" height="1" fill="#6200BC" />
    <rect x="3" y="11" width="3" height="1" fill="#6200BC" />
    <rect x="2" y="10" width="3" height="1" fill="#6200BC" />
    <rect x="2" y="9" width="2" height="1" fill="#6200BC" />
    <rect x="6" y="11" width="3" height="1" fill="#3723C4" />
    <rect x="5" y="10" width="5" height="1" fill="#3723C4" />
    <rect x="5" y="9" width="5" height="1" fill="#3723C4" />
    <rect x="6" y="8" width="3" height="1" fill="#3723C4" />
    <rect x="7" y="7" width="1" height="1" fill="#3723C4" />
    <rect x="11" y="5" width="1" height="4" fill="#250280" />
    <rect x="8" y="6" width="1" height="2" fill="#250280" />
    <rect x="12" y="6" width="1" height="2" fill="#250280" />
    <rect x="10" y="4" width="1" height="6" fill="#250280" />
    <rect x="9" y="5" width="1" height="4" fill="#250280" />
    <rect x="11" y="4" width="2" height="1" fill="#FF00B2" />
    <rect x="10" y="13" width="1" height="1" fill="black" />
    <rect x="11" y="12" width="1" height="1" fill="black" />
    <rect x="12" y="11" width="1" height="1" fill="black" />
    <rect x="13" y="10" width="1" height="1" fill="black" />
    <rect x="13" y="4" width="1" height="1" fill="black" />
    <rect x="12" y="3" width="1" height="1" fill="black" />
    <rect x="11" y="2" width="1" height="1" fill="black" />
    <rect x="10" y="1" width="1" height="1" fill="black" />
    <rect x="4" y="1" width="1" height="1" fill="black" />
    <rect x="3" y="2" width="1" height="1" fill="black" />
    <rect x="2" y="3" width="1" height="1" fill="black" />
    <rect x="1" y="4" width="1" height="1" fill="black" />
    <rect x="1" y="10" width="1" height="1" fill="black" />
    <rect x="2" y="11" width="1" height="1" fill="black" />
    <rect x="3" y="12" width="1" height="1" fill="black" />
    <rect x="4" y="13" width="1" height="1" fill="black" />
    <rect x="14" y="5" width="1" height="5" fill="black" />
    <rect y="5" width="1" height="5" fill="black" />
  </svg>
);

export default Gem;
