const PokePointer = () => {
  return (
    <svg
      width="16"
      height="24"
      viewBox="0 0 16 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.05596 5.61111L7.05596 9.44445L12.1668 9.44445V10.7222H14.7221V20.9444L12.1668 20.9444V22.2222H7.0557L5.77818 22.2222V19.6666H3.22249L3.22249 18.3889H1.94482L1.94482 12H3.22249H4.50037H5.77818L5.77818 5.61111L7.05596 5.61111Z"
        fill="#FFBB00"
      />
      <rect
        x="5.77795"
        y="4.33331"
        width="11.5"
        height="1.27777"
        transform="rotate(90 5.77795 4.33331)"
        fill="black"
      />
      <rect
        x="7.05566"
        y="4.33331"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 7.05566 4.33331)"
        fill="black"
      />
      <rect
        x="3.22241"
        y="10.7222"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 3.22241 10.7222)"
        fill="black"
      />
      <rect
        x="4.5"
        y="12"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 4.5 12)"
        fill="black"
      />
      <rect
        x="4.5"
        y="3.05557"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 4.5 3.05557)"
        fill="black"
      />
      <rect
        x="3.22241"
        y="4.33331"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 3.22241 4.33331)"
        fill="black"
      />
      <rect
        x="1.94482"
        y="4.33331"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 1.94482 4.33331)"
        fill="black"
      />
      <rect
        x="3.22241"
        y="1.7778"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 3.22241 1.7778)"
        fill="black"
      />
      <rect
        x="5.77795"
        y="1.7778"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 5.77795 1.7778)"
        fill="black"
      />
      <rect
        x="5.77795"
        y="0.500031"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 5.77795 0.500031)"
        fill="black"
      />
      <rect
        x="12.1667"
        y="22.2222"
        width="1.27777"
        height="6.38887"
        transform="rotate(90 12.1667 22.2222)"
        fill="black"
      />
      <rect
        x="9.61121"
        y="8.16672"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 9.61121 8.16672)"
        fill="black"
      />
      <rect
        x="12.1667"
        y="9.44446"
        width="1.27777"
        height="2.55555"
        transform="rotate(90 12.1667 9.44446)"
        fill="black"
      />
      <rect
        x="14.7224"
        y="10.7222"
        width="1.27777"
        height="1.27777"
        transform="rotate(90 14.7224 10.7222)"
        fill="black"
      />
      <rect
        x="10.8892"
        y="10.7222"
        width="2.55555"
        height="1.27777"
        transform="rotate(90 10.8892 10.7222)"
        fill="black"
      />
      <rect
        x="13.4447"
        y="10.7222"
        width="3.83332"
        height="1.27777"
        transform="rotate(90 13.4447 10.7222)"
        fill="black"
      />
      <rect
        x="16"
        y="12"
        width="6.38887"
        height="1.27777"
        transform="rotate(90 16 12)"
        fill="black"
      />
      <rect
        x="14.7224"
        y="18.3889"
        width="2.55555"
        height="1.27777"
        transform="rotate(90 14.7224 18.3889)"
        fill="black"
      />
      <rect
        x="5.77795"
        y="19.6667"
        width="2.55555"
        height="1.27777"
        transform="rotate(90 5.77795 19.6667)"
        fill="black"
      />
      <rect
        width="1.27777"
        height="1.27777"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 4.5 19.6667)"
        fill="black"
      />
      <rect
        width="2.55555"
        height="1.27777"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 3.22241 18.3889)"
        fill="black"
      />
      <rect
        width="5.11109"
        height="1.27777"
        transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 1.94482 15.8333)"
        fill="black"
      />
      <rect
        x="13.4447"
        y="20.9444"
        width="2.55555"
        height="1.27777"
        transform="rotate(90 13.4447 20.9444)"
        fill="black"
      />
      <rect
        x="8.33362"
        y="5.61111"
        width="7.66664"
        height="1.27777"
        transform="rotate(90 8.33362 5.61111)"
        fill="black"
      />
    </svg>
  );
};

export default PokePointer;
