"use client";

import Gem from "../icons/Gem";
import Ticket from "../icons/Ticket";
import { REWARD_TYPE_LABELS, REWARD_TYPES } from "../../types/earn";
import useStore from "../../lib/store";

const EarnTabs = () => {
  const activeEarnTab = useStore((state) => state.activeEarnTab);
  const setActiveEarnTab = useStore((state) => state.setActiveEarnTab);

  return (
    <section className="grid grid-cols-2 w-full mt-4 h-[30px]">
      <button
        onClick={() => setActiveEarnTab(REWARD_TYPES.GEMS)}
        className={
          activeEarnTab === REWARD_TYPES.GEMS
            ? "bg-pink"
            : "bg-black text-grey border-b-[3px] border-pink bg-opacity-50"
        }
      >
        <div className="flex items-center justify-center gap-2">
          <Gem className={`opacity-${activeEarnTab === REWARD_TYPES.GEMS ? "100" : "50"}`} />
          <div>{REWARD_TYPE_LABELS[REWARD_TYPES.GEMS]}</div>
        </div>
      </button>
      <button
        onClick={() => setActiveEarnTab(REWARD_TYPES.TICKETS)}
        className={
          activeEarnTab === REWARD_TYPES.TICKETS
            ? "bg-teal"
            : "bg-black text-grey border-b-[3px] border-teal bg-opacity-50"
        }
      >
        <div className="flex items-center justify-center gap-2">
          <Ticket className={activeEarnTab === REWARD_TYPES.TICKETS ? "opacity-100" : "opacity-50"} />
          <div>{REWARD_TYPE_LABELS[REWARD_TYPES.TICKETS]}</div>
        </div>
      </button>
    </section>
  );
};

export default EarnTabs;
