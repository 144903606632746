import { GAME_SCORE_STORAGE_KEYS, GAMES } from "../types/home";
import { UserStat } from "../types/user";

export const getUserHighScore = (gameId: GAMES): number => {
  let storageKey;
  if (gameId === GAMES.GLOVE_HERO) {
    storageKey = GAME_SCORE_STORAGE_KEYS.GLOVE_HERO_HIGH_SCORE;
  } else if (gameId === GAMES.ICE_SNAKE) {
    storageKey = GAME_SCORE_STORAGE_KEYS.ICE_SNAKE_HIGH_SCORE;
  }
  if (storageKey) {
    try {
      return parseInt(localStorage.getItem(storageKey) || "0");
    } catch (error) {
      console.error("Error retrieving token:", error);
      return 0;
    }
  }
  console.error(`Error retrieving token: storage key for ${gameId} not found`);
  return 0;
};

export const getUserRank = (userStats: UserStat[], gameId: GAMES | null): string => {
  const stat = userStats.find(stat => stat.game === gameId);
  return stat?.rank ? stat.rank.toString() : "Unranked";
};