const IconArrow = ({ direction = "right" }: { direction?: string }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    style={{ transform: direction === "right" ? "rotate(0deg)" : "rotate(180deg)" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 0.75H3H4.5V2.25H6H7.5V3.75H9H10.5V5.25H12V6.75H10.5V8.25H9H7.5V9.75H6H4.5V11.25H3H1.5V9.75H0V2.25H1.5V0.75Z"
      fill="white"
    />
  </svg>
);

export default IconArrow;
