"use client";

import { useEffect, useRef, useState } from "react";
import useStore from "../../lib/store";
import Box from "../Box";
import Button from "../Form/Button";
import Gem from "../icons/Gem";
import IconSweet from "../icons/IconSweet";
import Loader from "../Loader";

const AccountConnect = () => {
  const user = useStore(state => state.user);
  const setSendAppStart = useStore(state => state.setSendAppStart);
  const sweetUsername = user?.sweet_username;
  const sweetEmail = user?.sweet_email;
  const [connectionPending, setConnectionPending] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Store interval reference, prevent duplicate intervals from being created

  const handleAccountConnect = () => {
    setConnectionPending(true);
    window.open(process.env.NEXT_PUBLIC_ACCOUNT_URL, "_blank");
  };

  const renderAccountConnected = () => (
    <div className="bg-secondaryWhite flex flex-col items-center justify-center gap-1 px-3">
      <div className="relative py-2 text-center text-lg">Earn gems across Sweet</div>
      <div className="bg-lightGrey flex w-full justify-between p-2 py-5 items-center">
        <div className="w-full leading-none text-center">
          You’re all set!
        </div>
      </div>
      <div className="flex gap-2 my-4 items-center">
        <IconSweet className="mr-2 rounded-[4px]" />
        <div className="flex flex-col justify-center items-start leading-none gap-[4px]">
          <span className="opacity-50">{`Connected to ${sweetEmail}`}</span>
          <button className="underline" onClick={handleAccountConnect}>VIEW ACCOUNT</button>
        </div>
      </div>
    </div>
  );

  const renderAccountNotConnected = () => (
    <div className="bg-secondaryWhite flex flex-col items-center justify-center px-3">
      <div className="relative p-2 text-center text-lg">Earn gems across Sweet</div>
      <div className="bg-lightGrey flex w-full justify-between p-2 py-3 items-center">
        <div className="w-44 leading-none">
          Join Sweet to prepare for addtional gem-earning opportunities!
        </div>
        <div className="text-xs flex gap-1 items-center bg-pink h-5 px-1">
          <div>Earn</div>
          <Gem className="w-4 h-4" />
          <div>1000</div>
        </div>
      </div>
      {connectionPending && (
        <div className="flex flex-col gap-1 justify-center items-center">
          <div className="opacity-50">Connecting to Sweet Account...</div>
          <Loader width={20} height={20} />
        </div>
      )}
      {!connectionPending && (
        <Button
          text="CONNECT SWEET ACCOUNT"
          textSize="base"
          width={250}
          height={48}
          onClick={handleAccountConnect}
          className="mt-3 mb-4 text-nowrap"
          buttonColor="pink"
          thickBottom={true}
          textPosition="start"
        />
      )}
    </div>
  );

  const renderAccountConnect = () => {
    if (sweetUsername) {
      return renderAccountConnected();
    } else {
      return renderAccountNotConnected();
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      if ((connectionPending || !sweetUsername) && !intervalRef.current) {
        // Use the Performance API for a monotonic clock 
        const startTime = performance.now();
        intervalRef.current = setInterval(() => {
          const currentState = useStore.getState();
          if (currentState.sendAppStart) {
            // it's in the process of sending the app start signal, don't resend
            return;
          }

          // if user connection found, stop interval
          if (currentState.user?.sweet_username) {
            setConnectionPending(false);
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
              intervalRef.current = null;
            }
            return;
          }

          // If 3 minutes (180,000 ms) have passed, stop the interval
          if (performance.now() - startTime >= 180000) {
            setConnectionPending(false);
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
              intervalRef.current = null;
            }
            return;
          }
          setSendAppStart(true);
        }, 3000);

        // Cleanup: Stop interval when component unmounts or before setting a new one
        return () => {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        };
      }
    };

    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("focus", handleFocus);
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionPending]);

  return (
    <div className="flex items-center justify-center w-full mt-4 px-2">
      <Box content={renderAccountConnect()} bottomBorderColor="black" width={346} />
    </div>
  );
};

export default AccountConnect;
