import Checkmark from "../icons/Checkmark";
import Flame from "../icons/Flame";
import StreakCircle from "../icons/StreakCircle";

const Streak = ({ streak }: { streak: number }) => {
  const getStreakIcons = (streak: number) => {
    const streakIcons = [];
    for (let i = 1; i < 8; i++) {
      let icon;
      if (i < streak) {
        icon = (
          <div className="flex items-center justify-center relative">
            <StreakCircle active={true} />
            <Checkmark className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
          </div>
        );
        streakIcons.push(icon);
      } else if (i === streak) {
        streakIcons.push(<Flame className="mb-5" />);
      } else {
        icon = (
          <div className="flex items-center justify-center relative">
            <StreakCircle />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-secondaryWhite text-lg">
              {i}
            </div>
          </div>
        );
        streakIcons.push(icon);
      }
    }
    return streakIcons;
  };

  return (
    <div className="flex gap-2 w-full items-center justify-center z-10">
      {getStreakIcons(streak).map(icon => icon)}
    </div>
  );
};

export default Streak;
