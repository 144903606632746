const Checkmark = ({
  className = "",
  fill = "white",
  width = "21",
  height = "15",
}: {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21 15"
    fill="none"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.615 3.23102V0.38504H20.4609V3.23102L17.615 3.23102ZM14.769 6.077V3.23102L17.615 3.23102V6.077H14.769ZM11.923 8.92299V6.077H14.769L14.769 8.92299H11.923ZM9.07701 11.769V8.92299L11.923 8.92299L11.923 11.769L9.07701 11.769ZM6.23103 11.769L9.07701 11.769V14.615H6.23103L6.23103 11.769ZM3.38504 8.92299L3.38504 11.769H6.23103V8.92299H3.38504ZM3.38504 8.92299L0.539062 8.92299V6.077L3.38504 6.077L3.38504 8.92299Z"
      fill={fill}
    />
  </svg>
);

export default Checkmark;
