const HockeyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="36" viewBox="0 0 35 36" fill="none">
    <g id="Fill" clip-path="url(#clip0_209_33659)">
      <path id="Vector" d="M34.9374 21.7798H33.6907V23.0585H34.9374V21.7798Z" fill="black" />
      <path id="Vector_2" d="M34.9374 20.5037H33.6907V21.7799H34.9374V20.5037Z" fill="black" />
      <path id="Vector_3" d="M34.9374 19.2249H33.6907V20.5036H34.9374V19.2249Z" fill="black" />
      <path id="Vector_4" d="M34.9374 17.9487H33.6907V19.2249H34.9374V17.9487Z" fill="black" />
      <path id="Vector_5" d="M34.9374 16.6699H33.6907V17.9487H34.9374V16.6699Z" fill="black" />
      <path id="Vector_6" d="M34.9374 15.3938H33.6907V16.67H34.9374V15.3938Z" fill="black" />
      <path id="Vector_7" d="M34.9374 14.115H33.6907V15.3937H34.9374V14.115Z" fill="black" />
      <path id="Vector_8" d="M34.9374 12.8389H33.6907V14.1151H34.9374V12.8389Z" fill="black" />
      <path id="Vector_9" d="M33.6907 24.3347H32.4465V25.6135H33.6907V24.3347Z" fill="black" />
      <path id="Vector_10" d="M33.6907 23.0586H32.4465V24.3348H33.6907V23.0586Z" fill="black" />
      <path id="Vector_11" d="M33.6907 21.7798H32.4465V23.0585H33.6907V21.7798Z" fill="#67C4FF" />
      <path id="Vector_12" d="M33.6907 20.5037H32.4465V21.7799H33.6907V20.5037Z" fill="#67C4FF" />
      <path id="Vector_13" d="M33.6907 19.2249H32.4465V20.5036H33.6907V19.2249Z" fill="#67C4FF" />
      <path id="Vector_14" d="M33.6907 17.9487H32.4465V19.2249H33.6907V17.9487Z" fill="#67C4FF" />
      <path id="Vector_15" d="M33.6907 16.6699H32.4465V17.9487H33.6907V16.6699Z" fill="#0096FF" />
      <path id="Vector_16" d="M33.6907 15.3938H32.4465V16.67H33.6907V15.3938Z" fill="#67C4FF" />
      <path id="Vector_17" d="M33.6907 14.115H32.4465V15.3937H33.6907V14.115Z" fill="#0096FF" />
      <path id="Vector_18" d="M33.6907 12.8389H32.4465V14.1151H33.6907V12.8389Z" fill="#0096FF" />
      <path id="Vector_19" d="M33.6907 11.5601H32.4465V12.8388H33.6907V11.5601Z" fill="black" />
      <path id="Vector_20" d="M33.6907 10.2839H32.4465V11.5601H33.6907V10.2839Z" fill="black" />
      <path id="Vector_21" d="M32.4465 26.8896H31.1998V28.1684H32.4465V26.8896Z" fill="black" />
      <path id="Vector_22" d="M32.4465 25.6135H31.1998V26.8897H32.4465V25.6135Z" fill="black" />
      <path id="Vector_23" d="M32.4465 24.3347H31.1998V25.6135H32.4465V24.3347Z" fill="#67C4FF" />
      <path id="Vector_24" d="M32.4465 23.0586H31.1998V24.3348H32.4465V23.0586Z" fill="#67C4FF" />
      <path id="Vector_25" d="M32.4465 21.7798H31.1998V23.0585H32.4465V21.7798Z" fill="#67C4FF" />
      <path id="Vector_26" d="M32.4465 20.5037H31.1998V21.7799H32.4465V20.5037Z" fill="#0096FF" />
      <path id="Vector_27" d="M32.4465 19.2249H31.1998V20.5036H32.4465V19.2249Z" fill="#67C4FF" />
      <path id="Vector_28" d="M32.4465 17.9487H31.1998V19.2249H32.4465V17.9487Z" fill="#67C4FF" />
      <path id="Vector_29" d="M32.4465 16.6699H31.1998V17.9487H32.4465V16.6699Z" fill="#67C4FF" />
      <path id="Vector_30" d="M32.4465 15.3938H31.1998V16.67H32.4465V15.3938Z" fill="#0096FF" />
      <path id="Vector_31" d="M32.4465 14.115H31.1998V15.3937H32.4465V14.115Z" fill="#0096FF" />
      <path id="Vector_32" d="M32.4465 12.8389H31.1998V14.1151H32.4465V12.8389Z" fill="#0096FF" />
      <path id="Vector_33" d="M32.4465 11.5601H31.1998V12.8388H32.4465V11.5601Z" fill="#0096FF" />
      <path id="Vector_34" d="M32.4465 10.2839H31.1998V11.5601H32.4465V10.2839Z" fill="#0096FF" />
      <path id="Vector_35" d="M32.4465 9.00513H31.1998V10.2839H32.4465V9.00513Z" fill="black" />
      <path id="Vector_36" d="M32.4465 7.729H31.1998V9.0052H32.4465V7.729Z" fill="black" />
      <path id="Vector_37" d="M31.1998 28.1685H29.9557V29.4472H31.1998V28.1685Z" fill="black" />
      <path id="Vector_38" d="M31.1998 26.8896H29.9557V28.1684H31.1998V26.8896Z" fill="#C2EBFF" />
      <path id="Vector_39" d="M31.1998 25.6135H29.9557V26.8897H31.1998V25.6135Z" fill="#67C4FF" />
      <path id="Vector_40" d="M31.1998 24.3347H29.9557V25.6135H31.1998V24.3347Z" fill="#67C4FF" />
      <path id="Vector_41" d="M31.1998 23.0586H29.9557V24.3348H31.1998V23.0586Z" fill="#67C4FF" />
      <path id="Vector_42" d="M31.1998 21.7798H29.9557V23.0585H31.1998V21.7798Z" fill="#67C4FF" />
      <path id="Vector_43" d="M31.1998 20.5037H29.9557V21.7799H31.1998V20.5037Z" fill="#0096FF" />
      <path id="Vector_44" d="M31.1998 19.2249H29.9557V20.5036H31.1998V19.2249Z" fill="#0096FF" />
      <path id="Vector_45" d="M31.1998 17.9487H29.9557V19.2249H31.1998V17.9487Z" fill="#67C4FF" />
      <path id="Vector_46" d="M31.1998 16.6699H29.9557V17.9487H31.1998V16.6699Z" fill="#67C4FF" />
      <path id="Vector_47" d="M31.1998 15.3938H29.9557V16.67H31.1998V15.3938Z" fill="#0096FF" />
      <path id="Vector_48" d="M31.1998 14.115H29.9557V15.3937H31.1998V14.115Z" fill="#0096FF" />
      <path id="Vector_49" d="M31.1998 12.8389H29.9557V14.1151H31.1998V12.8389Z" fill="#0096FF" />
      <path id="Vector_50" d="M31.1998 11.5601H29.9557V12.8388H31.1998V11.5601Z" fill="#0096FF" />
      <path id="Vector_51" d="M31.1998 10.2839H29.9557V11.5601H31.1998V10.2839Z" fill="#0096FF" />
      <path id="Vector_52" d="M31.1998 9.00513H29.9557V10.2839H31.1998V9.00513Z" fill="#67C4FF" />
      <path id="Vector_53" d="M31.1998 7.729H29.9557V9.0052H31.1998V7.729Z" fill="#67C4FF" />
      <path id="Vector_54" d="M31.1998 6.4502H29.9557V7.72895H31.1998V6.4502Z" fill="black" />
      <path id="Vector_55" d="M29.9557 29.4473H28.709V30.7235H29.9557V29.4473Z" fill="black" />
      <path id="Vector_56" d="M29.9557 28.1685H28.709V29.4472H29.9557V28.1685Z" fill="#C2EBFF" />
      <path id="Vector_57" d="M29.9557 26.8896H28.709V28.1684H29.9557V26.8896Z" fill="#C2EBFF" />
      <path id="Vector_58" d="M29.9557 25.6135H28.709V26.8897H29.9557V25.6135Z" fill="#67C4FF" />
      <path id="Vector_59" d="M29.9557 24.3347H28.709V25.6135H29.9557V24.3347Z" fill="#67C4FF" />
      <path id="Vector_60" d="M29.9557 23.0586H28.709V24.3348H29.9557V23.0586Z" fill="#67C4FF" />
      <path id="Vector_61" d="M29.9557 21.7798H28.709V23.0585H29.9557V21.7798Z" fill="#0096FF" />
      <path id="Vector_62" d="M29.9557 20.5037H28.709V21.7799H29.9557V20.5037Z" fill="#0096FF" />
      <path id="Vector_63" d="M29.9557 19.2249H28.709V20.5036H29.9557V19.2249Z" fill="#0096FF" />
      <path id="Vector_64" d="M29.9557 17.9487H28.709V19.2249H29.9557V17.9487Z" fill="#67C4FF" />
      <path id="Vector_65" d="M29.9557 16.6699H28.709V17.9487H29.9557V16.6699Z" fill="#67C4FF" />
      <path id="Vector_66" d="M29.9557 15.3938H28.709V16.67H29.9557V15.3938Z" fill="#67C4FF" />
      <path id="Vector_67" d="M29.9557 14.115H28.709V15.3937H29.9557V14.115Z" fill="#0096FF" />
      <path id="Vector_68" d="M29.9557 12.8389H28.709V14.1151H29.9557V12.8389Z" fill="#0096FF" />
      <path id="Vector_69" d="M29.9557 11.5601H28.709V12.8388H29.9557V11.5601Z" fill="#0096FF" />
      <path id="Vector_70" d="M29.9557 10.2839H28.709V11.5601H29.9557V10.2839Z" fill="#67C4FF" />
      <path id="Vector_71" d="M29.9557 9.00513H28.709V10.2839H29.9557V9.00513Z" fill="#67C4FF" />
      <path id="Vector_72" d="M29.9557 7.729H28.709V9.0052H29.9557V7.729Z" fill="#67C4FF" />
      <path id="Vector_73" d="M29.9557 6.4502H28.709V7.72895H29.9557V6.4502Z" fill="#C2EBFF" />
      <path id="Vector_74" d="M29.9557 5.17407H28.709V6.45027H29.9557V5.17407Z" fill="black" />
      <path id="Vector_75" d="M28.709 30.7234H27.4648V32.0021H28.709V30.7234Z" fill="black" />
      <path id="Vector_76" d="M28.709 29.4473H27.4648V30.7235H28.709V29.4473Z" fill="#C2EBFF" />
      <path id="Vector_77" d="M28.709 28.1685H27.4648V29.4472H28.709V28.1685Z" fill="#C2EBFF" />
      <path id="Vector_78" d="M28.709 26.8896H27.4648V28.1684H28.709V26.8896Z" fill="#67C4FF" />
      <path id="Vector_79" d="M28.709 25.6135H27.4648V26.8897H28.709V25.6135Z" fill="#67C4FF" />
      <path id="Vector_80" d="M28.709 24.3347H27.4648V25.6135H28.709V24.3347Z" fill="#67C4FF" />
      <path id="Vector_81" d="M28.709 23.0586H27.4648V24.3348H28.709V23.0586Z" fill="#67C4FF" />
      <path id="Vector_82" d="M28.709 21.7798H27.4648V23.0585H28.709V21.7798Z" fill="#0096FF" />
      <path id="Vector_83" d="M28.709 20.5037H27.4648V21.7799H28.709V20.5037Z" fill="#0096FF" />
      <path id="Vector_84" d="M28.709 19.2249H27.4648V20.5036H28.709V19.2249Z" fill="#0096FF" />
      <path id="Vector_85" d="M28.709 17.9487H27.4648V19.2249H28.709V17.9487Z" fill="#0096FF" />
      <path id="Vector_86" d="M28.709 16.6699H27.4648V17.9487H28.709V16.6699Z" fill="#67C4FF" />
      <path id="Vector_87" d="M28.709 15.3938H27.4648V16.67H28.709V15.3938Z" fill="#67C4FF" />
      <path id="Vector_88" d="M28.709 14.115H27.4648V15.3937H28.709V14.115Z" fill="#0096FF" />
      <path id="Vector_89" d="M28.709 12.8389H27.4648V14.1151H28.709V12.8389Z" fill="#0096FF" />
      <path id="Vector_90" d="M28.709 11.5601H27.4648V12.8388H28.709V11.5601Z" fill="#0096FF" />
      <path id="Vector_91" d="M28.709 10.2839H27.4648V11.5601H28.709V10.2839Z" fill="#67C4FF" />
      <path id="Vector_92" d="M28.709 9.00513H27.4648V10.2839H28.709V9.00513Z" fill="#67C4FF" />
      <path id="Vector_93" d="M28.709 7.729H27.4648V9.0052H28.709V7.729Z" fill="#67C4FF" />
      <path id="Vector_94" d="M28.709 6.4502H27.4648V7.72895H28.709V6.4502Z" fill="#C2EBFF" />
      <path id="Vector_95" d="M28.709 5.17407H27.4648V6.45027H28.709V5.17407Z" fill="#C2EBFF" />
      <path id="Vector_96" d="M28.709 3.89526H27.4648V5.17402H28.709V3.89526Z" fill="black" />
      <path id="Vector_97" d="M27.4648 32.0022H26.2181V33.2784H27.4648V32.0022Z" fill="black" />
      <path id="Vector_98" d="M27.4648 30.7234H26.2181V32.0021H27.4648V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_99" d="M27.4648 29.4473H26.2181V30.7235H27.4648V29.4473Z" fill="#C2EBFF" />
      <path id="Vector_100" d="M27.4648 28.1685H26.2181V29.4472H27.4648V28.1685Z" fill="#C2EBFF" />
      <path id="Vector_101" d="M27.4648 26.8896H26.2181V28.1684H27.4648V26.8896Z" fill="#67C4FF" />
      <path id="Vector_102" d="M27.4648 25.6135H26.2181V26.8897H27.4648V25.6135Z" fill="#67C4FF" />
      <path id="Vector_103" d="M27.4648 24.3347H26.2181V25.6135H27.4648V24.3347Z" fill="#67C4FF" />
      <path id="Vector_104" d="M27.4648 23.0586H26.2181V24.3348H27.4648V23.0586Z" fill="#67C4FF" />
      <path id="Vector_105" d="M27.4648 21.7798H26.2181V23.0585H27.4648V21.7798Z" fill="#0096FF" />
      <path id="Vector_106" d="M27.4648 20.5037H26.2181V21.7799H27.4648V20.5037Z" fill="#0096FF" />
      <path id="Vector_107" d="M27.4648 19.2249H26.2181V20.5036H27.4648V19.2249Z" fill="#67C4FF" />
      <path id="Vector_108" d="M27.4648 17.9487H26.2181V19.2249H27.4648V17.9487Z" fill="#0096FF" />
      <path id="Vector_109" d="M27.4648 16.6699H26.2181V17.9487H27.4648V16.6699Z" fill="#67C4FF" />
      <path id="Vector_110" d="M27.4648 15.3938H26.2181V16.67H27.4648V15.3938Z" fill="#67C4FF" />
      <path id="Vector_111" d="M27.4648 14.115H26.2181V15.3937H27.4648V14.115Z" fill="#0096FF" />
      <path id="Vector_112" d="M27.4648 12.8389H26.2181V14.1151H27.4648V12.8389Z" fill="#0096FF" />
      <path id="Vector_113" d="M27.4648 11.5601H26.2181V12.8388H27.4648V11.5601Z" fill="#67C4FF" />
      <path id="Vector_114" d="M27.4648 10.2839H26.2181V11.5601H27.4648V10.2839Z" fill="black" />
      <path id="Vector_115" d="M27.4648 9.00513H26.2181V10.2839H27.4648V9.00513Z" fill="black" />
      <path id="Vector_116" d="M27.4648 7.729H26.2181V9.0052H27.4648V7.729Z" fill="black" />
      <path id="Vector_117" d="M27.4648 6.4502H26.2181V7.72895H27.4648V6.4502Z" fill="black" />
      <path id="Vector_118" d="M27.4648 5.17407H26.2181V6.45027H27.4648V5.17407Z" fill="black" />
      <path id="Vector_119" d="M27.4648 3.89526H26.2181V5.17402H27.4648V3.89526Z" fill="black" />
      <path id="Vector_120" d="M27.4648 2.6189H26.2181V3.89509H27.4648V2.6189Z" fill="black" />
      <path id="Vector_121" d="M26.2181 32.0022H24.9739V33.2784H26.2181V32.0022Z" fill="black" />
      <path id="Vector_122" d="M26.2181 30.7234H24.9739V32.0021H26.2181V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_123" d="M26.2181 29.4473H24.9739V30.7235H26.2181V29.4473Z" fill="#C2EBFF" />
      <path id="Vector_124" d="M26.2181 28.1685H24.9739V29.4472H26.2181V28.1685Z" fill="#67C4FF" />
      <path id="Vector_125" d="M26.2181 26.8896H24.9739V28.1684H26.2181V26.8896Z" fill="#67C4FF" />
      <path id="Vector_126" d="M26.2181 25.6135H24.9739V26.8897H26.2181V25.6135Z" fill="#67C4FF" />
      <path id="Vector_127" d="M26.2181 24.3347H24.9739V25.6135H26.2181V24.3347Z" fill="#67C4FF" />
      <path id="Vector_128" d="M26.2181 23.0586H24.9739V24.3348H26.2181V23.0586Z" fill="black" />
      <path id="Vector_129" d="M26.2181 21.7798H24.9739V23.0585H26.2181V21.7798Z" fill="black" />
      <path id="Vector_130" d="M26.2181 20.5037H24.9739V21.7799H26.2181V20.5037Z" fill="black" />
      <path id="Vector_131" d="M26.2181 19.2249H24.9739V20.5036H26.2181V19.2249Z" fill="black" />
      <path id="Vector_132" d="M26.2181 17.9487H24.9739V19.2249H26.2181V17.9487Z" fill="black" />
      <path id="Vector_133" d="M26.2181 16.6699H24.9739V17.9487H26.2181V16.6699Z" fill="black" />
      <path id="Vector_134" d="M26.2181 15.3938H24.9739V16.67H26.2181V15.3938Z" fill="black" />
      <path id="Vector_135" d="M26.2181 14.115H24.9739V15.3937H26.2181V14.115Z" fill="black" />
      <path id="Vector_136" d="M26.2181 12.8389H24.9739V14.1151H26.2181V12.8389Z" fill="black" />
      <path id="Vector_137" d="M26.2181 11.5601H24.9739V12.8388H26.2181V11.5601Z" fill="black" />
      <path id="Vector_138" d="M26.2181 10.2839H24.9739V11.5601H26.2181V10.2839Z" fill="black" />
      <path id="Vector_139" d="M26.2181 9.00513H24.9739V10.2839H26.2181V9.00513Z" fill="black" />
      <path id="Vector_140" d="M26.2181 7.729H24.9739V9.0052H26.2181V7.729Z" fill="black" />
      <path id="Vector_141" d="M26.2181 6.4502H24.9739V7.72895H26.2181V6.4502Z" fill="black" />
      <path id="Vector_142" d="M26.2181 5.17407H24.9739V6.45027H26.2181V5.17407Z" fill="black" />
      <path id="Vector_143" d="M26.2181 3.89526H24.9739V5.17402H26.2181V3.89526Z" fill="black" />
      <path id="Vector_144" d="M26.2181 2.6189H24.9739V3.89509H26.2181V2.6189Z" fill="black" />
      <path id="Vector_145" d="M24.9739 33.2783H23.7272V34.5571H24.9739V33.2783Z" fill="black" />
      <path id="Vector_146" d="M24.9739 32.0022H23.7272V33.2784H24.9739V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_147" d="M24.9739 30.7234H23.7272V32.0021H24.9739V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_148" d="M24.9739 29.4473H23.7272V30.7235H24.9739V29.4473Z" fill="#C2EBFF" />
      <path id="Vector_149" d="M24.9739 28.1685H23.7272V29.4472H24.9739V28.1685Z" fill="#67C4FF" />
      <path id="Vector_150" d="M24.9739 26.8896H23.7272V28.1684H24.9739V26.8896Z" fill="#67C4FF" />
      <path id="Vector_151" d="M24.9739 25.6135H23.7272V26.8897H24.9739V25.6135Z" fill="#67C4FF" />
      <path id="Vector_152" d="M24.9739 24.3347H23.7272V25.6135H24.9739V24.3347Z" fill="black" />
      <path id="Vector_153" d="M24.9739 23.0586H23.7272V24.3348H24.9739V23.0586Z" fill="black" />
      <path id="Vector_154" d="M24.9739 21.7798H23.7272V23.0585H24.9739V21.7798Z" fill="#6D6E71" />
      <path id="Vector_155" d="M24.9739 20.5037H23.7272V21.7799H24.9739V20.5037Z" fill="black" />
      <path id="Vector_156" d="M24.9739 19.2249H23.7272V20.5036H24.9739V19.2249Z" fill="black" />
      <path id="Vector_157" d="M24.9739 17.9487H23.7272V19.2249H24.9739V17.9487Z" fill="black" />
      <path id="Vector_158" d="M24.9739 16.6699H23.7272V17.9487H24.9739V16.6699Z" fill="black" />
      <path id="Vector_159" d="M24.9739 15.3938H23.7272V16.67H24.9739V15.3938Z" fill="black" />
      <path id="Vector_160" d="M24.9739 14.115H23.7272V15.3937H24.9739V14.115Z" fill="black" />
      <path id="Vector_161" d="M24.9739 12.8389H23.7272V14.1151H24.9739V12.8389Z" fill="black" />
      <path id="Vector_162" d="M24.9739 11.5601H23.7272V12.8388H24.9739V11.5601Z" fill="black" />
      <path id="Vector_163" d="M24.9739 10.2839H23.7272V11.5601H24.9739V10.2839Z" fill="black" />
      <path id="Vector_164" d="M24.9739 9.00513H23.7272V10.2839H24.9739V9.00513Z" fill="black" />
      <path id="Vector_165" d="M24.9739 7.729H23.7272V9.0052H24.9739V7.729Z" fill="black" />
      <path id="Vector_166" d="M24.9739 6.4502H23.7272V7.72895H24.9739V6.4502Z" fill="#C2EBFF" />
      <path id="Vector_167" d="M24.9739 5.17407H23.7272V6.45027H24.9739V5.17407Z" fill="#67C4FF" />
      <path id="Vector_168" d="M24.9739 3.89526H23.7272V5.17402H24.9739V3.89526Z" fill="#67C4FF" />
      <path id="Vector_169" d="M24.9739 2.6189H23.7272V3.89509H24.9739V2.6189Z" fill="#0096FF" />
      <path id="Vector_170" d="M24.9739 1.34033H23.7272V2.61909H24.9739V1.34033Z" fill="black" />
      <path id="Vector_171" d="M23.7272 33.2783H22.483V34.5571H23.7272V33.2783Z" fill="black" />
      <path id="Vector_172" d="M23.7272 32.0022H22.483V33.2784H23.7272V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_173" d="M23.7272 30.7234H22.483V32.0021H23.7272V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_174" d="M23.7272 29.4473H22.483V30.7235H23.7272V29.4473Z" fill="#67C4FF" />
      <path id="Vector_175" d="M23.7272 28.1685H22.483V29.4472H23.7272V28.1685Z" fill="#67C4FF" />
      <path id="Vector_176" d="M23.7272 26.8896H22.483V28.1684H23.7272V26.8896Z" fill="#67C4FF" />
      <path id="Vector_177" d="M23.7272 25.6135H22.483V26.8897H23.7272V25.6135Z" fill="black" />
      <path id="Vector_178" d="M23.7272 24.3347H22.483V25.6135H23.7272V24.3347Z" fill="black" />
      <path id="Vector_179" d="M23.7272 23.0586H22.483V24.3348H23.7272V23.0586Z" fill="#6D6E71" />
      <path id="Vector_180" d="M23.7272 21.7798H22.483V23.0585H23.7272V21.7798Z" fill="black" />
      <path id="Vector_181" d="M23.7272 20.5037H22.483V21.7799H23.7272V20.5037Z" fill="#0096FF" />
      <path id="Vector_182" d="M23.7272 19.2249H22.483V20.5036H23.7272V19.2249Z" fill="#67C4FF" />
      <path id="Vector_183" d="M23.7272 17.9487H22.483V19.2249H23.7272V17.9487Z" fill="#67C4FF" />
      <path id="Vector_184" d="M23.7272 16.6699H22.483V17.9487H23.7272V16.6699Z" fill="#0096FF" />
      <path id="Vector_185" d="M23.7272 15.3938H22.483V16.67H23.7272V15.3938Z" fill="#0096FF" />
      <path id="Vector_186" d="M23.7272 14.115H22.483V15.3937H23.7272V14.115Z" fill="#0096FF" />
      <path id="Vector_187" d="M23.7272 12.8389H22.483V14.1151H23.7272V12.8389Z" fill="#0096FF" />
      <path id="Vector_188" d="M23.7272 11.5601H22.483V12.8388H23.7272V11.5601Z" fill="#67C4FF" />
      <path id="Vector_189" d="M23.7272 10.2839H22.483V11.5601H23.7272V10.2839Z" fill="#67C4FF" />
      <path id="Vector_190" d="M23.7272 9.00513H22.483V10.2839H23.7272V9.00513Z" fill="#C2EBFF" />
      <path id="Vector_191" d="M23.7272 7.729H22.483V9.0052H23.7272V7.729Z" fill="#C2EBFF" />
      <path id="Vector_192" d="M23.7272 6.4502H22.483V7.72895H23.7272V6.4502Z" fill="#C2EBFF" />
      <path id="Vector_193" d="M23.7272 5.17407H22.483V6.45027H23.7272V5.17407Z" fill="#67C4FF" />
      <path id="Vector_194" d="M23.7272 3.89526H22.483V5.17402H23.7272V3.89526Z" fill="#67C4FF" />
      <path id="Vector_195" d="M23.7272 2.6189H22.483V3.89509H23.7272V2.6189Z" fill="#0096FF" />
      <path id="Vector_196" d="M23.7272 1.34033H22.483V2.61909H23.7272V1.34033Z" fill="black" />
      <path id="Vector_197" d="M22.483 34.5571H21.2363V35.8333H22.483V34.5571Z" fill="black" />
      <path id="Vector_198" d="M22.483 33.2783H21.2363V34.5571H22.483V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_199" d="M22.483 32.0022H21.2363V33.2784H22.483V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_200" d="M22.483 30.7234H21.2363V32.0021H22.483V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_201" d="M22.483 29.4473H21.2363V30.7235H22.483V29.4473Z" fill="#67C4FF" />
      <path id="Vector_202" d="M22.483 28.1685H21.2363V29.4472H22.483V28.1685Z" fill="#67C4FF" />
      <path id="Vector_203" d="M22.483 26.8896H21.2363V28.1684H22.483V26.8896Z" fill="black" />
      <path id="Vector_204" d="M22.483 25.6135H21.2363V26.8897H22.483V25.6135Z" fill="black" />
      <path id="Vector_205" d="M22.483 24.3347H21.2363V25.6135H22.483V24.3347Z" fill="#6D6E71" />
      <path id="Vector_206" d="M22.483 23.0586H21.2363V24.3348H22.483V23.0586Z" fill="black" />
      <path id="Vector_207" d="M22.483 21.7798H21.2363V23.0585H22.483V21.7798Z" fill="#0096FF" />
      <path id="Vector_208" d="M22.483 20.5037H21.2363V21.7799H22.483V20.5037Z" fill="#0096FF" />
      <path id="Vector_209" d="M22.483 19.2249H21.2363V20.5036H22.483V19.2249Z" fill="#0096FF" />
      <path id="Vector_210" d="M22.483 17.9487H21.2363V19.2249H22.483V17.9487Z" fill="#67C4FF" />
      <path id="Vector_211" d="M22.483 16.6699H21.2363V17.9487H22.483V16.6699Z" fill="#67C4FF" />
      <path id="Vector_212" d="M22.483 15.3938H21.2363V16.67H22.483V15.3938Z" fill="#0096FF" />
      <path id="Vector_213" d="M22.483 14.115H21.2363V15.3937H22.483V14.115Z" fill="#0096FF" />
      <path id="Vector_214" d="M22.483 12.8389H21.2363V14.1151H22.483V12.8389Z" fill="#0096FF" />
      <path id="Vector_215" d="M22.483 11.5601H21.2363V12.8388H22.483V11.5601Z" fill="#67C4FF" />
      <path id="Vector_216" d="M22.483 10.2839H21.2363V11.5601H22.483V10.2839Z" fill="#67C4FF" />
      <path id="Vector_217" d="M22.483 9.00513H21.2363V10.2839H22.483V9.00513Z" fill="#C2EBFF" />
      <path id="Vector_218" d="M22.483 7.729H21.2363V9.0052H22.483V7.729Z" fill="#C2EBFF" />
      <path id="Vector_219" d="M22.483 6.4502H21.2363V7.72895H22.483V6.4502Z" fill="#67C4FF" />
      <path id="Vector_220" d="M22.483 5.17407H21.2363V6.45027H22.483V5.17407Z" fill="#67C4FF" />
      <path id="Vector_221" d="M22.483 3.89526H21.2363V5.17402H22.483V3.89526Z" fill="#67C4FF" />
      <path id="Vector_222" d="M22.483 2.6189H21.2363V3.89509H22.483V2.6189Z" fill="#0096FF" />
      <path id="Vector_223" d="M22.483 1.34033H21.2363V2.61909H22.483V1.34033Z" fill="#0096FF" />
      <path id="Vector_224" d="M22.483 0.0639648H21.2363V1.34016H22.483V0.0639648Z" fill="black" />
      <path id="Vector_225" d="M21.2363 34.5571H19.9921V35.8333H21.2363V34.5571Z" fill="black" />
      <path id="Vector_226" d="M21.2363 33.2783H19.9921V34.5571H21.2363V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_227" d="M21.2363 32.0022H19.9921V33.2784H21.2363V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_228" d="M21.2363 30.7234H19.9921V32.0021H21.2363V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_229" d="M21.2363 29.4473H19.9921V30.7235H21.2363V29.4473Z" fill="#67C4FF" />
      <path id="Vector_230" d="M21.2363 28.1685H19.9921V29.4472H21.2363V28.1685Z" fill="black" />
      <path id="Vector_231" d="M21.2363 26.8896H19.9921V28.1684H21.2363V26.8896Z" fill="black" />
      <path id="Vector_232" d="M21.2363 25.6135H19.9921V26.8897H21.2363V25.6135Z" fill="black" />
      <path id="Vector_233" d="M21.2363 24.3347H19.9921V25.6135H21.2363V24.3347Z" fill="#6D6E71" />
      <path id="Vector_234" d="M21.2363 23.0586H19.9921V24.3348H21.2363V23.0586Z" fill="black" />
      <path id="Vector_235" d="M21.2363 21.7798H19.9921V23.0585H21.2363V21.7798Z" fill="#0096FF" />
      <path id="Vector_236" d="M21.2363 20.5037H19.9921V21.7799H21.2363V20.5037Z" fill="#0096FF" />
      <path id="Vector_237" d="M21.2363 19.2249H19.9921V20.5036H21.2363V19.2249Z" fill="#0096FF" />
      <path id="Vector_238" d="M21.2363 17.9487H19.9921V19.2249H21.2363V17.9487Z" fill="#0096FF" />
      <path id="Vector_239" d="M21.2363 16.6699H19.9921V17.9487H21.2363V16.6699Z" fill="#67C4FF" />
      <path id="Vector_240" d="M21.2363 15.3938H19.9921V16.67H21.2363V15.3938Z" fill="#0096FF" />
      <path id="Vector_241" d="M21.2363 14.115H19.9921V15.3937H21.2363V14.115Z" fill="#0096FF" />
      <path id="Vector_242" d="M21.2363 12.8389H19.9921V14.1151H21.2363V12.8389Z" fill="#67C4FF" />
      <path id="Vector_243" d="M21.2363 11.5601H19.9921V12.8388H21.2363V11.5601Z" fill="#67C4FF" />
      <path id="Vector_244" d="M21.2363 10.2839H19.9921V11.5601H21.2363V10.2839Z" fill="#67C4FF" />
      <path id="Vector_245" d="M21.2363 9.00513H19.9921V10.2839H21.2363V9.00513Z" fill="#C2EBFF" />
      <path id="Vector_246" d="M21.2363 7.729H19.9921V9.0052H21.2363V7.729Z" fill="#C2EBFF" />
      <path id="Vector_247" d="M21.2363 6.4502H19.9921V7.72895H21.2363V6.4502Z" fill="#67C4FF" />
      <path id="Vector_248" d="M21.2363 5.17407H19.9921V6.45027H21.2363V5.17407Z" fill="#67C4FF" />
      <path id="Vector_249" d="M21.2363 3.89526H19.9921V5.17402H21.2363V3.89526Z" fill="#0096FF" />
      <path id="Vector_250" d="M21.2363 2.6189H19.9921V3.89509H21.2363V2.6189Z" fill="#0096FF" />
      <path id="Vector_251" d="M21.2363 1.34033H19.9921V2.61909H21.2363V1.34033Z" fill="#0096FF" />
      <path
        id="Vector_252"
        d="M21.2363 0.0639648H19.9921V1.34016H21.2363V0.0639648Z"
        fill="black"
      />
      <path id="Vector_253" d="M19.9921 34.5571H18.7454V35.8333H19.9921V34.5571Z" fill="black" />
      <path id="Vector_254" d="M19.9921 33.2783H18.7454V34.5571H19.9921V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_255" d="M19.9921 32.0022H18.7454V33.2784H19.9921V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_256" d="M19.9921 30.7234H18.7454V32.0021H19.9921V30.7234Z" fill="#C2EBFF" />
      <path id="Vector_257" d="M19.9921 29.4473H18.7454V30.7235H19.9921V29.4473Z" fill="#67C4FF" />
      <path id="Vector_258" d="M19.9921 28.1685H18.7454V29.4472H19.9921V28.1685Z" fill="black" />
      <path id="Vector_259" d="M19.9921 26.8896H18.7454V28.1684H19.9921V26.8896Z" fill="black" />
      <path id="Vector_260" d="M19.9921 25.6135H18.7454V26.8897H19.9921V25.6135Z" fill="black" />
      <path id="Vector_261" d="M19.9921 24.3347H18.7454V25.6135H19.9921V24.3347Z" fill="#6D6E71" />
      <path id="Vector_262" d="M19.9921 23.0586H18.7454V24.3348H19.9921V23.0586Z" fill="black" />
      <path id="Vector_263" d="M19.9921 21.7798H18.7454V23.0585H19.9921V21.7798Z" fill="#0096FF" />
      <path id="Vector_264" d="M19.9921 20.5037H18.7454V21.7799H19.9921V20.5037Z" fill="#0096FF" />
      <path id="Vector_265" d="M19.9921 19.2249H18.7454V20.5036H19.9921V19.2249Z" fill="#67C4FF" />
      <path id="Vector_266" d="M19.9921 17.9487H18.7454V19.2249H19.9921V17.9487Z" fill="#0096FF" />
      <path id="Vector_267" d="M19.9921 16.6699H18.7454V17.9487H19.9921V16.6699Z" fill="#67C4FF" />
      <path id="Vector_268" d="M19.9921 15.3938H18.7454V16.67H19.9921V15.3938Z" fill="#67C4FF" />
      <path id="Vector_269" d="M19.9921 14.115H18.7454V15.3937H19.9921V14.115Z" fill="#0096FF" />
      <path id="Vector_270" d="M19.9921 12.8389H18.7454V14.1151H19.9921V12.8389Z" fill="#67C4FF" />
      <path id="Vector_271" d="M19.9921 11.5601H18.7454V12.8388H19.9921V11.5601Z" fill="#C2EBFF" />
      <path id="Vector_272" d="M19.9921 10.2839H18.7454V11.5601H19.9921V10.2839Z" fill="#C2EBFF" />
      <path id="Vector_273" d="M19.9921 9.00513H18.7454V10.2839H19.9921V9.00513Z" fill="#C2EBFF" />
      <path id="Vector_274" d="M19.9921 7.729H18.7454V9.0052H19.9921V7.729Z" fill="#67C4FF" />
      <path id="Vector_275" d="M19.9921 6.4502H18.7454V7.72895H19.9921V6.4502Z" fill="#67C4FF" />
      <path id="Vector_276" d="M19.9921 5.17407H18.7454V6.45027H19.9921V5.17407Z" fill="#67C4FF" />
      <path id="Vector_277" d="M19.9921 3.89526H18.7454V5.17402H19.9921V3.89526Z" fill="#0096FF" />
      <path id="Vector_278" d="M19.9921 2.6189H18.7454V3.89509H19.9921V2.6189Z" fill="#0096FF" />
      <path id="Vector_279" d="M19.9921 1.34033H18.7454V2.61909H19.9921V1.34033Z" fill="#0096FF" />
      <path
        id="Vector_280"
        d="M19.9921 0.0639648H18.7454V1.34016H19.9921V0.0639648Z"
        fill="black"
      />
      <path id="Vector_281" d="M18.7454 34.5571H17.5013V35.8333H18.7454V34.5571Z" fill="black" />
      <path id="Vector_282" d="M18.7454 33.2783H17.5013V34.5571H18.7454V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_283" d="M18.7454 32.0022H17.5013V33.2784H18.7454V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_284" d="M18.7454 30.7234H17.5013V32.0021H18.7454V30.7234Z" fill="#67C4FF" />
      <path id="Vector_285" d="M18.7454 29.4473H17.5013V30.7235H18.7454V29.4473Z" fill="#67C4FF" />
      <path id="Vector_286" d="M18.7454 28.1685H17.5013V29.4472H18.7454V28.1685Z" fill="black" />
      <path id="Vector_287" d="M18.7454 26.8896H17.5013V28.1684H18.7454V26.8896Z" fill="black" />
      <path id="Vector_288" d="M18.7454 25.6135H17.5013V26.8897H18.7454V25.6135Z" fill="black" />
      <path id="Vector_289" d="M18.7454 24.3347H17.5013V25.6135H18.7454V24.3347Z" fill="#6D6E71" />
      <path id="Vector_290" d="M18.7454 23.0586H17.5013V24.3348H18.7454V23.0586Z" fill="black" />
      <path id="Vector_291" d="M18.7454 21.7798H17.5013V23.0585H18.7454V21.7798Z" fill="#0096FF" />
      <path id="Vector_292" d="M18.7454 20.5037H17.5013V21.7799H18.7454V20.5037Z" fill="#0096FF" />
      <path id="Vector_293" d="M18.7454 19.2249H17.5013V20.5036H18.7454V19.2249Z" fill="#67C4FF" />
      <path id="Vector_294" d="M18.7454 17.9487H17.5013V19.2249H18.7454V17.9487Z" fill="#67C4FF" />
      <path id="Vector_295" d="M18.7454 16.6699H17.5013V17.9487H18.7454V16.6699Z" fill="#67C4FF" />
      <path id="Vector_296" d="M18.7454 15.3938H17.5013V16.67H18.7454V15.3938Z" fill="#67C4FF" />
      <path id="Vector_297" d="M18.7454 14.115H17.5013V15.3937H18.7454V14.115Z" fill="#67C4FF" />
      <path id="Vector_298" d="M18.7454 12.8389H17.5013V14.1151H18.7454V12.8389Z" fill="#67C4FF" />
      <path id="Vector_299" d="M18.7454 11.5601H17.5013V12.8388H18.7454V11.5601Z" fill="#C2EBFF" />
      <path id="Vector_300" d="M18.7454 10.2839H17.5013V11.5601H18.7454V10.2839Z" fill="#C2EBFF" />
      <path id="Vector_301" d="M18.7454 9.00513H17.5013V10.2839H18.7454V9.00513Z" fill="#C2EBFF" />
      <path id="Vector_302" d="M18.7454 7.729H17.5013V9.0052H18.7454V7.729Z" fill="#67C4FF" />
      <path id="Vector_303" d="M18.7454 6.4502H17.5013V7.72895H18.7454V6.4502Z" fill="#67C4FF" />
      <path id="Vector_304" d="M18.7454 5.17407H17.5013V6.45027H18.7454V5.17407Z" fill="#0096FF" />
      <path id="Vector_305" d="M18.7454 3.89526H17.5013V5.17402H18.7454V3.89526Z" fill="#0096FF" />
      <path id="Vector_306" d="M18.7454 2.6189H17.5013V3.89509H18.7454V2.6189Z" fill="#0096FF" />
      <path id="Vector_307" d="M18.7454 1.34033H17.5013V2.61909H18.7454V1.34033Z" fill="#0096FF" />
      <path
        id="Vector_308"
        d="M18.7454 0.0639648H17.5013V1.34016H18.7454V0.0639648Z"
        fill="black"
      />
      <path id="Vector_309" d="M17.5013 34.5571H16.2546V35.8333H17.5013V34.5571Z" fill="black" />
      <path id="Vector_310" d="M17.5013 33.2783H16.2546V34.5571H17.5013V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_311" d="M17.5013 32.0022H16.2546V33.2784H17.5013V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_312" d="M17.5013 30.7234H16.2546V32.0021H17.5013V30.7234Z" fill="#67C4FF" />
      <path id="Vector_313" d="M17.5013 29.4473H16.2546V30.7235H17.5013V29.4473Z" fill="#67C4FF" />
      <path id="Vector_314" d="M17.5013 28.1685H16.2546V29.4472H17.5013V28.1685Z" fill="black" />
      <path id="Vector_315" d="M17.5013 26.8896H16.2546V28.1684H17.5013V26.8896Z" fill="black" />
      <path id="Vector_316" d="M17.5013 25.6135H16.2546V26.8897H17.5013V25.6135Z" fill="black" />
      <path id="Vector_317" d="M17.5013 24.3347H16.2546V25.6135H17.5013V24.3347Z" fill="#6D6E71" />
      <path id="Vector_318" d="M17.5013 23.0586H16.2546V24.3348H17.5013V23.0586Z" fill="black" />
      <path id="Vector_319" d="M17.5013 21.7798H16.2546V23.0585H17.5013V21.7798Z" fill="#0096FF" />
      <path id="Vector_320" d="M17.5013 20.5037H16.2546V21.7799H17.5013V20.5037Z" fill="#0096FF" />
      <path id="Vector_321" d="M17.5013 19.2249H16.2546V20.5036H17.5013V19.2249Z" fill="#67C4FF" />
      <path id="Vector_322" d="M17.5013 17.9487H16.2546V19.2249H17.5013V17.9487Z" fill="#67C4FF" />
      <path id="Vector_323" d="M17.5013 16.6699H16.2546V17.9487H17.5013V16.6699Z" fill="black" />
      <path id="Vector_324" d="M17.5013 15.3938H16.2546V16.67H17.5013V15.3938Z" fill="black" />
      <path id="Vector_325" d="M17.5013 14.115H16.2546V15.3937H17.5013V14.115Z" fill="black" />
      <path id="Vector_326" d="M17.5013 12.8389H16.2546V14.1151H17.5013V12.8389Z" fill="#C2EBFF" />
      <path id="Vector_327" d="M17.5013 11.5601H16.2546V12.8388H17.5013V11.5601Z" fill="#C2EBFF" />
      <path id="Vector_328" d="M17.5013 10.2839H16.2546V11.5601H17.5013V10.2839Z" fill="#C2EBFF" />
      <path id="Vector_329" d="M17.5013 9.00513H16.2546V10.2839H17.5013V9.00513Z" fill="#67C4FF" />
      <path id="Vector_330" d="M17.5013 7.729H16.2546V9.0052H17.5013V7.729Z" fill="#67C4FF" />
      <path id="Vector_331" d="M17.5013 6.4502H16.2546V7.72895H17.5013V6.4502Z" fill="#67C4FF" />
      <path id="Vector_332" d="M17.5013 5.17407H16.2546V6.45027H17.5013V5.17407Z" fill="#0096FF" />
      <path id="Vector_333" d="M17.5013 3.89526H16.2546V5.17402H17.5013V3.89526Z" fill="#0096FF" />
      <path id="Vector_334" d="M17.5013 2.6189H16.2546V3.89509H17.5013V2.6189Z" fill="#0096FF" />
      <path id="Vector_335" d="M17.5013 1.34033H16.2546V2.61909H17.5013V1.34033Z" fill="#67C4FF" />
      <path
        id="Vector_336"
        d="M17.5013 0.0639648H16.2546V1.34016H17.5013V0.0639648Z"
        fill="black"
      />
      <path id="Vector_337" d="M16.2546 34.5571H15.0104V35.8333H16.2546V34.5571Z" fill="black" />
      <path id="Vector_338" d="M16.2546 33.2783H15.0104V34.5571H16.2546V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_339" d="M16.2546 32.0022H15.0104V33.2784H16.2546V32.0022Z" fill="#C2EBFF" />
      <path id="Vector_340" d="M16.2546 30.7234H15.0104V32.0021H16.2546V30.7234Z" fill="#67C4FF" />
      <path id="Vector_341" d="M16.2546 29.4473H15.0104V30.7235H16.2546V29.4473Z" fill="#67C4FF" />
      <path id="Vector_342" d="M16.2546 28.1685H15.0104V29.4472H16.2546V28.1685Z" fill="black" />
      <path id="Vector_343" d="M16.2546 26.8896H15.0104V28.1684H16.2546V26.8896Z" fill="black" />
      <path id="Vector_344" d="M16.2546 25.6135H15.0104V26.8897H16.2546V25.6135Z" fill="black" />
      <path id="Vector_345" d="M16.2546 24.3347H15.0104V25.6135H16.2546V24.3347Z" fill="#6D6E71" />
      <path id="Vector_346" d="M16.2546 23.0586H15.0104V24.3348H16.2546V23.0586Z" fill="black" />
      <path id="Vector_347" d="M16.2546 21.7798H15.0104V23.0585H16.2546V21.7798Z" fill="#0096FF" />
      <path id="Vector_348" d="M16.2546 20.5037H15.0104V21.7799H16.2546V20.5037Z" fill="#0096FF" />
      <path id="Vector_349" d="M16.2546 19.2249H15.0104V20.5036H16.2546V19.2249Z" fill="#67C4FF" />
      <path id="Vector_350" d="M16.2546 17.9487H15.0104V19.2249H16.2546V17.9487Z" fill="black" />
      <path id="Vector_351" d="M16.2546 16.6699H15.0104V17.9487H16.2546V16.6699Z" fill="black" />
      <path id="Vector_352" d="M16.2546 15.3938H15.0104V16.67H16.2546V15.3938Z" fill="#6D6E71" />
      <path id="Vector_353" d="M16.2546 14.115H15.0104V15.3937H16.2546V14.115Z" fill="black" />
      <path id="Vector_354" d="M16.2546 12.8389H15.0104V14.1151H16.2546V12.8389Z" fill="black" />
      <path id="Vector_355" d="M16.2546 11.5601H15.0104V12.8388H16.2546V11.5601Z" fill="#C2EBFF" />
      <path id="Vector_356" d="M16.2546 10.2839H15.0104V11.5601H16.2546V10.2839Z" fill="#C2EBFF" />
      <path id="Vector_357" d="M16.2546 9.00513H15.0104V10.2839H16.2546V9.00513Z" fill="#67C4FF" />
      <path id="Vector_358" d="M16.2546 7.729H15.0104V9.0052H16.2546V7.729Z" fill="#67C4FF" />
      <path id="Vector_359" d="M16.2546 6.4502H15.0104V7.72895H16.2546V6.4502Z" fill="#0096FF" />
      <path id="Vector_360" d="M16.2546 5.17407H15.0104V6.45027H16.2546V5.17407Z" fill="#0096FF" />
      <path id="Vector_361" d="M16.2546 3.89526H15.0104V5.17402H16.2546V3.89526Z" fill="#0096FF" />
      <path id="Vector_362" d="M16.2546 2.6189H15.0104V3.89509H16.2546V2.6189Z" fill="#67C4FF" />
      <path id="Vector_363" d="M16.2546 1.34033H15.0104V2.61909H16.2546V1.34033Z" fill="#67C4FF" />
      <path
        id="Vector_364"
        d="M16.2546 0.0639648H15.0104V1.34016H16.2546V0.0639648Z"
        fill="black"
      />
      <path id="Vector_365" d="M15.0104 34.5571H13.7637V35.8333H15.0104V34.5571Z" fill="black" />
      <path id="Vector_366" d="M15.0104 33.2783H13.7637V34.5571H15.0104V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_367" d="M15.0104 32.0022H13.7637V33.2784H15.0104V32.0022Z" fill="#67C4FF" />
      <path id="Vector_368" d="M15.0104 30.7234H13.7637V32.0021H15.0104V30.7234Z" fill="#67C4FF" />
      <path id="Vector_369" d="M15.0104 29.4473H13.7637V30.7235H15.0104V29.4473Z" fill="#67C4FF" />
      <path id="Vector_370" d="M15.0104 28.1685H13.7637V29.4472H15.0104V28.1685Z" fill="black" />
      <path id="Vector_371" d="M15.0104 26.8896H13.7637V28.1684H15.0104V26.8896Z" fill="black" />
      <path id="Vector_372" d="M15.0104 25.6135H13.7637V26.8897H15.0104V25.6135Z" fill="black" />
      <path id="Vector_373" d="M15.0104 24.3347H13.7637V25.6135H15.0104V24.3347Z" fill="#6D6E71" />
      <path id="Vector_374" d="M15.0104 23.0586H13.7637V24.3348H15.0104V23.0586Z" fill="black" />
      <path id="Vector_375" d="M15.0104 21.7798H13.7637V23.0585H15.0104V21.7798Z" fill="#0096FF" />
      <path id="Vector_376" d="M15.0104 20.5037H13.7637V21.7799H15.0104V20.5037Z" fill="#0096FF" />
      <path id="Vector_377" d="M15.0104 19.2249H13.7637V20.5036H15.0104V19.2249Z" fill="#67C4FF" />
      <path id="Vector_378" d="M15.0104 17.9487H13.7637V19.2249H15.0104V17.9487Z" fill="black" />
      <path id="Vector_379" d="M15.0104 16.6699H13.7637V17.9487H15.0104V16.6699Z" fill="black" />
      <path id="Vector_380" d="M15.0104 15.3938H13.7637V16.67H15.0104V15.3938Z" fill="#6D6E71" />
      <path id="Vector_381" d="M15.0104 14.115H13.7637V15.3937H15.0104V14.115Z" fill="#6D6E71" />
      <path id="Vector_382" d="M15.0104 12.8389H13.7637V14.1151H15.0104V12.8389Z" fill="black" />
      <path id="Vector_383" d="M15.0104 11.5601H13.7637V12.8388H15.0104V11.5601Z" fill="#C2EBFF" />
      <path id="Vector_384" d="M15.0104 10.2839H13.7637V11.5601H15.0104V10.2839Z" fill="#67C4FF" />
      <path id="Vector_385" d="M15.0104 9.00513H13.7637V10.2839H15.0104V9.00513Z" fill="#67C4FF" />
      <path id="Vector_386" d="M15.0104 7.729H13.7637V9.0052H15.0104V7.729Z" fill="#67C4FF" />
      <path id="Vector_387" d="M15.0104 6.4502H13.7637V7.72895H15.0104V6.4502Z" fill="#0096FF" />
      <path id="Vector_388" d="M15.0104 5.17407H13.7637V6.45027H15.0104V5.17407Z" fill="#0096FF" />
      <path id="Vector_389" d="M15.0104 3.89526H13.7637V5.17402H15.0104V3.89526Z" fill="#67C4FF" />
      <path id="Vector_390" d="M15.0104 2.6189H13.7637V3.89509H15.0104V2.6189Z" fill="#67C4FF" />
      <path id="Vector_391" d="M15.0104 1.34033H13.7637V2.61909H15.0104V1.34033Z" fill="#67C4FF" />
      <path
        id="Vector_392"
        d="M15.0104 0.0639648H13.7637V1.34016H15.0104V0.0639648Z"
        fill="black"
      />
      <path id="Vector_393" d="M13.7637 34.5571H12.5195V35.8333H13.7637V34.5571Z" fill="black" />
      <path id="Vector_394" d="M13.7637 33.2783H12.5195V34.5571H13.7637V33.2783Z" fill="#C2EBFF" />
      <path id="Vector_395" d="M13.7637 32.0022H12.5195V33.2784H13.7637V32.0022Z" fill="#67C4FF" />
      <path id="Vector_396" d="M13.7637 30.7234H12.5195V32.0021H13.7637V30.7234Z" fill="#67C4FF" />
      <path id="Vector_397" d="M13.7637 29.4473H12.5195V30.7235H13.7637V29.4473Z" fill="#67C4FF" />
      <path id="Vector_398" d="M13.7637 28.1685H12.5195V29.4472H13.7637V28.1685Z" fill="black" />
      <path id="Vector_399" d="M13.7637 26.8896H12.5195V28.1684H13.7637V26.8896Z" fill="black" />
      <path id="Vector_400" d="M13.7637 25.6135H12.5195V26.8897H13.7637V25.6135Z" fill="black" />
      <path id="Vector_401" d="M13.7637 24.3347H12.5195V25.6135H13.7637V24.3347Z" fill="#6D6E71" />
      <path id="Vector_402" d="M13.7637 23.0586H12.5195V24.3348H13.7637V23.0586Z" fill="black" />
      <path id="Vector_403" d="M13.7637 21.7798H12.5195V23.0585H13.7637V21.7798Z" fill="#0096FF" />
      <path id="Vector_404" d="M13.7637 20.5037H12.5195V21.7799H13.7637V20.5037Z" fill="#0096FF" />
      <path id="Vector_405" d="M13.7637 19.2249H12.5195V20.5036H13.7637V19.2249Z" fill="#67C4FF" />
      <path id="Vector_406" d="M13.7637 17.9487H12.5195V19.2249H13.7637V17.9487Z" fill="black" />
      <path id="Vector_407" d="M13.7637 16.6699H12.5195V17.9487H13.7637V16.6699Z" fill="black" />
      <path id="Vector_408" d="M13.7637 15.3938H12.5195V16.67H13.7637V15.3938Z" fill="#6D6E71" />
      <path id="Vector_409" d="M13.7637 14.115H12.5195V15.3937H13.7637V14.115Z" fill="#6D6E71" />
      <path id="Vector_410" d="M13.7637 12.8389H12.5195V14.1151H13.7637V12.8389Z" fill="black" />
      <path id="Vector_411" d="M13.7637 11.5601H12.5195V12.8388H13.7637V11.5601Z" fill="#67C4FF" />
      <path id="Vector_412" d="M13.7637 10.2839H12.5195V11.5601H13.7637V10.2839Z" fill="#67C4FF" />
      <path id="Vector_413" d="M13.7637 9.00513H12.5195V10.2839H13.7637V9.00513Z" fill="#67C4FF" />
      <path id="Vector_414" d="M13.7637 7.729H12.5195V9.0052H13.7637V7.729Z" fill="#0096FF" />
      <path id="Vector_415" d="M13.7637 6.4502H12.5195V7.72895H13.7637V6.4502Z" fill="#0096FF" />
      <path id="Vector_416" d="M13.7637 5.17407H12.5195V6.45027H13.7637V5.17407Z" fill="#0096FF" />
      <path id="Vector_417" d="M13.7637 3.89526H12.5195V5.17402H13.7637V3.89526Z" fill="#67C4FF" />
      <path id="Vector_418" d="M13.7637 2.6189H12.5195V3.89509H13.7637V2.6189Z" fill="#67C4FF" />
      <path id="Vector_419" d="M13.7637 1.34033H12.5195V2.61909H13.7637V1.34033Z" fill="#67C4FF" />
      <path
        id="Vector_420"
        d="M13.7637 0.0639648H12.5195V1.34016H13.7637V0.0639648Z"
        fill="black"
      />
      <path id="Vector_421" d="M12.5195 33.2783H11.2728V34.5571H12.5195V33.2783Z" fill="black" />
      <path id="Vector_422" d="M12.5195 32.0022H11.2728V33.2784H12.5195V32.0022Z" fill="#67C4FF" />
      <path id="Vector_423" d="M12.5195 30.7234H11.2728V32.0021H12.5195V30.7234Z" fill="#67C4FF" />
      <path id="Vector_424" d="M12.5195 29.4473H11.2728V30.7235H12.5195V29.4473Z" fill="#67C4FF" />
      <path id="Vector_425" d="M12.5195 28.1685H11.2728V29.4472H12.5195V28.1685Z" fill="black" />
      <path id="Vector_426" d="M12.5195 26.8896H11.2728V28.1684H12.5195V26.8896Z" fill="black" />
      <path id="Vector_427" d="M12.5195 25.6135H11.2728V26.8897H12.5195V25.6135Z" fill="black" />
      <path id="Vector_428" d="M12.5195 24.3347H11.2728V25.6135H12.5195V24.3347Z" fill="#6D6E71" />
      <path id="Vector_429" d="M12.5195 23.0586H11.2728V24.3348H12.5195V23.0586Z" fill="black" />
      <path id="Vector_430" d="M12.5195 21.7798H11.2728V23.0585H12.5195V21.7798Z" fill="#0096FF" />
      <path id="Vector_431" d="M12.5195 20.5037H11.2728V21.7799H12.5195V20.5037Z" fill="#0096FF" />
      <path id="Vector_432" d="M12.5195 19.2249H11.2728V20.5036H12.5195V19.2249Z" fill="#67C4FF" />
      <path id="Vector_433" d="M12.5195 17.9487H11.2728V19.2249H12.5195V17.9487Z" fill="black" />
      <path id="Vector_434" d="M12.5195 16.6699H11.2728V17.9487H12.5195V16.6699Z" fill="black" />
      <path id="Vector_435" d="M12.5195 15.3938H11.2728V16.67H12.5195V15.3938Z" fill="#6D6E71" />
      <path id="Vector_436" d="M12.5195 14.115H11.2728V15.3937H12.5195V14.115Z" fill="#6D6E71" />
      <path id="Vector_437" d="M12.5195 12.8389H11.2728V14.1151H12.5195V12.8389Z" fill="black" />
      <path id="Vector_438" d="M12.5195 11.5601H11.2728V12.8388H12.5195V11.5601Z" fill="#67C4FF" />
      <path id="Vector_439" d="M12.5195 10.2839H11.2728V11.5601H12.5195V10.2839Z" fill="#67C4FF" />
      <path id="Vector_440" d="M12.5195 9.00513H11.2728V10.2839H12.5195V9.00513Z" fill="#67C4FF" />
      <path id="Vector_441" d="M12.5195 7.729H11.2728V9.0052H12.5195V7.729Z" fill="#0096FF" />
      <path id="Vector_442" d="M12.5195 6.4502H11.2728V7.72895H12.5195V6.4502Z" fill="#0096FF" />
      <path id="Vector_443" d="M12.5195 5.17407H11.2728V6.45027H12.5195V5.17407Z" fill="#67C4FF" />
      <path id="Vector_444" d="M12.5195 3.89526H11.2728V5.17402H12.5195V3.89526Z" fill="#67C4FF" />
      <path id="Vector_445" d="M12.5195 2.6189H11.2728V3.89509H12.5195V2.6189Z" fill="#67C4FF" />
      <path id="Vector_446" d="M12.5195 1.34033H11.2728V2.61909H12.5195V1.34033Z" fill="black" />
      <path id="Vector_447" d="M11.2728 33.2783H10.0261V34.5571H11.2728V33.2783Z" fill="black" />
      <path id="Vector_448" d="M11.2728 32.0022H10.0261V33.2784H11.2728V32.0022Z" fill="#67C4FF" />
      <path id="Vector_449" d="M11.2728 30.7234H10.0261V32.0021H11.2728V30.7234Z" fill="#67C4FF" />
      <path id="Vector_450" d="M11.2728 29.4473H10.0261V30.7235H11.2728V29.4473Z" fill="#67C4FF" />
      <path id="Vector_451" d="M11.2728 28.1685H10.0261V29.4472H11.2728V28.1685Z" fill="#0096FF" />
      <path id="Vector_452" d="M11.2728 26.8896H10.0261V28.1684H11.2728V26.8896Z" fill="black" />
      <path id="Vector_453" d="M11.2728 25.6135H10.0261V26.8897H11.2728V25.6135Z" fill="black" />
      <path id="Vector_454" d="M11.2728 24.3347H10.0261V25.6135H11.2728V24.3347Z" fill="black" />
      <path id="Vector_455" d="M11.2728 23.0586H10.0261V24.3348H11.2728V23.0586Z" fill="#0096FF" />
      <path id="Vector_456" d="M11.2728 21.7798H10.0261V23.0585H11.2728V21.7798Z" fill="#0096FF" />
      <path id="Vector_457" d="M11.2728 20.5037H10.0261V21.7799H11.2728V20.5037Z" fill="#67C4FF" />
      <path id="Vector_458" d="M11.2728 19.2249H10.0261V20.5036H11.2728V19.2249Z" fill="#67C4FF" />
      <path id="Vector_459" d="M11.2728 17.9487H10.0261V19.2249H11.2728V17.9487Z" fill="black" />
      <path id="Vector_460" d="M11.2728 16.6699H10.0261V17.9487H11.2728V16.6699Z" fill="black" />
      <path id="Vector_461" d="M11.2728 15.3938H10.0261V16.67H11.2728V15.3938Z" fill="#6D6E71" />
      <path id="Vector_462" d="M11.2728 14.115H10.0261V15.3937H11.2728V14.115Z" fill="#6D6E71" />
      <path id="Vector_463" d="M11.2728 12.8389H10.0261V14.1151H11.2728V12.8389Z" fill="black" />
      <path id="Vector_464" d="M11.2728 11.5601H10.0261V12.8388H11.2728V11.5601Z" fill="#67C4FF" />
      <path id="Vector_465" d="M11.2728 10.2839H10.0261V11.5601H11.2728V10.2839Z" fill="#67C4FF" />
      <path id="Vector_466" d="M11.2728 9.00513H10.0261V10.2839H11.2728V9.00513Z" fill="#0096FF" />
      <path id="Vector_467" d="M11.2728 7.729H10.0261V9.0052H11.2728V7.729Z" fill="#0096FF" />
      <path id="Vector_468" d="M11.2728 6.4502H10.0261V7.72895H11.2728V6.4502Z" fill="#67C4FF" />
      <path id="Vector_469" d="M11.2728 5.17407H10.0261V6.45027H11.2728V5.17407Z" fill="#67C4FF" />
      <path id="Vector_470" d="M11.2728 3.89526H10.0261V5.17402H11.2728V3.89526Z" fill="#67C4FF" />
      <path id="Vector_471" d="M11.2728 2.6189H10.0261V3.89509H11.2728V2.6189Z" fill="#67C4FF" />
      <path id="Vector_472" d="M11.2728 1.34033H10.0261V2.61909H11.2728V1.34033Z" fill="black" />
      <path id="Vector_473" d="M10.0261 32.0022H8.78192V33.2784H10.0261V32.0022Z" fill="black" />
      <path id="Vector_474" d="M10.0261 30.7234H8.78192V32.0021H10.0261V30.7234Z" fill="#67C4FF" />
      <path id="Vector_475" d="M10.0261 29.4473H8.78192V30.7235H10.0261V29.4473Z" fill="#0096FF" />
      <path id="Vector_476" d="M10.0261 28.1685H8.78192V29.4472H10.0261V28.1685Z" fill="#0096FF" />
      <path id="Vector_477" d="M10.0261 26.8896H8.78192V28.1684H10.0261V26.8896Z" fill="#0096FF" />
      <path id="Vector_478" d="M10.0261 25.6135H8.78192V26.8897H10.0261V25.6135Z" fill="#0096FF" />
      <path id="Vector_479" d="M10.0261 24.3347H8.78192V25.6135H10.0261V24.3347Z" fill="#0096FF" />
      <path id="Vector_480" d="M10.0261 23.0586H8.78192V24.3348H10.0261V23.0586Z" fill="#0096FF" />
      <path id="Vector_481" d="M10.0261 21.7798H8.78192V23.0585H10.0261V21.7798Z" fill="#0096FF" />
      <path id="Vector_482" d="M10.0261 20.5037H8.78192V21.7799H10.0261V20.5037Z" fill="#67C4FF" />
      <path id="Vector_483" d="M10.0261 19.2249H8.78192V20.5036H10.0261V19.2249Z" fill="#67C4FF" />
      <path id="Vector_484" d="M10.0261 17.9487H8.78192V19.2249H10.0261V17.9487Z" fill="black" />
      <path id="Vector_485" d="M10.0261 16.6699H8.78192V17.9487H10.0261V16.6699Z" fill="black" />
      <path id="Vector_486" d="M10.0261 15.3938H8.78192V16.67H10.0261V15.3938Z" fill="#6D6E71" />
      <path id="Vector_487" d="M10.0261 14.115H8.78192V15.3937H10.0261V14.115Z" fill="black" />
      <path id="Vector_488" d="M10.0261 12.8389H8.78192V14.1151H10.0261V12.8389Z" fill="black" />
      <path id="Vector_489" d="M10.0261 11.5601H8.78192V12.8388H10.0261V11.5601Z" fill="#67C4FF" />
      <path id="Vector_490" d="M10.0261 10.2839H8.78192V11.5601H10.0261V10.2839Z" fill="#67C4FF" />
      <path id="Vector_491" d="M10.0261 9.00513H8.78192V10.2839H10.0261V9.00513Z" fill="#0096FF" />
      <path id="Vector_492" d="M10.0261 7.729H8.78192V9.0052H10.0261V7.729Z" fill="#0096FF" />
      <path id="Vector_493" d="M10.0261 6.4502H8.78192V7.72895H10.0261V6.4502Z" fill="#67C4FF" />
      <path id="Vector_494" d="M10.0261 5.17407H8.78192V6.45027H10.0261V5.17407Z" fill="#67C4FF" />
      <path id="Vector_495" d="M10.0261 3.89526H8.78192V5.17402H10.0261V3.89526Z" fill="#67C4FF" />
      <path id="Vector_496" d="M10.0261 2.6189H8.78192V3.89509H10.0261V2.6189Z" fill="black" />
      <path id="Vector_497" d="M8.78192 32.0022H7.53522V33.2784H8.78192V32.0022Z" fill="black" />
      <path id="Vector_498" d="M8.78192 30.7234H7.53522V32.0021H8.78192V30.7234Z" fill="#67C4FF" />
      <path id="Vector_499" d="M8.78192 29.4473H7.53522V30.7235H8.78192V29.4473Z" fill="#0096FF" />
      <path id="Vector_500" d="M8.78192 28.1685H7.53522V29.4472H8.78192V28.1685Z" fill="#0096FF" />
      <path id="Vector_501" d="M8.78192 26.8896H7.53522V28.1684H8.78192V26.8896Z" fill="#0096FF" />
      <path id="Vector_502" d="M8.78192 25.6135H7.53522V26.8897H8.78192V25.6135Z" fill="#67C4FF" />
      <path id="Vector_503" d="M8.78192 24.3347H7.53522V25.6135H8.78192V24.3347Z" fill="#0096FF" />
      <path id="Vector_504" d="M8.78192 23.0586H7.53522V24.3348H8.78192V23.0586Z" fill="#0096FF" />
      <path id="Vector_505" d="M8.78192 21.7798H7.53522V23.0585H8.78192V21.7798Z" fill="#0096FF" />
      <path id="Vector_506" d="M8.78192 20.5037H7.53522V21.7799H8.78192V20.5037Z" fill="#67C4FF" />
      <path id="Vector_507" d="M8.78192 19.2249H7.53522V20.5036H8.78192V19.2249Z" fill="#67C4FF" />
      <path id="Vector_508" d="M8.78192 17.9487H7.53522V19.2249H8.78192V17.9487Z" fill="#C2EBFF" />
      <path id="Vector_509" d="M8.78192 16.6699H7.53522V17.9487H8.78192V16.6699Z" fill="black" />
      <path id="Vector_510" d="M8.78192 15.3938H7.53522V16.67H8.78192V15.3938Z" fill="black" />
      <path id="Vector_511" d="M8.78192 14.115H7.53522V15.3937H8.78192V14.115Z" fill="black" />
      <path id="Vector_512" d="M8.78192 12.8389H7.53522V14.1151H8.78192V12.8389Z" fill="#67C4FF" />
      <path id="Vector_513" d="M8.78192 11.5601H7.53522V12.8388H8.78192V11.5601Z" fill="#67C4FF" />
      <path id="Vector_514" d="M8.78192 10.2839H7.53522V11.5601H8.78192V10.2839Z" fill="#0096FF" />
      <path id="Vector_515" d="M8.78192 9.00513H7.53522V10.2839H8.78192V9.00513Z" fill="#0096FF" />
      <path id="Vector_516" d="M8.78192 7.729H7.53522V9.0052H8.78192V7.729Z" fill="#0096FF" />
      <path id="Vector_517" d="M8.78192 6.4502H7.53522V7.72895H8.78192V6.4502Z" fill="#67C4FF" />
      <path id="Vector_518" d="M8.78192 5.17407H7.53522V6.45027H8.78192V5.17407Z" fill="#67C4FF" />
      <path id="Vector_519" d="M8.78192 3.89526H7.53522V5.17402H8.78192V3.89526Z" fill="#67C4FF" />
      <path id="Vector_520" d="M8.78192 2.6189H7.53522V3.89509H8.78192V2.6189Z" fill="black" />
      <path id="Vector_521" d="M7.53523 30.7234H6.29104V32.0021H7.53523V30.7234Z" fill="black" />
      <path id="Vector_522" d="M7.53523 29.4473H6.29104V30.7235H7.53523V29.4473Z" fill="#0096FF" />
      <path id="Vector_523" d="M7.53523 28.1685H6.29104V29.4472H7.53523V28.1685Z" fill="#0096FF" />
      <path id="Vector_524" d="M7.53523 26.8896H6.29104V28.1684H7.53523V26.8896Z" fill="#67C4FF" />
      <path id="Vector_525" d="M7.53523 25.6135H6.29104V26.8897H7.53523V25.6135Z" fill="#67C4FF" />
      <path id="Vector_526" d="M7.53523 24.3347H6.29104V25.6135H7.53523V24.3347Z" fill="#0096FF" />
      <path id="Vector_527" d="M7.53523 23.0586H6.29104V24.3348H7.53523V23.0586Z" fill="#0096FF" />
      <path id="Vector_528" d="M7.53523 21.7798H6.29104V23.0585H7.53523V21.7798Z" fill="#0096FF" />
      <path id="Vector_529" d="M7.53523 20.5037H6.29104V21.7799H7.53523V20.5037Z" fill="#67C4FF" />
      <path id="Vector_530" d="M7.53523 19.2249H6.29104V20.5036H7.53523V19.2249Z" fill="#67C4FF" />
      <path id="Vector_531" d="M7.53523 17.9487H6.29104V19.2249H7.53523V17.9487Z" fill="#C2EBFF" />
      <path id="Vector_532" d="M7.53523 16.6699H6.29104V17.9487H7.53523V16.6699Z" fill="#C2EBFF" />
      <path id="Vector_533" d="M7.53523 15.3938H6.29104V16.67H7.53523V15.3938Z" fill="#67C4FF" />
      <path id="Vector_534" d="M7.53523 14.115H6.29104V15.3937H7.53523V14.115Z" fill="#67C4FF" />
      <path id="Vector_535" d="M7.53523 12.8389H6.29104V14.1151H7.53523V12.8389Z" fill="#67C4FF" />
      <path id="Vector_536" d="M7.53523 11.5601H6.29104V12.8388H7.53523V11.5601Z" fill="#67C4FF" />
      <path id="Vector_537" d="M7.53523 10.2839H6.29104V11.5601H7.53523V10.2839Z" fill="#0096FF" />
      <path id="Vector_538" d="M7.53523 9.00513H6.29104V10.2839H7.53523V9.00513Z" fill="#0096FF" />
      <path id="Vector_539" d="M7.53523 7.729H6.29104V9.0052H7.53523V7.729Z" fill="#67C4FF" />
      <path id="Vector_540" d="M7.53523 6.4502H6.29104V7.72895H7.53523V6.4502Z" fill="#67C4FF" />
      <path id="Vector_541" d="M7.53523 5.17407H6.29104V6.45027H7.53523V5.17407Z" fill="#67C4FF" />
      <path id="Vector_542" d="M7.53523 3.89526H6.29104V5.17402H7.53523V3.89526Z" fill="black" />
      <path id="Vector_543" d="M6.29103 29.4473H5.04434V30.7235H6.29103V29.4473Z" fill="black" />
      <path id="Vector_544" d="M6.29103 28.1685H5.04434V29.4472H6.29103V28.1685Z" fill="#67C4FF" />
      <path id="Vector_545" d="M6.29103 26.8896H5.04434V28.1684H6.29103V26.8896Z" fill="#67C4FF" />
      <path id="Vector_546" d="M6.29103 25.6135H5.04434V26.8897H6.29103V25.6135Z" fill="#67C4FF" />
      <path id="Vector_547" d="M6.29103 24.3347H5.04434V25.6135H6.29103V24.3347Z" fill="#0096FF" />
      <path id="Vector_548" d="M6.29103 23.0586H5.04434V24.3348H6.29103V23.0586Z" fill="#0096FF" />
      <path id="Vector_549" d="M6.29103 21.7798H5.04434V23.0585H6.29103V21.7798Z" fill="#67C4FF" />
      <path id="Vector_550" d="M6.29103 20.5037H5.04434V21.7799H6.29103V20.5037Z" fill="#67C4FF" />
      <path id="Vector_551" d="M6.29103 19.2249H5.04434V20.5036H6.29103V19.2249Z" fill="#67C4FF" />
      <path id="Vector_552" d="M6.29103 17.9487H5.04434V19.2249H6.29103V17.9487Z" fill="#C2EBFF" />
      <path id="Vector_553" d="M6.29103 16.6699H5.04434V17.9487H6.29103V16.6699Z" fill="#C2EBFF" />
      <path id="Vector_554" d="M6.29103 15.3938H5.04434V16.67H6.29103V15.3938Z" fill="#67C4FF" />
      <path id="Vector_555" d="M6.29103 14.115H5.04434V15.3937H6.29103V14.115Z" fill="#67C4FF" />
      <path id="Vector_556" d="M6.29103 12.8389H5.04434V14.1151H6.29103V12.8389Z" fill="#67C4FF" />
      <path id="Vector_557" d="M6.29103 11.5601H5.04434V12.8388H6.29103V11.5601Z" fill="#0096FF" />
      <path id="Vector_558" d="M6.29103 10.2839H5.04434V11.5601H6.29103V10.2839Z" fill="#0096FF" />
      <path id="Vector_559" d="M6.29103 9.00513H5.04434V10.2839H6.29103V9.00513Z" fill="#67C4FF" />
      <path id="Vector_560" d="M6.29103 7.729H5.04434V9.0052H6.29103V7.729Z" fill="#67C4FF" />
      <path id="Vector_561" d="M6.29103 6.4502H5.04434V7.72895H6.29103V6.4502Z" fill="#67C4FF" />
      <path id="Vector_562" d="M6.29103 5.17407H5.04434V6.45027H6.29103V5.17407Z" fill="black" />
      <path id="Vector_563" d="M5.04434 28.1685H3.80016V29.4472H5.04434V28.1685Z" fill="black" />
      <path id="Vector_564" d="M5.04434 26.8896H3.80016V28.1684H5.04434V26.8896Z" fill="#67C4FF" />
      <path id="Vector_565" d="M5.04434 25.6135H3.80016V26.8897H5.04434V25.6135Z" fill="#67C4FF" />
      <path id="Vector_566" d="M5.04434 24.3347H3.80016V25.6135H5.04434V24.3347Z" fill="#0096FF" />
      <path id="Vector_567" d="M5.04434 23.0586H3.80016V24.3348H5.04434V23.0586Z" fill="#0096FF" />
      <path id="Vector_568" d="M5.04434 21.7798H3.80016V23.0585H5.04434V21.7798Z" fill="#67C4FF" />
      <path id="Vector_569" d="M5.04434 20.5037H3.80016V21.7799H5.04434V20.5037Z" fill="#67C4FF" />
      <path id="Vector_570" d="M5.04434 19.2249H3.80016V20.5036H5.04434V19.2249Z" fill="#C2EBFF" />
      <path id="Vector_571" d="M5.04434 17.9487H3.80016V19.2249H5.04434V17.9487Z" fill="#C2EBFF" />
      <path id="Vector_572" d="M5.04434 16.6699H3.80016V17.9487H5.04434V16.6699Z" fill="#67C4FF" />
      <path id="Vector_573" d="M5.04434 15.3938H3.80016V16.67H5.04434V15.3938Z" fill="#67C4FF" />
      <path id="Vector_574" d="M5.04434 14.115H3.80016V15.3937H5.04434V14.115Z" fill="#67C4FF" />
      <path id="Vector_575" d="M5.04434 12.8389H3.80016V14.1151H5.04434V12.8389Z" fill="#67C4FF" />
      <path id="Vector_576" d="M5.04434 11.5601H3.80016V12.8388H5.04434V11.5601Z" fill="#0096FF" />
      <path id="Vector_577" d="M5.04434 10.2839H3.80016V11.5601H5.04434V10.2839Z" fill="#0096FF" />
      <path id="Vector_578" d="M5.04434 9.00513H3.80016V10.2839H5.04434V9.00513Z" fill="#67C4FF" />
      <path id="Vector_579" d="M5.04434 7.729H3.80016V9.0052H5.04434V7.729Z" fill="#67C4FF" />
      <path id="Vector_580" d="M5.04434 6.4502H3.80016V7.72895H5.04434V6.4502Z" fill="black" />
      <path id="Vector_581" d="M3.80016 26.8896H2.55347V28.1684H3.80016V26.8896Z" fill="black" />
      <path id="Vector_582" d="M3.80016 25.6135H2.55347V26.8897H3.80016V25.6135Z" fill="black" />
      <path id="Vector_583" d="M3.80016 24.3347H2.55347V25.6135H3.80016V24.3347Z" fill="#0096FF" />
      <path id="Vector_584" d="M3.80016 23.0586H2.55347V24.3348H3.80016V23.0586Z" fill="#0096FF" />
      <path id="Vector_585" d="M3.80016 21.7798H2.55347V23.0585H3.80016V21.7798Z" fill="#67C4FF" />
      <path id="Vector_586" d="M3.80016 20.5037H2.55347V21.7799H3.80016V20.5037Z" fill="#C2EBFF" />
      <path id="Vector_587" d="M3.80016 19.2249H2.55347V20.5036H3.80016V19.2249Z" fill="#C2EBFF" />
      <path id="Vector_588" d="M3.80016 17.9487H2.55347V19.2249H3.80016V17.9487Z" fill="#C2EBFF" />
      <path id="Vector_589" d="M3.80016 16.6699H2.55347V17.9487H3.80016V16.6699Z" fill="#67C4FF" />
      <path id="Vector_590" d="M3.80016 15.3938H2.55347V16.67H3.80016V15.3938Z" fill="#67C4FF" />
      <path id="Vector_591" d="M3.80016 14.115H2.55347V15.3937H3.80016V14.115Z" fill="#67C4FF" />
      <path id="Vector_592" d="M3.80016 12.8389H2.55347V14.1151H3.80016V12.8389Z" fill="#0096FF" />
      <path id="Vector_593" d="M3.80016 11.5601H2.55347V12.8388H3.80016V11.5601Z" fill="#0096FF" />
      <path id="Vector_594" d="M3.80016 10.2839H2.55347V11.5601H3.80016V10.2839Z" fill="#67C4FF" />
      <path id="Vector_595" d="M3.80016 9.00513H2.55347V10.2839H3.80016V9.00513Z" fill="black" />
      <path id="Vector_596" d="M3.80016 7.729H2.55347V9.0052H3.80016V7.729Z" fill="black" />
      <path id="Vector_597" d="M2.55347 24.3347H1.30928V25.6135H2.55347V24.3347Z" fill="black" />
      <path id="Vector_598" d="M2.55347 23.0586H1.30928V24.3348H2.55347V23.0586Z" fill="black" />
      <path id="Vector_599" d="M2.55347 21.7798H1.30928V23.0585H2.55347V21.7798Z" fill="#67C4FF" />
      <path id="Vector_600" d="M2.55347 20.5037H1.30928V21.7799H2.55347V20.5037Z" fill="#C2EBFF" />
      <path id="Vector_601" d="M2.55347 19.2249H1.30928V20.5036H2.55347V19.2249Z" fill="#C2EBFF" />
      <path id="Vector_602" d="M2.55347 17.9487H1.30928V19.2249H2.55347V17.9487Z" fill="#67C4FF" />
      <path id="Vector_603" d="M2.55347 16.6699H1.30928V17.9487H2.55347V16.6699Z" fill="#67C4FF" />
      <path id="Vector_604" d="M2.55347 15.3938H1.30928V16.67H2.55347V15.3938Z" fill="#67C4FF" />
      <path id="Vector_605" d="M2.55347 14.115H1.30928V15.3937H2.55347V14.115Z" fill="#67C4FF" />
      <path id="Vector_606" d="M2.55347 12.8389H1.30928V14.1151H2.55347V12.8389Z" fill="#0096FF" />
      <path id="Vector_607" d="M2.55347 11.5601H1.30928V12.8388H2.55347V11.5601Z" fill="black" />
      <path id="Vector_608" d="M2.55347 10.2839H1.30928V11.5601H2.55347V10.2839Z" fill="black" />
      <path id="Vector_609" d="M1.30928 21.7798H0.0625839V23.0585H1.30928V21.7798Z" fill="black" />
      <path id="Vector_610" d="M1.30928 20.5037H0.0625839V21.7799H1.30928V20.5037Z" fill="black" />
      <path id="Vector_611" d="M1.30928 19.2249H0.0625839V20.5036H1.30928V19.2249Z" fill="black" />
      <path id="Vector_612" d="M1.30928 17.9487H0.0625839V19.2249H1.30928V17.9487Z" fill="black" />
      <path id="Vector_613" d="M1.30928 16.6699H0.0625839V17.9487H1.30928V16.6699Z" fill="black" />
      <path id="Vector_614" d="M1.30928 15.3938H0.0625839V16.67H1.30928V15.3938Z" fill="black" />
      <path id="Vector_615" d="M1.30928 14.115H0.0625839V15.3937H1.30928V14.115Z" fill="black" />
      <path id="Vector_616" d="M1.30928 12.8389H0.0625839V14.1151H1.30928V12.8389Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_209_33659">
        <rect width="35" height="35.8974" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HockeyIcon;
