import OneIcon from "../icons/CircleIcon";

const ListNumber = ({ numberToDisplay }: { numberToDisplay: number }) => {
  return (
    <div className="relative">
      <OneIcon />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        {numberToDisplay}
      </div>
    </div>
  );
};

export default ListNumber;
