import { useState } from "react";
import SmallArrow from "../../icons/SmallArrow";

enum SORT_OPTIONS {
  WEEKLY = "Weekly",
  // leave out until implemented
  // MONTHLY = "Monthly",
  // ALL_TIME = "All Time"
}

const RankingsDropdown = () => {
  const [selectedOption, setSelectedOption] = useState(SORT_OPTIONS.WEEKLY);
  const [isSelecting, setIsSelecting] = useState(false);

  const selectOption = (option: SORT_OPTIONS) => {
    setSelectedOption(option);
    setIsSelecting(false);
  };

  return (
    <div className="flex flex-col text-white text-sm relative items-center">
      <div className="h-[2px] w-[138px] bg-white"/>
      <div className="flex items-center">
        <div className="w-[2px] h-[24px] bg-white"/>
          <button
            id="cars"
            name="rankings"
            className="appearance-none bg-transparent p-2 w-[140px] h-[26px] flex justify-between items-center"
            onClick={() => setIsSelecting(!isSelecting)}
          >
            <span>{selectedOption}</span>
            <SmallArrow />
          </button>
          <div className="w-[2px] h-[24px] bg-white"/>
        </div>
      <div className="h-[2px] w-[138px] bg-white"/>
      {isSelecting && (
        <div className="absolute top-7 flex flex-col w-full border-2 border-white">
          {Object.values(SORT_OPTIONS).map((option, index) => (
            <button key={option} onClick={() => selectOption(option)} className={`text-center w-full bg-black bg-opacity-60 ${index > 0 && "border-t-2 border-white"}`}>
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default RankingsDropdown;
