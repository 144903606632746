const Close = ({ className = "", fill = "black" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    className={className}
  >
    <g opacity="0.5">
      <rect width="2" height="2" fill={fill} />
      <rect x="2" y="2" width="2" height="2" fill={fill} />
      <rect x="4" y="4" width="2" height="2" fill={fill} />
      <rect x="6" y="2" width="2" height="2" fill={fill} />
      <rect x="6" y="6" width="2" height="2" fill={fill} />
      <rect x="2" y="6" width="2" height="2" fill={fill} />
      <rect y="8" width="2" height="2" fill={fill} />
      <rect x="8" y="8" width="2" height="2" fill={fill} />
      <rect x="8" width="2" height="2" fill={fill} />
    </g>
  </svg>
);

export default Close;
