const IconProfileBorder = ({ className = "" }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="70" 
    height="72" 
    viewBox="0 0 70 72" 
    fill="none"
    className={className}
  >
    <rect x="66.8072" y="23.04" width="2.89157" height="23.04" fill="black"/>
    <rect x="0.301208" y="23.04" width="2.89157" height="23.04" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 63.9156 20.16)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 63.9156 23.04)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 3.19278 23.04)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 3.19278 51.84)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 63.9156 51.84)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 61.0241 17.28)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 58.1326 11.52)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 52.3494 8.63998)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 11.8675 8.63998)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 8.97591 11.52)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 6.08433 17.28)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 3.19278 54.72)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 6.08433 60.48)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 6.08433 57.6)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 0.301208 48.96)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 8.97591 60.48)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 8.97591 63.36)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 11.8675 63.36)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 11.8675 66.24)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 52.3494 63.36)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 52.3494 66.24)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 58.1326 60.48)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 58.1326 63.36)" fill="black"/>
    <rect width="2.89157" height="5.76" transform="matrix(1 0 0 -1 61.0241 57.6)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 63.9156 54.72)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 66.8072 48.96)" fill="black"/>
    <rect width="2.89157" height="2.88" transform="matrix(1 0 0 -1 61.0241 60.48)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 46.5663 5.76001)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 17.6506 5.76001)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 17.6506 66.24)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 17.6506 69.12)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 46.5663 66.24)" fill="black"/>
    <rect width="5.78313" height="2.88" transform="matrix(1 0 0 -1 46.5663 69.12)" fill="black"/>
    <rect width="23.1325" height="2.88" transform="matrix(1 0 0 -1 23.4337 2.88)" fill="black"/>
    <rect width="23.1325" height="2.88" transform="matrix(1 0 0 -1 23.4337 69.12)" fill="black"/>
    <rect width="23.1325" height="2.88" transform="matrix(1 0 0 -1 23.4337 72)" fill="black"/>
  </svg>
);

export default IconProfileBorder;
