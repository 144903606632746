"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
import { retrieveLaunchParams, init } from "@telegram-apps/sdk";
import { postRequest, saveTelegramInitData } from "../utils/requests";
import useStore from "../lib/store";
import { datadogRum } from "@datadog/browser-rum";
import { InitData } from "../types/user";
import { setGaUser } from "../hooks/ga4/utils";

interface AuthContextProps {
  isLoading: boolean
  isInitTelegram: boolean
  isClaimingDailyReward: boolean
  dailyRewardTickets: number
  setIsClaimingDailyReward: (claiming: boolean) => void
  error: string | null
  isShowingAuthExpiredModal: boolean
}

const AuthContext = createContext<AuthContextProps>({
  isLoading: true,
  isInitTelegram: true,
  isClaimingDailyReward: false,
  dailyRewardTickets: 0,
  setIsClaimingDailyReward: () => {},
  error: null,
  isShowingAuthExpiredModal: false
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isInitTelegram, setIsInitTelegram] = useState(true);
  const [isClaimingDailyReward, setIsClaimingDailyReward] = useState(false);
  const [dailyRewardTickets, setDailyRewardTickets] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [initDataRawTelegram, setInitDataRawTelegram] = useState<string>();
  const [initDataTelegram, setInitDataTelegram] = useState<InitData>();
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [isShowingAuthExpiredModal, setIsShowingAuthExpiredModal] = useState(false);
  const [authDate, setAuthDate] = useState<Date | null>(null);

  const user = useStore(state => state.user);
  const setUser = useStore(state => state.setUser);
  const setNextDailyReward = useStore(state => state.setNextDailyReward);
  const setSendAppStart = useStore(state => state.setSendAppStart);
  const sendAppStart = useStore(state => state.sendAppStart);
  const setStreak = useStore(state => state.setStreak);

  const checkIfAuthDateIsExpired = (authDate: Date) => {
    const diff = Date.now() - authDate.getTime();
    return diff >= 1000 * 60 * 60 * 24; // 24 hours
  };

  useEffect(() => {
    try {
      const { initDataRaw, initData, startParam } = retrieveLaunchParams();
      if (initData?.authDate) {
        const { authDate } = initData;
        if (checkIfAuthDateIsExpired(authDate)) {
          setIsShowingAuthExpiredModal(true);
          setIsLoading(false);
          return;
        } else {
          setAuthDate(authDate);
        }
      }
      setInitDataTelegram(initData as InitData);
      setInitDataRawTelegram(initDataRaw);
      if (startParam) {
        setReferralCode(startParam);
      }

      const timer = setTimeout(() => {
        // set slight delay so that splash screen is shown
        setIsInitTelegram(false);
      }, 200);

      return () => clearTimeout(timer);
    } catch (error) {
      console.error("Error retrieving launch params", error);
      setError("Error retrieving launch params");
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authDate) {
      // if app is left open for 24 hours and user is active at time of auth expiration
      // prompt user to restart app
      const now = new Date().getTime();
      const authExpTime = authDate.getTime() + 1000 * 60 * 60 * 24; // 24 hours
      const difference = authExpTime - now;
      const timer = setTimeout(() => {
        setIsShowingAuthExpiredModal(true);
      }, difference);
      return () => clearTimeout(timer);
    }
  }, [authDate]);

  useEffect(() => {
    const sendAppStartSignal = async () => {
      try {
        const authResponse = saveTelegramInitData(
          initDataRawTelegram as string,
          initDataTelegram?.user?.languageCode
        );
        if (!authResponse) {
          setError("Error setting auth token");
          return;
        }
        const response = await postRequest("miniapp/activity/app-start", {
          referral_code: referralCode
        });
        if (response.status !== 200) {
          setError(`Error: ${response.status} ${response.message}`);
          return;
        }
        const { data } = response;
        const { success, data: userData } = data;
        if (success && userData) {
          const { streak } = userData;
          setStreak(streak?.num_streak || 0);
          if (streak?.status === "daily-reward") {
            setIsClaimingDailyReward(true);
            setDailyRewardTickets(streak?.num_tickets || 0);
          }
          setNextDailyReward(streak?.next_reward_at || 0);
          const { user: telegramUser } = initDataTelegram as InitData;
          setUser({
            ...userData,
            first_name: telegramUser.firstName,
            last_name: telegramUser.lastName,
            profile_photo: telegramUser.photoUrl
          });

          // Analytics
          setGaUser(userData.id);
          datadogRum.setUser({ id: userData.id });
        }
      } catch (err) {
        setError((err as Error).message);
      } finally {
        setIsLoading(false);
        setSendAppStart(false);
      }
    };

    if (initDataRawTelegram && (sendAppStart || !user)) {
      sendAppStartSignal();
    }
  }, [initDataRawTelegram, sendAppStart, user]);

  useEffect(() => {
    init();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isInitTelegram,
        isClaimingDailyReward,
        dailyRewardTickets,
        error,
        setIsClaimingDailyReward,
        isShowingAuthExpiredModal
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
