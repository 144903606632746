import React from "react";

interface ButtonProps {
  text: React.ReactNode;
  onClick: () => void;
  width?: number;
  height?: number;
  fontSize?: string;
  disabled?: boolean;
  textSize?: string; // Tailwind text size
  textPosition?: "center" | "start" | "end";
  className?: string;
  datadogActionName?: string;
  buttonColor?: "yellow" | "orange" | "grey" | "pink";
  thickBottom?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  width = 207,
  height = 66,
  fontSize,
  disabled = false,
  textSize = "2xl",
  textPosition = "center",
  className = "",
  datadogActionName = "",
  buttonColor = "orange",
  thickBottom = false,
}) => {
  const borderFrameWidth = width ? `${width - 16}px` : "calc(100% - 16px)";
  const contentFrameWidth = width ? `${width - 8}px` : "calc(100% - 8px)";

  const colors = {
    yellow: {
      base: "#FFBB00",
      light: "#FFDA76",
      dark: "#B28300",
    },
    orange: {
      base: "#FF6A3C",
      light: "#FF9D00",
      dark: "#E63A00",
    },
    grey: {
      base: "#BCBEC0",
      light: "#D1D3D4",
      dark: "#888A8E",
    },
    pink: {
      base: "#ff3bae",
      light: "#FF90CC",
      dark: "#a115e8",
    }
  };

  if (disabled) {
    buttonColor = "grey";
  }

  const contentColor = colors[buttonColor].base;
  const bandColor = colors[buttonColor].light;
  const bottomColor = colors[buttonColor].dark;

  const containerStyle: { width: string; height?: string } = {
    width: width ? `${width}px` : "100%",
  };
  const buttonStyle = {
    width: contentFrameWidth,
    backgroundColor: contentColor,
    fontSize,
    height: height ? `${height - 16}px` : undefined,
  };

  return (
    <div
      style={containerStyle}
      className={`flex flex-col items-center h-full ${className}`}
    >
      {/* Top line */}
      <div className="h-[4px] bg-black" style={{ width: borderFrameWidth }} />

      {/* Top inner line */}
      <div className="flex">
        <div className="w-[4px] h-[4px] bg-black" />
        <div
          className="h-[4px]"
          style={{ width: borderFrameWidth, backgroundColor: contentColor }}
        />
        <div className="w-[4px] h-[4px] bg-black" />
      </div>

      {/* Content */}
      <div className="flex relative overflow-hidden">
        <div className="w-[4px] bg-black" />
        <button
          className={`flex justify-center items-${textPosition} text-white px-2 ${textSize ? `text-${textSize}` : "text-2xl"}`}
          disabled={disabled}
          style={buttonStyle}
          onClick={onClick}
          data-dd-action-name={datadogActionName}
        >
          {text}
        </button>
        <div className="w-[4px] bg-black" />
        {/* band inside the button */}
        <div className="absolute left-[4px] bottom-[4px] w-[4px] h-[4px]" style={{ backgroundColor: bandColor, bottom: thickBottom ? "8px" : "4px" }}/>
        <div className="absolute right-[4px] bottom-[4px] w-[4px] h-[4px]" style={{ backgroundColor: bandColor, bottom: thickBottom ? "8px" : "4px" }}/>
        <div className="absolute left-[8px] bottom-0 h-[4px]" style={{ width: borderFrameWidth, backgroundColor: bandColor, bottom: thickBottom ? "4px" : "0px" }}/>
        {/* thick bottom line */}
        {thickBottom && (
          <>
        <div className="absolute left-[4px] bottom-[4px] w-[4px] h-[4px]" style={{ backgroundColor: bottomColor}}/>
        <div className="absolute right-[4px] bottom-[4px] w-[4px] h-[4px]" style={{ backgroundColor: bottomColor}}/>
        <div className="absolute left-[8px] bottom-0 h-[4px]" style={{ width: borderFrameWidth, backgroundColor: bottomColor }} />
        </>
        )}
        <div className="absolute left-[4px] bottom-0 w-[4px] h-[4px]" style={{ backgroundColor: bottomColor }}/>
        <div className="absolute right-[4px] bottom-0 w-[4px] h-[4px]" style={{ backgroundColor: bottomColor }}/>
      </div>

      {/* Bottom inner line */}
      <div className="flex">
        <div className="w-[4px] h-[4px] bg-black" />
        <div
          className="h-[4px]"
          style={{ width: borderFrameWidth, backgroundColor: bottomColor }}
        />
        <div className="w-[4px] h-[4px] bg-black" />
      </div>

      {/* Bottom line */}
      <div className="h-[4px] bg-black" style={{ width: borderFrameWidth }} />
    </div>
  );
};

export default Button;
