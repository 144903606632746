"use client";

import { useEffect, useState } from "react";
import Box from "../../Box";
import LeaderboardRecord from "./LeaderboardRecord";
import NavigationArrow from "../../icons/NavigationArrow";
import { RANKING_TYPES, RankingRecord } from "../../../types/community";
import Rankings from "./Rankings";
import { calculateRankingsPercent, getRankings, getTitleAndIcon, isCurrentUserRecord } from "../../../utils/leaderboard";
import Loader from "../../Loader";
import useStore from "../../../lib/store";

const LIMIT = 5;

interface UserStats {
  username: string;
  rank: number | null;
  score: number | null;
}

interface LeaderboardsProps {
  viewingRecordType: RANKING_TYPES | undefined;
  setViewingRecordType: (recordType: RANKING_TYPES | undefined) => void;
}

const Leaderboard = ({ viewingRecordType, setViewingRecordType }: LeaderboardsProps) => {
  const [topEarnerRankings, setTopEarnerRankings] = useState<RankingRecord[]>([]);
  const [iceSnakeRankings, setIceSnakeRankings] = useState<RankingRecord[]>([]);
  const [gloveHeroRankings, setGloveHeroRankings] = useState<RankingRecord[]>([]);
  const [topEarnerUserStats, setTopEarnerUserStats] = useState<UserStats>({ username: "", rank: 0, score: 0});
  const [iceSnakeUserStats, setIceSnakeUserStats] = useState<UserStats>({ username: "", rank: 0, score: 0});
  const [gloveHeroUserStats, setGloveHeroUserStats] = useState<UserStats>({ username: "", rank: 0, score: 0});
  const [topEarnersTotalCount, setTopEarnersTotalCount] = useState(0);
  const [iceSnakeTotalCount, setIceSnakeTotalCount] = useState(0);
  const [gloveHeroTotalCount, setGloveHeroTotalCount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const user = useStore(state => state.user);

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      const [topEarnerResp, iceSnakeResp, gloveHeroResp] = await Promise.all([
        getRankings(RANKING_TYPES.TOP_EARNERS, 0, LIMIT),
        getRankings(RANKING_TYPES.ICE_SNAKE, 0, LIMIT),
        getRankings(RANKING_TYPES.GLOVE_HERO, 0, LIMIT)
      ]);

      if (topEarnerResp.status === 200 && topEarnerResp.data) {
        setTopEarnerRankings(topEarnerResp.data.leaderboard);
        setTopEarnersTotalCount(topEarnerResp.data.count);
        setTopEarnerUserStats(topEarnerResp.data.user);
      }
      if (iceSnakeResp.status === 200 && iceSnakeResp.data) {
        setIceSnakeRankings(iceSnakeResp.data.leaderboard);
        setIceSnakeTotalCount(iceSnakeResp.data.count);
        setIceSnakeUserStats(iceSnakeResp.data.user);
      }
      if (gloveHeroResp.status === 200 && gloveHeroResp.data) {
        setGloveHeroRankings(gloveHeroResp.data.leaderboard);
        setGloveHeroTotalCount(gloveHeroResp.data.count);
        setGloveHeroUserStats(gloveHeroResp.data.user);
      }
      setIsFetching(false);
    }

    fetchData();
  }, []);

  const getUserScoreInfo = () => {
    let userRank = 0;
    let userRankDescription;
    let userScore = 0;
    let totalPlayers;
    if (viewingRecordType === RANKING_TYPES.TOP_EARNERS) {
      userRank = topEarnerUserStats.rank || 0;
      totalPlayers = topEarnersTotalCount;
      userRankDescription = calculateRankingsPercent(userRank|| 0, totalPlayers);
      userScore = topEarnerUserStats.score || 0;
    } else if (viewingRecordType === RANKING_TYPES.ICE_SNAKE) {
      userRank = iceSnakeUserStats.rank || 0;
      totalPlayers = iceSnakeTotalCount;
      userRankDescription = calculateRankingsPercent(userRank || 0, totalPlayers);
      userScore = iceSnakeUserStats.score || 0;
    } else if (viewingRecordType === RANKING_TYPES.GLOVE_HERO) {
      userRank = gloveHeroUserStats.rank || 0;
      totalPlayers = gloveHeroTotalCount;
      userRankDescription = calculateRankingsPercent(userRank || 0, totalPlayers);
      userScore = gloveHeroUserStats.score || 0;
    }

    return {
      userRank,
      userRankDescription,
      userScore
    };
  };

  const getRankTitle = (rank: number, rankingType: RANKING_TYPES) => {
    const { title, icon } = getTitleAndIcon(rankingType);
    if (rank === 1) {
      return <div className="flex gap-1"><span>{title}</span><span>{icon}</span></div>;
    } 
    return undefined;
  };

  const getContent = (rankingType: RANKING_TYPES) => {
    let rank = 0;
    let totalPlayers = 0;
    let records = [];
    let clickFunction;
    let rankDescription;

    switch (rankingType) {
      case RANKING_TYPES.TOP_EARNERS:
        rank = topEarnerUserStats.rank || 0;
        totalPlayers = topEarnersTotalCount;
        records = topEarnerRankings;
        clickFunction = () => setViewingRecordType(RANKING_TYPES.TOP_EARNERS);
        rankDescription = calculateRankingsPercent(rank, totalPlayers);
        break;
      case RANKING_TYPES.ICE_SNAKE:
        rank = iceSnakeUserStats.rank || 0;
        totalPlayers = iceSnakeTotalCount;
        records = iceSnakeRankings;
        clickFunction = () => setViewingRecordType(RANKING_TYPES.ICE_SNAKE);
        rankDescription = calculateRankingsPercent(rank, totalPlayers);
        break;
      case RANKING_TYPES.GLOVE_HERO:
        rank = gloveHeroUserStats.rank || 0;
        totalPlayers = gloveHeroTotalCount;
        records = gloveHeroRankings;
        clickFunction = () => setViewingRecordType(RANKING_TYPES.GLOVE_HERO);
        rankDescription = calculateRankingsPercent(rank, totalPlayers);
        break;
    }

    return (
      <div className="flex flex-col bg-secondaryWhite items-start px-4 py-2">
        <div className="flex justify-between w-full">
          <h2>{rankingType}</h2>
          <button className="z-5 rotate-180" data-dd-action-name={`${rankingType} Leaderboard`} onClick={clickFunction}>
            <NavigationArrow backgroundColor="#F1F2F2" arrowColor="black" />
          </button>
        </div>
        <div className="flex gap-1 text-sm text-darkGrey">
          <p>RANK</p>
          <p>{`#${rank}`}</p>
          <p>/</p>
          <p>{totalPlayers}</p>
          <p className={ ["Unranked", "Bottom 50%"].includes(rankDescription) ? "text-darkGrey" : "text-green"}>{rankDescription}</p>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          {records.map((record) => (
            <LeaderboardRecord
              key={record.username}
              user={record.username}
              score={record.score}
              rank={record.rank}
              backgroundColor={user && isCurrentUserRecord(user, record.username) ? "#90D6FF" : "#D2D2D2"}
              rankTitle={getRankTitle(record.rank, rankingType)}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {!viewingRecordType && (
        <div className="flex flex-col items-center mt-6 gap-2">
          {isFetching && <Loader width={32} height={32} />}
          {!isFetching && Object.values(RANKING_TYPES).map(type => (
            <Box key={type} content={getContent(type)} width={354} bottomBorderColor="black" />
          ))}
        </div>
      )}
      {viewingRecordType && <Rankings rankingType={viewingRecordType} setViewingRecordType={setViewingRecordType} userStats={getUserScoreInfo()}/>}
    </>
  );
};

export default Leaderboard;
