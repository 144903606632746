const NavigationArrow = ({ backgroundColor = "black", arrowColor = "white"}: {backgroundColor?: string, arrowColor?: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
    <g id="Frame 129">
      <rect width="16" height="24" fill={backgroundColor} />
      <g id="Group 89">
        <rect
          id="Rectangle 378"
          x="5"
          y="13"
          width="2"
          height="2"
          transform="rotate(-180 5 13)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 379"
          x="7"
          y="13"
          width="2"
          height="2"
          transform="rotate(-180 7 13)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 380"
          x="7"
          y="11"
          width="2"
          height="2"
          transform="rotate(-180 7 11)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 381"
          x="7"
          y="15"
          width="2"
          height="2"
          transform="rotate(-180 7 15)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 382"
          x="11"
          y="17"
          width="2"
          height="10"
          transform="rotate(-180 11 17)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 387"
          x="13"
          y="17"
          width="2"
          height="10"
          transform="rotate(-180 13 17)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 383"
          x="9"
          y="15"
          width="2"
          height="2"
          transform="rotate(-180 9 15)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 384"
          x="9"
          y="13"
          width="2"
          height="2"
          transform="rotate(-180 9 13)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 385"
          x="9"
          y="11"
          width="2"
          height="2"
          transform="rotate(-180 9 11)"
          fill={arrowColor}
        />
        <rect
          id="Rectangle 386"
          x="11"
          y="9"
          width="2"
          height="2"
          transform="rotate(-180 11 9)"
          fill={arrowColor}
        />
      </g>
    </g>
  </svg>
);

export default NavigationArrow;
