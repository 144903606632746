import { GAME_SCREENS } from "../../types/home";
import GloveHeroPlayerShadow from "../icons/GloveHeroPlayerShadow";
import IceSnakePlayerShadow from "../icons/IceSnakePlayerShadow";
import SoccerBall from "../icons/SoccerBall";
import OptimizedImage from "../OptimizedImage";

interface PlayerIconProps {
  gameScreen: GAME_SCREENS;
}

const GloveHeroIcon = () => (
  <div className="flex flex-col items-center relative transform -translate-y-1/3">
    <div className="flex flex-col items-center">
      <OptimizedImage
        src="/image/soccer.gif"
        alt="Glove Hero"
        width={195}
        height={293}
        className="z-20"
      />
      <GloveHeroPlayerShadow className="mt-[-25px] z-10" />
    </div>
    <SoccerBall width={58} height={58} className="mt-[-50px] z-30" />
  </div>
);

const IceSnakeIcon = () => (
  <div className="flex flex-col items-center relative transform -translate-y-1/3">
    <OptimizedImage
      src="/image/hockey.gif"
      alt="Ice Snake"
      width={195}
      height={293}
      className="z-20"
    />
    <IceSnakePlayerShadow className="mt-[-40px] z-10" />
  </div>
);

const PlayerIcon = ({ gameScreen }: PlayerIconProps) => {
  const icons = {
    [GAME_SCREENS.GLOVE_HERO]: <GloveHeroIcon />,
    [GAME_SCREENS.ICE_SNAKE]: <IceSnakeIcon />,
  };

  return icons[gameScreen] || null;
};

export default PlayerIcon;
