"use-client";

import { useEffect, useState } from "react";
import Gem from "../icons/Gem";
import InviteButton from "./InviteButton";
import ReferralLinkModal from "./ReferralLinkModal";
import SortDropdown from "../Form/SortDropdown";
import PokePointer from "../icons/PokePointer";
import FriendsActivityList from "./FriendsActivityList";
import InviteFriends from "./InviteFriends";
import { FRIEND_ACTIVITIES, FriendActivity } from "../../types/activity";
import { getRequest } from "../../utils/requests";
import Loader from "../Loader";

const FriendsActivity = () => {
  const [friendsActivities, setFriendsActivities] = useState<FriendActivity[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState("earning");
  const [isFetchingActivities, setIsFetchingActivities] = useState(true);

  const [isDisplayingReferralModal, setIsDisplayingReferralCodeModal] = useState(false);

  const fetchFriendReferralActivities = async () => {
    const response = await getRequest("miniapp/referrals");
    const { status, data } = response;
    if (status === 200 && data) {
      return data;
    }
  };

  useEffect(() => {
    const setFriendsReferralActivities = async () => {
      setIsFetchingActivities(true);
      const activities = await fetchFriendReferralActivities();
      if (activities) {
        setFriendsActivities(activities);
      }

      sortActivities("earning");
      setIsFetchingActivities(false);
    };

    setFriendsReferralActivities();
  }, []);

  const sortActivities = (sortOrder: string) => {
    setSelectedSortOption(sortOrder);

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

    const getCategory = (activity: FriendActivity) => {
      const lastTokenAwarded = activity.last_game_played && new Date(activity.last_game_played);

      if (lastTokenAwarded && lastTokenAwarded < twoWeeksAgo && activity.verified) {
        return FRIEND_ACTIVITIES.INACTIVE;
      } else if (activity.gems_earned) {
        return FRIEND_ACTIVITIES.GEMS_EARNED;
      } else if (activity.verified) {
        return FRIEND_ACTIVITIES.VERIFIED;
      } else {
        return FRIEND_ACTIVITIES.JOINED;
      }
    };

    setFriendsActivities(prevActivities => {
      const sortedActivities = [...prevActivities].sort((a, b) => {
        const categoryA = getCategory(a);
        const categoryB = getCategory(b);

        if (sortOrder === "earning") {
          // Sorting logic: GEMS_EARNED > INACTIVE > VERIFIED > JOINED
          if (categoryA !== categoryB) return categoryA.localeCompare(categoryB);
          if (categoryA === FRIEND_ACTIVITIES.GEMS_EARNED) {
            return b.gems_earned - a.gems_earned;
          }
          if (categoryA === FRIEND_ACTIVITIES.INACTIVE) {
            return new Date(a.last_game_played).getTime() - new Date(b.last_game_played).getTime();
          }
          if (categoryA === FRIEND_ACTIVITIES.JOINED) {
            return new Date(a.joined).getTime() - new Date(b.joined).getTime();
          }
        }

        if (sortOrder === "inactive") {
          // Sorting logic: JOINED > VERIFIED > INACTIVE > GEMS_EARNED
          if (categoryA !== categoryB) return categoryB.localeCompare(categoryA);
          if (categoryA === FRIEND_ACTIVITIES.JOINED) {
            return new Date(a.joined).getTime() - new Date(b.joined).getTime();
          }
          if (categoryA === FRIEND_ACTIVITIES.INACTIVE) {
            return new Date(a.last_game_played).getTime() - new Date(b.last_game_played).getTime();
          }
          if (categoryA === FRIEND_ACTIVITIES.GEMS_EARNED) {
            return b.gems_earned - a.gems_earned;
          }
        }

        return 0;
      });

      return sortedActivities;
    });
  };

  if (isFetchingActivities) {
    return (
      <div className="loader flex items-center justify-center mt-[100px]" key={0}>
        <Loader width={32} height={32} />
      </div>
    );
  }

  return (
    <>
      {!friendsActivities.length && (
        <div>
          <div className="flex flex-col items-center text-white mt-4 gap-4">
            <div className="flex flex-col items-center text-2xl">
              <div>Play with friends,</div>
              <div className="flex flex-row gap-2 items-center">
                Earn Gems <Gem width={25} height={25} />
              </div>
            </div>
          </div>
          <InviteFriends />
          <InviteButton width={200} height={66} popupOffset="70px" text={"INVITE"} />
        </div>
      )}
      {isDisplayingReferralModal && (
        <ReferralLinkModal setIsDisplayingReferralCodeModal={setIsDisplayingReferralCodeModal} />
      )}
      {friendsActivities.length > 0 && (
        <>
          <div className="flex flex-col items-center bg-black bg-opacity-[.60] mt-2 pt-3 pb-4 justify-center">
            <div className="flex flex-row items-center text-white text-2xl">
              <div>Play with Friends, Earn Gems </div>
              <div className="pl-2">
                <Gem width={25} height={25} />
              </div>
            </div>
            <div className="pt-4">
              <InviteButton text={"INVITE"} width={106} height={41} textSize="1xl" />
            </div>
            <button
              onClick={() => setIsDisplayingReferralCodeModal(true)}
              className="text-white pt-4 underline"
            >
              How to earn
            </button>
          </div>
          <div className="flex flex-row justify-between mx-5 mt-4">
            <div className="text-white text-2xl">My Friends</div>
            <SortDropdown
              options={[
                { value: "earning", label: "Earning" },
                { value: "inactive", label: "Inactive" }
              ]}
              selected={selectedSortOption}
              onSortChange={sortActivities}
            />
          </div>
          <div className="flex flex-row gap-4 items-center bg-black bg-opacity-[.60] mx-5 mt-3 py-1 p-4 w-[362px]">
            <PokePointer />
            <div>
              <div className="text-white">Poke your friends to notify them to earn!</div>
            </div>
          </div>
          <div className="mt-2 mx-5">
            <FriendsActivityList friendsActivities={friendsActivities} />
          </div>
        </>
      )}
    </>
  );
};

export default FriendsActivity;
