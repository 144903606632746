const Jersey = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none">
    <rect x="5" y="16" width="13" height="1" fill="black" />
    <rect x="6" y="17" width="11" height="1" fill="black" />
    <rect x="17" y="6" width="1" height="10" fill="black" />
    <rect x="5" y="6" width="1" height="10" fill="black" />
    <rect x="18" y="6" width="1" height="1" fill="black" />
    <rect x="18" y="7" width="1" height="1" fill="black" />
    <rect x="1" y="4" width="21" height="2" fill="#88CEFF" />
    <rect x="19" y="7" width="1" height="1" fill="black" />
    <rect x="19" y="8" width="1" height="1" fill="black" />
    <rect x="20" y="6" width="1" height="1" fill="black" />
    <rect x="20" y="7" width="1" height="1" fill="black" />
    <rect x="21" y="5" width="1" height="1" fill="black" />
    <rect x="21" y="6" width="1" height="1" fill="black" />
    <rect x="22" y="4" width="1" height="1" fill="black" />
    <rect x="22" y="5" width="1" height="1" fill="black" />
    <rect x="21" y="3" width="1" height="1" fill="black" />
    <rect x="2" y="2" width="19" height="2" fill="#A9DCFF" />
    <rect x="4" y="1" width="5" height="1" fill="#D1ECFF" />
    <rect x="14" y="1" width="5" height="1" fill="#D1ECFF" />
    <rect x="20" y="2" width="1" height="1" fill="black" />
    <rect x="19" y="1" width="1" height="1" fill="black" />
    <rect x="3" y="1" width="1" height="1" fill="black" />
    <rect x="2" y="2" width="1" height="1" fill="black" />
    <rect x="1" y="3" width="1" height="1" fill="black" />
    <rect y="4" width="1" height="1" fill="black" />
    <rect y="5" width="1" height="1" fill="black" />
    <rect x="1" y="5" width="1" height="1" fill="black" />
    <rect x="1" y="6" width="1" height="1" fill="black" />
    <rect x="2" y="6" width="1" height="1" fill="black" />
    <rect x="2" y="7" width="1" height="1" fill="black" />
    <rect x="3" y="7" width="1" height="1" fill="black" />
    <rect x="3" y="8" width="1" height="1" fill="black" />
    <rect x="4" y="6" width="1" height="1" fill="black" />
    <rect x="4" y="7" width="1" height="1" fill="black" />
    <rect x="5" y="5" width="1" height="1" fill="black" />
    <rect x="6" y="14" width="11" height="2" fill="#1100FF" />
    <rect x="6" y="12" width="11" height="2" fill="#004DFF" />
    <rect x="6" y="10" width="11" height="2" fill="#006FFF" />
    <rect x="6" y="8" width="11" height="2" fill="#0096FF" />
    <rect x="6" y="6" width="11" height="2" fill="#51B8FF" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 20 6)" fill="#51B8FF" />
    <rect width="1" height="1" transform="matrix(-1 0 0 1 4 6)" fill="#51B8FF" />
    <rect x="5" y="6" width="1" height="1" fill="black" />
    <rect x="12" y="1" width="2" height="1" fill="black" />
    <rect x="9" y="1" width="2" height="1" fill="black" />
    <rect x="19" y="6" width="1" height="1" fill="white" />
    <rect x="14" y="1" width="1" height="1" fill="white" />
    <rect x="8" y="1" width="1" height="1" fill="white" />
    <rect x="11" y="3" width="1" height="1" fill="white" />
    <rect x="12" y="2" width="2" height="1" fill="white" />
    <rect x="9" y="2" width="2" height="1" fill="white" />
    <rect x="3" y="6" width="1" height="1" fill="white" />
    <rect x="6" y="15" width="11" height="1" fill="white" />
    <rect x="2" y="5" width="1" height="1" fill="white" />
    <rect x="1" y="4" width="1" height="1" fill="white" />
    <rect x="20" y="5" width="1" height="1" fill="white" />
    <rect x="21" y="4" width="1" height="1" fill="white" />
    <rect x="11" y="2" width="1" height="1" fill="black" />
    <rect x="10" y="7" width="1" height="1" fill="black" />
    <rect x="11" y="6" width="1" height="1" fill="black" />
    <rect x="11" y="7" width="1" height="4" fill="black" />
    <rect x="14" width="5" height="1" fill="black" />
    <rect x="4" width="5" height="1" fill="black" />
    <rect x="17" y="5" width="1" height="1" fill="black" />
    <rect x="17" y="6" width="1" height="1" fill="black" />
  </svg>
);

export default Jersey;