"use client";

import useStore from "../../lib/store";
import IconProfileBorder from "../icons/IconProfileBorder";
import OptimizedImage from "../OptimizedImage";

interface AccountAvatarProp {
  width?: number;
  height?: number;
  className?: string;
}

const AccountAvatar = ({ width = 64, height = 64, className = "" }: AccountAvatarProp) => {
  const user = useStore(state => state.user);
  const frameWidth = `${width}px`;
  const frameHeight = `${height}px`;
  const innerWidth = `${width - 4}px`;
  const innerHeight = `${height - 4}px`;
  return (
    <div
      className={`relative flex items-center justify-center ${className}`}
      style={{ width: frameWidth, height: frameHeight }}
    >
      {
        !user?.profile_photo && (
          <div className="rounded-full bg-teal" style={{ width: innerWidth, height: innerHeight }}/>
        )
      }
      {
        user?.profile_photo && (
          <OptimizedImage src={user.profile_photo} alt="profile-photo" width={width - 4} height = {height - 4} className="rounded-full object-cover"/>
        )
      }
      <IconProfileBorder className="absolute inset-0 w-full h-full" />
    </div>
  );
};

export default AccountAvatar;
