"use client";

import { ActivityItem, ACTIVITY_TYPES } from "../../types/activity";
import { formatActivityDate, getActivityTextForDisplay } from "../../utils/activities";
import Block from "../icons/Block";
import Gem from "../icons/Gem";
import Bell from "../icons/Bell";
import Ticket from "../icons/Ticket";
import Headset from "../icons/Headset";
import OptimizedImage from "../OptimizedImage";
import SoccerBall from "../icons/SoccerBall";
import HockeyIcon from "../icons/Hockey";
import GatherIcon from "../icons/GatherIcon";

interface ActivityProps {
  activity: ActivityItem
  activeTab: ACTIVITY_TYPES
}

const getIconForActivity = (reason: string, iconUrl?: string) => {
  switch (reason) {
    case "daily-reward":
      return <Bell />;
    case "ticket-adjustment":
    case "point-adjustment":
      return <Headset />;
    case "ice-snake":
      return <HockeyIcon />;
    case "glove-hero":
      return <SoccerBall />;
    case "referral-bonus":
      return <GatherIcon />;
    default:
      return <OptimizedImage src={iconUrl || "/image/Sweet.png"} alt="Sweet Icon" width={40} height={40} className="rounded-md" />;
  }
};

const Activity = ({ activity, activeTab }: ActivityProps) => {
  const { quantity, reason, datetime, icon_url: iconUrl } = activity;
  return (
    <div className="relative inline-block justify-center mt-2">
      <Block width={362} height={73} />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[362px] py-5 px-7 flex gap-3 items-center">
        <div className="w-[45px] flex items-center justify-center">{getIconForActivity(reason, iconUrl)}</div>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center">
            <div>{getActivityTextForDisplay(reason, activeTab)}</div>
            <div className="opacity-50 text-sm">{formatActivityDate(datetime)}</div>
          </div>
          <div className={`flex gap-1 items-center bg-${activeTab === ACTIVITY_TYPES.GEMS ? "pink" : "teal"} h-[19px] p-2`}>
            <div>{Math.sign(quantity) === 1 ? "+" : "-"}</div>
            <div>
              {activeTab === ACTIVITY_TYPES.GEMS ? <Gem /> : <Ticket />}
            </div>
            <div>{Math.abs(quantity)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
