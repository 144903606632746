"use client";
import { useMemo, useState } from "react";
import CommunityTabs from "./CommunityTabs";
import { COMMUNITY_TABS, RANKING_TYPES } from "../../types/community";
import FriendsActivity from "./FriendsActivity";
import Leaderboard from "./Leaderboard";
import useStore from "../../lib/store";

const Community = () => {
  const activeTab = useStore(state => state.activeCommunityTab);
  const [viewingRecordType, setViewingRecordType] = useState<RANKING_TYPES | undefined>(
    undefined
  );

  const backgroundImage = useMemo(() => {
    switch (viewingRecordType) {
      case RANKING_TYPES.TOP_EARNERS:
        return "url('/image/clouds-background.png')";
      case RANKING_TYPES.ICE_SNAKE:
        return "url('/image/ice-snake-background.png')";
      case RANKING_TYPES.GLOVE_HERO:
        return "url('/image/glove-hero-background.png')";
      default:
        return "url('/image/clouds-background.png')";
    }
  }, [viewingRecordType]);

  return (
    <div className="flex flex-col pt-2 bg-black bg-top bg-fixed bg-[length:100%_auto] min-h-[630px] pb-[77px]" style={{ backgroundImage: backgroundImage}}>
      <CommunityTabs />
      {activeTab === COMMUNITY_TABS.INVITE && <FriendsActivity />}
      {activeTab === COMMUNITY_TABS.LEADERBOARDS && <Leaderboard viewingRecordType={viewingRecordType} setViewingRecordType={setViewingRecordType} />}
    </div>
  );
};

export default Community;
