import { ACTIVITY_TYPES } from "../types/activity";

const ActivityReasonsDisplay: { [key: string]: string } = {
  "daily-reward": "Daily Reward",
  "ticket-adjustment": "Support Adjustment",
  "point-adjustment": "Support Adjustment",
  "referral-bonus": "Referral Reward",
};

export const getActivityTextForDisplay = (activityReason: string, rewardType: ACTIVITY_TYPES) => {
  if (rewardType === ACTIVITY_TYPES.GEMS) {
    ActivityReasonsDisplay["ice-snake"] = "Ice Snake Reward";
    ActivityReasonsDisplay["glove-hero"] = "Glove Hero Reward";
  } else {
    ActivityReasonsDisplay["ice-snake"] = "Played Ice Snake";
    ActivityReasonsDisplay["glove-hero"] = "Played Glove Hero";
  }
  return ActivityReasonsDisplay[activityReason] || activityReason;
};

export const formatActivityDate = (datetime: string) => {
  // datetime is in ISO 8601 format
  const date = new Date(datetime + "Z"); // Ensure it's treated as UTC
  return date.toLocaleString(navigator.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};
