const gradientBox = ({
  color1 = "#FA7A2A",
  color2 = "#FAA32A",
  color3 = "#FABF2A",
  color4 = "#F9E229"
}: {
  color1?: string
  color2?: string
  color3?: string
  color4?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="357"
    height="124"
    viewBox="0 0 357 124"
    fill="none"
  >
    <rect x="200" y="3" width="147" height="118" fill={color4} />
    <rect width="226" height="118" transform="matrix(-1 0 0 1 229 3)" fill={color4} />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121 21H115V3H121H127H133H139H145H151H195V13H201V31H207V49H213V67H219V85H225V103H231V121H225H219H213H207H201H195H151V111H145V93H139V75H133V57H127V39H121V21Z"
      fill={color3}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 121H29H35H41H47H53H59H65H71V100H65H59V82H53V64H47V46H41V28H35V10H29V3H3V121Z"
      fill={color1}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35 28H41V46H47V64H53V82H59V100H65V118H71V121H115H121H127H133H139H145H151V110H145V92H139V74H133V56H127V38H121V20H115V3H71H65H59H53H47H41H35H29V10H35V28Z"
      fill={color2}
    />
    <rect width="3" height="112" transform="matrix(-1 0 0 1 350 6)" fill="black" />
    <rect width="3" height="112" transform="matrix(-1 0 0 1 3 6)" fill="black" />
    <rect x="6" y="6" width="3" height="3" transform="rotate(180 6 6)" fill="black" />
    <rect x="6" y="121" width="3" height="3" transform="rotate(180 6 121)" fill="black" />
    <rect
      opacity="0.2"
      x="6"
      y="118"
      width="3"
      height="3"
      transform="rotate(180 6 118)"
      fill="black"
    />
    <rect x="347" y="6" width="3" height="3" transform="rotate(180 347 6)" fill="black" />
    <rect x="347" y="121" width="3" height="3" transform="rotate(180 347 121)" fill="black" />
    <rect
      opacity="0.2"
      x="347"
      y="118"
      width="3"
      height="3"
      transform="rotate(180 347 118)"
      fill="black"
    />
    <rect x="344" y="3" width="338" height="3" transform="rotate(180 344 3)" fill="black" />
    <rect x="344" y="124" width="338" height="3" transform="rotate(180 344 124)" fill="black" />
    <rect
      opacity="0.2"
      x="344"
      y="121"
      width="338"
      height="3"
      transform="rotate(180 344 121)"
      fill="black"
    />

    <defs>
      <pattern
        id="pattern0_2318_21159"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0_2318_21159" transform="scale(0.000976562)" />
      </pattern>
    </defs>
  </svg>
);

export default gradientBox;
