export enum REWARD_TYPES {
  GEMS = "points",
  TICKETS = "tickets"
}

export const REWARD_TYPE_LABELS = {
  [REWARD_TYPES.GEMS]: "Gems",
  [REWARD_TYPES.TICKETS]: "Tickets"
};

export interface TmaAction {
  user_id: number;
  action: string;
  timestamp_ms: number;
  rewarded_at?: number;
  task_id?: number;
}

export interface CustomTask {
  id: number;
  name: string;
  description: string;
  reward_type: REWARD_TYPES;
  reward_amount: number;
  icon_url: string;
  link: string;
  action?: TmaAction;
}
