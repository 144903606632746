const GloveHeroPlayerShadow = ({ className = "" }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="116"
    height="22"
    viewBox="0 0 116 22"
    fill="none"
    className={className}
  >
    <ellipse cx="58" cy="11" rx="58" ry="11" fill="#566A25" />
  </svg>
);

export default GloveHeroPlayerShadow;
