import Earn from "../Earn";
import Community from "../Community";
import { NAVIGATION_TABS } from "../../types/home";
import Account from "../Account";
import Play from "../Play";
import ActivityLog from "../ActivityLog";
import useStore from "../../lib/store";

const HomeContent = () => {
  const activeTab = useStore(state => state.activeTab);

  return (
    <section className="pb-[32px]">
      {activeTab === NAVIGATION_TABS.EARN && <Earn />}
      {activeTab === NAVIGATION_TABS.COMMUNITY && <Community />}
      {activeTab === NAVIGATION_TABS.ACCOUNT && <Account /> }
      {activeTab === NAVIGATION_TABS.PLAY && <Play />}
      {activeTab === NAVIGATION_TABS.ACTIVITY_LOG && <ActivityLog />}
    </section>
  );
};

export default HomeContent;
