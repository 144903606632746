
const HockeyStick = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="75" height="99" viewBox="0 0 75 99" fill="none" className={className}>
    <rect
      x="40.2878"
      y="62.1788"
      width="2"
      height="14"
      transform="rotate(12.9046 40.2878 62.1788)"
      fill="#D6EEFF"
    />
    <rect
      x="60.0245"
      y="29.768"
      width="2"
      height="14"
      transform="rotate(12.9046 60.0245 29.768)"
      fill="#06C9FF"
    />
    <rect
      x="60.0245"
      y="29.768"
      width="2"
      height="6"
      transform="rotate(12.9046 60.0245 29.768)"
      fill="#D6EEFF"
    />
    <rect
      x="50.1561"
      y="45.9734"
      width="2"
      height="14"
      transform="rotate(12.9046 50.1561 45.9734)"
      fill="#06C9FF"
    />
    <rect
      x="69.8929"
      y="13.5626"
      width="2"
      height="14"
      transform="rotate(12.9046 69.8929 13.5626)"
      fill="#D6EEFF"
    />
    <rect
      x="72.289"
      y="12.0597"
      width="2"
      height="8"
      transform="rotate(12.9046 72.289 12.0597)"
      fill="#D6EEFF"
    />
    <rect
      x="36.9983"
      y="67.5806"
      width="2"
      height="14"
      transform="rotate(12.9046 36.9983 67.5806)"
      fill="#D6EEFF"
    />
    <rect
      x="56.7351"
      y="35.1698"
      width="2"
      height="14"
      transform="rotate(12.9046 56.7351 35.1698)"
      fill="#06C9FF"
    />
    <rect
      x="46.8667"
      y="51.3752"
      width="2"
      height="14"
      transform="rotate(12.9046 46.8667 51.3752)"
      fill="#06C9FF"
    />
    <rect
      x="66.6034"
      y="18.9644"
      width="2"
      height="14"
      transform="rotate(12.9046 66.6034 18.9644)"
      fill="#D6EEFF"
    />
    <rect
      x="33.7089"
      y="72.9824"
      width="2"
      height="14"
      transform="rotate(12.9046 33.7089 72.9824)"
      fill="#D6EEFF"
    />
    <rect
      x="31.3127"
      y="74.4852"
      width="2"
      height="14"
      transform="rotate(12.9046 31.3127 74.4852)"
      fill="#D6EEFF"
    />
    <rect
      x="28.9166"
      y="75.9881"
      width="2"
      height="14"
      transform="rotate(12.9046 28.9166 75.9881)"
      fill="#D6EEFF"
    />
    <rect
      x="2.67994"
      y="74.0805"
      width="2"
      height="10"
      transform="rotate(12.9046 2.67994 74.0805)"
      fill="#D6EEFF"
    />
    <rect
      x="5.07608"
      y="72.5777"
      width="24"
      height="14"
      transform="rotate(12.9046 5.07608 72.5777)"
      fill="#D6EEFF"
    />
    <rect
      x="53.4456"
      y="40.5716"
      width="2"
      height="14"
      transform="rotate(12.9046 53.4456 40.5716)"
      fill="#06C9FF"
    />
    <rect
      x="43.5772"
      y="56.777"
      width="2"
      height="14"
      transform="rotate(12.9046 43.5772 56.777)"
      fill="#D6EEFF"
    />
    <rect
      x="43.5772"
      y="56.777"
      width="2"
      height="10"
      transform="rotate(12.9046 43.5772 56.777)"
      fill="#06C9FF"
    />
    <rect
      x="40.2878"
      y="62.1788"
      width="2"
      height="6"
      transform="rotate(12.9046 40.2878 62.1788)"
      fill="#06C9FF"
    />
    <rect
      x="36.9983"
      y="67.5806"
      width="2"
      height="2"
      transform="rotate(12.9046 36.9983 67.5806)"
      fill="#06C9FF"
    />
    <rect
      x="26.6833"
      y="85.7355"
      width="2"
      height="2"
      transform="rotate(12.9046 26.6833 85.7355)"
      fill="#949494"
    />
    <rect
      x="29.0794"
      y="84.2327"
      width="2"
      height="2"
      transform="rotate(12.9046 29.0794 84.2327)"
      fill="#949494"
    />
    <rect
      x="1.33997"
      y="79.9289"
      width="2"
      height="2"
      transform="rotate(12.9046 1.33997 79.9289)"
      fill="#949494"
    />
    <rect
      x="2.8428"
      y="82.3251"
      width="24"
      height="2"
      transform="rotate(12.9046 2.8428 82.3251)"
      fill="#949494"
    />
    <rect
      x="5.07608"
      y="72.5777"
      width="24"
      height="2"
      transform="rotate(12.9046 5.07608 72.5777)"
      fill="white"
    />
    <rect
      x="63.314"
      y="24.3662"
      width="2"
      height="14"
      transform="rotate(12.9046 63.314 24.3662)"
      fill="#D6EEFF"
    />
    <rect
      x="61.0807"
      y="34.1136"
      width="2"
      height="4"
      transform="rotate(12.9046 61.0807 34.1136)"
      fill="#06C9FF"
    />
    <rect
      x="56.7351"
      y="35.1698"
      width="2"
      height="2"
      transform="rotate(12.9046 56.7351 35.1698)"
      fill="#D6EEFF"
    />
  </svg>
);

export default HockeyStick;
