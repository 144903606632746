import useStore from "../../lib/store";
import { NAVIGATION_TABS } from "../../types/home";
import AccountAvatar from "../Account/AccountAvatar";
import Gem from "../icons/Gem";
import GroupIcon from "../icons/Group";
import Jersey from "../icons/Jersey";
import EarnTooltop from "./EarnTooltop";

const NavigationBar = () => {
  const activeTab = useStore(state => state.activeTab);
  const setActiveTab = useStore(state => state.setActiveTab);
  const ticketCount = useStore(state => state.ticketCount);
  const showZeroTicketModal = useStore(state => state.showZeroTicketModal);
  const showEarnToolTip = ticketCount === 0 && !showZeroTicketModal;

  const getTabContent = (tab: NAVIGATION_TABS) => {
    let icon;
    let text;
    let onClick;
    let style;
    switch (tab) {
      case NAVIGATION_TABS.PLAY:
        icon = <Jersey />;
        text = "Play";
        onClick = () => setActiveTab(NAVIGATION_TABS.PLAY);
        style = getNavButtonStyle(activeTab, NAVIGATION_TABS.PLAY);
        break;
      case NAVIGATION_TABS.EARN:
        icon = <Gem width={25} height={25} className="relative"/>;
        text = "Earn";
        onClick = () => setActiveTab(NAVIGATION_TABS.EARN);
        style = getNavButtonStyle(activeTab, NAVIGATION_TABS.EARN);
        break;
      case NAVIGATION_TABS.COMMUNITY:
        icon = <GroupIcon />;
        text = "Community";
        onClick = () => setActiveTab(NAVIGATION_TABS.COMMUNITY);
        style = getNavButtonStyle(activeTab, NAVIGATION_TABS.COMMUNITY);
        break;
      case NAVIGATION_TABS.ACCOUNT:
        icon = <AccountAvatar width={28} height={28} />;
        text = "Account";
        onClick = () => setActiveTab(NAVIGATION_TABS.ACCOUNT);
        style = getNavButtonStyle(activeTab, NAVIGATION_TABS.ACCOUNT);
        break;
    }
    return {
      icon,
      text,
      style,
      onClick
    };
  };

  const getNavButtonStyle = (activeTab: NAVIGATION_TABS, currentTab: NAVIGATION_TABS) => {
    if (activeTab === currentTab) {
      return {
        backgroundColor: "#C6F2FF",
        borderLeft: "2px solid #3AA6C7",
        borderRight: "2px solid #3AA6C7",
        borderBottom: "2px solid ##3AA6C7"
      };
    }
    return {
      backgroundColor: "#94CCDD"
    };
  };

  const getTabs = () => {
    return Object.entries(NAVIGATION_TABS).map(([tab, value]) => {
      // Activity log resides in the account tab
      if (tab === "ACTIVITY_LOG") return null;
      const { icon, text, onClick, style } = getTabContent(value);
      return (
        <button
          key={tab.toString()}
          style={style}
          className="flex flex-col items-center justify-center w-[80px] h-full"
          onClick={onClick}
        >
          {icon}
          {tab === "EARN" && showEarnToolTip && <EarnTooltop className="absolute top-[-24px]"/>}
          <div>{text}</div>
        </button>
      );
    });
  };

  return (
    <section className="flex flex-col fixed bottom-0 left-0 w-full z-10">
      <div className="flex h-[2px] w-full">
        <div className="w-[2px] h-[2px] bg-black" />
        <div className="w-[2px] h-[2px] bg-white" />
        <div className="w-full bg-extraLightBlue" />
        <div className="w-[2px] h-[2px] bg-white" />
        <div className="w-[2px] h-[2px] bg-black" />
      </div>
      <div className="flex w-full">
        <div className="flex flex-col w-full">
          <div className="bg-extraLightBlue w-[3px] h-[2px]" />
          <div className="bg-skyBlue h-full" />
          <div className="bg-extraLightBlue w-[3px] h-[2px]" />
        </div>
        <div className="flex px-6 bg-secondaryBlue w-full h-[77px] gap-4 items-start border-b-2 border-b-skyBlue">
          {getTabs()}
        </div>
        <div className="flex flex-col w-full">
          <div className="bg-secondaryBlue w-[3px] h-[2px]" />
          <div className="bg-skyBlue h-full" />
          <div className="bg-secondaryBlue w-[3px] h-[2px]" />
        </div>
      </div>
    </section>
  );
};

export default NavigationBar;
