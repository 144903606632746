const GroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27" fill="none">
    <g id="Group 94">
      <g id="Group 92">
        <rect
          id="Rectangle 486"
          x="13.0772"
          y="20.2744"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 507"
          x="13.0772"
          y="21.5222"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 487"
          x="13.0772"
          y="17.7791"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 488"
          x="28.0493"
          y="17.7791"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 489"
          x="15.5726"
          y="15.2837"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 490"
          x="14.3249"
          y="16.5315"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 491"
          x="26.8017"
          y="16.5315"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 492"
          x="24.3063"
          y="15.2837"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 493"
          x="14.3249"
          y="12.7886"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 494"
          x="14.3249"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 495"
          x="15.5726"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 496"
          x="16.8203"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.554 1.55933H18.0679V2.807H25.554V1.55933ZM26.8017 2.80709H16.8203V4.05462H15.5726V5.3023H28.0494V4.05462H26.8017V2.80709ZM26.8017 12.7885H15.5726V15.2839H18.0679V16.5315H24.3063V15.2839H26.8017V12.7885ZM26.8017 16.5316H15.5726V17.7791H14.3249V20.2744H28.0493V17.7791H26.8017V16.5316ZM28.0493 5.30239H14.3249V12.7884H28.0493V5.30239Z"
          fill="#FF90CC"
        />
        <rect
          id="Rectangle 506"
          x="16.8203"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 505"
          x="18.0679"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 497"
          x="15.5726"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 498"
          x="14.3249"
          y="4.05469"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 499"
          x="25.5539"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 500"
          x="26.8017"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 501"
          x="26.8017"
          y="12.7886"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 502"
          x="16.8203"
          y="0.311768"
          width="8.73371"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 503"
          x="13.0772"
          y="4.05469"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
        <rect
          id="Rectangle 504"
          x="28.0493"
          y="4.05469"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
      </g>
      <g id="Group 93">
        <rect
          id="Rectangle 486_2"
          x="0.600464"
          y="20.2744"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 508"
          x="0.600464"
          y="21.5222"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 487_2"
          x="0.600464"
          y="17.7791"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 488_2"
          x="15.5726"
          y="17.7791"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 489_2"
          x="3.09576"
          y="15.2837"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 490_2"
          x="1.84811"
          y="16.5315"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 491_2"
          x="14.3248"
          y="16.5315"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 492_2"
          x="11.8295"
          y="15.2837"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 493_2"
          x="1.84811"
          y="12.7886"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 494_2"
          x="1.84811"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 495_2"
          x="3.09576"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 496_2"
          x="4.34353"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <path
          id="Union_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0772 1.55933H5.59118V2.807H13.0772V1.55933ZM14.3249 2.80709H4.34353V4.05462H3.09576V5.3023H15.5725V4.05462H14.3249V2.80709ZM14.3248 12.7885H3.09576V15.2839H5.59118V16.5315H11.8295V15.2839H14.3248V12.7885ZM14.3248 16.5316H3.09576V17.7791H1.84811V20.2744H15.5725V17.7791H14.3248V16.5316ZM15.5725 5.30239H1.84811V12.7884H15.5725V5.30239Z"
          fill="#FF006A"
        />
        <rect
          id="Rectangle 506_2"
          x="4.34353"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 507_2"
          x="4.34353"
          y="4.05469"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 505_2"
          x="5.59117"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 497_2"
          x="3.09576"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 498_2"
          x="1.84811"
          y="4.05469"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 499_2"
          x="13.0772"
          y="1.55933"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 500_2"
          x="14.3248"
          y="2.80713"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 501_2"
          x="14.3248"
          y="12.7886"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 502_2"
          x="4.34353"
          y="0.311768"
          width="8.73372"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 503_2"
          x="0.600464"
          y="4.05469"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
        <rect
          id="Rectangle 504_2"
          x="15.5726"
          y="4.05469"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
      </g>
      <g id="Group 91">
        <rect
          id="Rectangle 467"
          x="6.83884"
          y="24.0178"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 489_3"
          x="6.83884"
          y="25.2654"
          width="16.2198"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 468"
          x="6.83884"
          y="21.5225"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 482"
          x="21.811"
          y="21.5225"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 469"
          x="9.33425"
          y="19.0271"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 480"
          x="8.08649"
          y="20.2747"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 481"
          x="20.5632"
          y="20.2747"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 479"
          x="18.0679"
          y="19.0271"
          width="2.49535"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 470"
          x="8.08649"
          y="16.5317"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 472"
          x="8.08649"
          y="6.55029"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 473"
          x="9.33425"
          y="5.30273"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 483"
          x="10.5819"
          y="5.30273"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <path
          id="Union_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.3156 5.30273H11.8295V6.55041H19.3156V5.30273ZM20.5633 6.5505H10.5819V7.79803H9.33425V9.0457H21.811V7.79803H20.5633V6.5505ZM20.5633 16.5319H9.33425V19.0273H11.8295V20.2747H9.33425V21.5224H20.5633V20.2747H18.0679V19.0273H20.5633V16.5319ZM8.08649 21.5225H21.8109V24.0179H8.08649V21.5225ZM21.8109 9.0458H8.08649V16.5318H21.8109V9.0458Z"
          fill="#79EED9"
        />
        <rect
          id="Rectangle 488_3"
          x="11.8295"
          y="5.30273"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 484"
          x="9.33425"
          y="6.55029"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 486_3"
          x="10.5819"
          y="7.79785"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 487_3"
          x="10.5819"
          y="6.55029"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 485"
          x="8.08649"
          y="7.79785"
          width="1.24767"
          height="1.24767"
          fill="white"
        />
        <rect
          id="Rectangle 475"
          x="19.3156"
          y="5.30273"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 476"
          x="20.5632"
          y="6.55029"
          width="1.24767"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 478"
          x="20.5632"
          y="16.5317"
          width="1.24767"
          height="2.49535"
          fill="black"
        />
        <rect
          id="Rectangle 474"
          x="10.5819"
          y="4.05493"
          width="8.73371"
          height="1.24767"
          fill="black"
        />
        <rect
          id="Rectangle 471"
          x="6.83884"
          y="7.79785"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
        <rect
          id="Rectangle 477"
          x="21.811"
          y="7.79785"
          width="1.24767"
          height="8.73371"
          fill="black"
        />
      </g>
    </g>
  </svg>
);

export default GroupIcon;
