"use client";

import { openMiniappLink } from "../../utils/utils";
import Button from "../Form/Button";
import IconArrow from "../icons/IconArrow";
import IconDiscord from "../icons/IconDiscord";
import IconX from "../icons/IconX";

const AccountFooter = () => {
  const buttonContent = () => (
    <div className="flex justify-center items-start mt-1 gap-[10px]">
      <IconArrow />
      <div className="mt-[-10px]">{"ABOUT $SCOR"}</div>
    </div>
  );

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-[8px]">
        <button onClick={() => openMiniappLink("https://x.com/scortoken") }><IconX /></button>
        <button onClick={() => openMiniappLink("https://discord.gg/scortoken")}><IconDiscord /></button>
        <Button
          width={160}
          height={42}
          onClick={() => openMiniappLink("https://scor.io")}
          text={buttonContent()}
          fontSize="14px"
        />
      </div>
      <div className="flex justify-between items-center text-[14px] py-[12px] text-white px-[16px]">
        <button onClick={() => openMiniappLink("https://scor.io/whitepaper") }>$SCOR WHITEPAPER</button>
        <button onClick={() => openMiniappLink("https://scor.io/privacy") }>PRIVACY POLICY</button>
        <button onClick={() => openMiniappLink("https://scor.io/terms") }>TERMS OF USE</button>
      </div>
    </div>
  );
};

export default AccountFooter;
