import Ticket from "../icons/Ticket";

const RewardTickets = ({
  dailyRewardTickets,
  width = 132
}: {
  dailyRewardTickets: number
  width?: number
}) => (
  <div className="flex flex-col items-center z-10">
    {/* TOP BORDER */}
    <div className="bg-black h-[4px]" style={{ width: width - 6 }} />
    <div className="flex">
      <div className="bg-black w-[4px] h-[4px]" />
      <div className="bg-teal h-[4px]" style={{ width: width - 8 }} />
      <div className="bg-black w-[4px] h-[4px]" />
    </div>
    {/* MAIN CONTAINER */}
    <div className="flex">
      <div className="bg-black w-[4px]" />
      <div className="bg-teal" style={{ width: width }}>
        <div className="flex flex-col items-center justify-center">
          <div className="flex">
            <div className="bg-teal w-[4px] h-[4px]" />
            <div className="bg-white h-[4px]" style={{ width: width - 16 }} />
            <div className="bg-teal w-[4px] h-[4px]" />
          </div>
          <div className="flex">
            <div className="bg-white w-[4px] h-[24px]" />
            <div className="bg-teal h-[4px]" style={{ width: width - 16 }} />
            <div className="bg-white w-[4px] h-[4px]" />
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center justify-center text-black mb-2">
          <Ticket width={60} height={44} />
          <div className="flex flex-col items-center justify-center">
            <p className="text-3xl">{dailyRewardTickets}</p>
            <p className="text-lg">{dailyRewardTickets > 1 ? "Tickets" : "Ticket"}</p>
          </div>
        </div>
      </div>
      <div className="bg-black w-[4px]" />
    </div>
    {/* BOTTOM BORDER */}
    <div className="flex">
      <div className="bg-black w-[4px] h-[4px]" />
      <div className="bg-darkTeal w-[4px] h-[4px]" />
      <div className="bg-teal h-[4px]" style={{ width: width - 8 }} />
      <div className="bg-darkTeal w-[4px] h-[4px]" />
      <div className="bg-black w-[4px] h-[4px]" />
    </div>
    <div className="flex">
      <div className="bg-black w-[4px] h-[4px]" />
      <div className="bg-darkTeal h-[4px]" style={{ width: width - 8 }} />
      <div className="bg-black w-[4px] h-[4px]" />
    </div>
    <div className="bg-black h-[4px]" style={{ width: width - 8 }} />
  </div>
);

export default RewardTickets;
