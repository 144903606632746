const Alert = ({ message, icon }: { message: string, icon: JSX.Element }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-[4px] h-[18px] bg-black" />
      <div className="w-[4px] h-[26px] bg-black" />
      <div className="flex w-[124px] h-[34px] justify-center items-center gap-2 bg-black text-white text-base">
        {icon || null}
        {message}
      </div>
      <div className="w-[4px] h-[26px] bg-black" />
      <div className="w-[4px] h-[18px] bg-black" />
    </div>
  );
};

export default Alert;
