import Image from "next/image";
import { getImageUrl, resizerLoader } from "sweet-lib/utils/image.js";

interface OptimizedImageProps {
  data?: {
    image?: {
      thumbnail?: {
        [key: string]: {
          width: number;
          height: number;
        };
      };
    };
    name?: string;
  };
  width?: number | null;
  height?: number | null;
  qaComponent?: string;
  alt?: string | null;
  src: string;
  className?: string;
}

const OptimizedImage = ({
  data = {},
  width = null,
  height = null,
  qaComponent = "",
  alt = null,
  src,
  ...props
}: OptimizedImageProps) => {
  // max width and height rendered needs to be communicated
  // in order to present image size options, and 1x / 2x
  // versions will be supplied.
  // if no image present yet (waiting on API response state variable)
  // a default grey image will be substituted
  const thumbnails = data?.image?.thumbnail;
  const alt_text = alt || data?.name || "";
  const target_width = width || 500;
  // we want to match the height to the source image, which is
  // available for virtually all images except very old ones via the thumbnails
  // but if we don't know it, we'll just set it to slightly tall
  let target_height = height ?? 0;
  if (!height) {
    let aspect_ratio = 0.75;
    if (thumbnails) {
      // get any thumbnail, doesn't matter
      const first_thumbnail = thumbnails[Object.keys(thumbnails)[0]];
      aspect_ratio = first_thumbnail.width / first_thumbnail.height;
    }
    target_height = Math.ceil(target_width / aspect_ratio);
  }
  const url = getImageUrl(data);
  return (
    <Image
      src={ url || src || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII=" }
      loader={ resizerLoader }
      fill={ false }
      width={ target_width }
      height={ target_height }
      data-qa-component={ qaComponent }
      alt={ alt_text }
      { ...props }
    />
  );
};

export default OptimizedImage;
