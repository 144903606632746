import ArrowButton from "../icons/ArrowButton";
import Button from "../Form/Button";

interface NavigationButtonsProps {
  gameScreen: string;
  gameScreens: string[];
  scrollLeft: () => void;
  scrollRight: () => void;
  getPlayButtonFunction: () => () => void;
  ticketCount: number | undefined;
}

const NavigationButtons = ({ gameScreen, gameScreens, scrollLeft, scrollRight, getPlayButtonFunction, ticketCount }: NavigationButtonsProps) => (
  <div className="flex justify-center mt-2 mb-4 px-4 gap-6">
    <button disabled={gameScreen === gameScreens[0]} onClick={scrollLeft}>
      <ArrowButton disabled={gameScreen === gameScreens[0]} />
    </button>
    <Button
      width={158}
      height={66}
      text="PLAY"
      onClick={getPlayButtonFunction()}
      disabled={!ticketCount || ticketCount === 0}
      thickBottom={true}
    />
    <button disabled={gameScreen === gameScreens[gameScreens.length - 1]} onClick={scrollRight}>
      <ArrowButton direction="right" disabled={gameScreen === gameScreens[gameScreens.length - 1]} />
    </button>
  </div>
);

export default NavigationButtons;