import { useEffect, useState } from "react";
import { copyToClipboard } from "../../utils/utils";
import { getRequest } from "../../utils/requests";
import Alert from "../Alert";
import CopyIcon from "../icons/CopyIcon";
import Button from "../Form/Button";

interface InviteButtonProps {
  text?: string
  width?: number
  height?: number
  textSize?: string
  popupOffset?: string
}

const InviteButton = ({
  width,
  height,
  textSize = "2xl",
  popupOffset = "70px",
  text = ""
}: InviteButtonProps) => {
  const [referralLink, setReferralLink] = useState("");
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const fetchReferralLink = async () => {
    const response = await getRequest("miniapp/referral_code");
    const { status, data } = response;
    if (status === 200 && data?.success) {
      const { data: responseData } = data;
      if (responseData?.referral_code) {
        setReferralLink(
          `https://t.me/${process.env.NEXT_PUBLIC_BOT_HANDLE}?startapp=${responseData?.referral_code}`
        );
      }
    }
  };

  const copyLink = () => {
    copyToClipboard(referralLink);
    setIsLinkCopied(true);
  };

  useEffect(() => {
    if (isLinkCopied) {
      const timeout = setTimeout(() => {
        setIsLinkCopied(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [isLinkCopied]);

  useEffect(() => {
    fetchReferralLink();
  }, []);

  return (
    <div className="flex items-center justify-center relative">
      {isLinkCopied && (
        <div className="absolute z-10" style={{ bottom: popupOffset }}>
          <Alert message={"Link Copied!"} icon={<CopyIcon />} />
        </div>
      )}
      <Button
        onClick={copyLink}
        width={width}
        height={height}
        textSize={textSize}
        text={text}
        thickBottom={true}
      />
    </div>
  );
};

export default InviteButton;
