import Box from "../Box";
import Ticket from "../icons/Ticket";

const EarnTooltop = ({ className = "" }) => {
  const content = (
    <div className="flex justify-center items-center gap-[8px] text-white text-[14px]">
      <div className="mt-[2px]">GET MORE</div>
      <Ticket />
    </div>
  );

  return (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <Box
        content={ content }
        width={100}
        backgroundColor="#FF6A3C"
        bottomAccentColor="transparent"
        borderWidth={2}
      />
      <div className="relative mt-[-2px] flex flex-col items-center">
        <div className="w-[8px] h-[2px] bg-orange" />
        <div className="flex">
          <div className="w-[2px] h-[6px] bg-black" />
          <div className="w-[4px] h-[6px] bg-orange" />
          <div className="w-[2px] h-[6px] bg-black" />
        </div>
        <div className="w-[4px] h-[2px] bg-black" />
      </div>
    </div>
  );
};

export default EarnTooltop;
