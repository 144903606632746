interface BoxProps {
  content: JSX.Element,
  className?: string,
  topColor?: string,
  bottomBorderColor?: string,
  bottomAccentColor?: string,
  width?: number,
  backgroundColor?: string
  backgroundImage?: string
  borderWidth?: number,
}

const Box = ({
  content,
  className = "",
  width = 346,
  backgroundColor="#F1F2F2",
  bottomAccentColor="#D9D9D9",
  backgroundImage,
  borderWidth = 4,
}: BoxProps) => {
  const borderWidthPx = `${borderWidth}px`;
  const clipCutWidthPx = `${borderWidth * 2}px`;
  const clipPath = `polygon(${clipCutWidthPx} ${clipCutWidthPx}, ${clipCutWidthPx} 0, calc(100% - ${clipCutWidthPx}) 0, calc(100% - ${clipCutWidthPx}) ${clipCutWidthPx}, 100% ${clipCutWidthPx}, 100% calc(100% - ${clipCutWidthPx}), calc(100% - ${clipCutWidthPx}) calc(100% - ${clipCutWidthPx}), calc(100% - ${clipCutWidthPx}) 100%, ${clipCutWidthPx} 100%, ${clipCutWidthPx} calc(100% - ${clipCutWidthPx}), 0 calc(100% - ${clipCutWidthPx}), 0 ${clipCutWidthPx})`;
  return (
    <div
      className={`relative flex flex-col items-center justify-center shadow-lg rounded-lg ${className}`}
      style={{ width: `${width}px` }}
    >
      <div
        className="absolute bg-black"
        style={{ width: borderWidth, height: borderWidth, left: borderWidth, top: borderWidth }}
      />
      <div
        className="absolute bg-black"
        style={{ width: borderWidth, height: borderWidth, right: borderWidth, top: borderWidth }}
      />
      <div
        className="absolute bg-black"
        style={{ width: borderWidth, height: borderWidth, left: borderWidth, bottom: borderWidth }}
      />
      <div
        className="absolute bg-black"
        style={{ width: borderWidth, height: borderWidth, right: borderWidth, bottom: borderWidth }}
      />
      <div
        className="relative flex flex-col items-center justify-center text-black w-full"
        style={{
          clipPath: clipPath,
          border: `${borderWidthPx} solid black`,
          backgroundColor: backgroundColor,
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="absolute"
          style={{ backgroundColor: bottomAccentColor, width: borderWidth, height: borderWidth, left: 0, bottom: borderWidth }}
        />
        <div
          className="absolute"
          style={{ backgroundColor: bottomAccentColor, width: borderWidth, height: borderWidth, right: 0, bottom: borderWidth }}
        />
        <div
          className="absolute w-full"
          style={{ backgroundColor: bottomAccentColor, height: borderWidth, bottom: 0 }}
        />
        <div className="w-full justify-center">
          {content}
        </div>
      </div>
    </div>
  );
};

export default Box;
