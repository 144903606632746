import { create } from "zustand";
import { User } from "../types/user";
import { NAVIGATION_TABS } from "../types/home";
import { REWARD_TYPES } from "../types/earn";
import { COMMUNITY_TABS } from "../types/community";
import { ACTIVITY_TYPES } from "../types/activity";

interface State {
  user: User | undefined
  setUser: (user: User) => void
  gemCount: number | undefined
  setGemCount: (gemCount: number | undefined) => void
  ticketCount: number | undefined
  setTicketCount: (ticketCount: number | undefined) => void
  nextDailyReward: number | undefined
  setNextDailyReward: (nextDailyReward: number | undefined) => void
  sendAppStart: boolean
  setSendAppStart: (sendAppStart: boolean) => void
  streak: number
  setStreak: (streak: number) => void
  showZeroTicketModal: boolean
  setShowZeroTicketModal: (showZeroTicketModal: boolean) => void
  activeTab: NAVIGATION_TABS
  setActiveTab: (activeTab: NAVIGATION_TABS) => void
  activeEarnTab: REWARD_TYPES
  setActiveEarnTab: (activeEarnTab: REWARD_TYPES) => void
  activeCommunityTab: COMMUNITY_TABS
  setActiveCommunityTab: (activeCommunityTab: COMMUNITY_TABS) => void
  activeActivityTab: ACTIVITY_TYPES
  setActiveActivityTab: (activeActivityTab: ACTIVITY_TYPES) => void
}

const useStore = create<State>(set => ({
  user: undefined,
  setUser: (user: User) => set(() => ({ user })),
  gemCount: undefined,
  setGemCount: (gemCount: number | undefined) => set(() => ({ gemCount })),
  ticketCount: undefined,
  setTicketCount: (ticketCount: number | undefined) => set(() => ({ ticketCount })),
  nextDailyReward: undefined,
  setNextDailyReward: (nextDailyReward: number | undefined) => set(() => ({ nextDailyReward })),
  sendAppStart: false,
  setSendAppStart: (sendAppStart: boolean) => set(() => ({ sendAppStart })),
  streak: 0,
  setStreak: (streak: number) => set(() => ({ streak })),
  showZeroTicketModal: false,
  setShowZeroTicketModal: (showZeroTicketModal: boolean) => set(() => ({ showZeroTicketModal })),
  activeTab: NAVIGATION_TABS.PLAY,
  setActiveTab: (activeTab: NAVIGATION_TABS) => set(() => ({ activeTab})),
  activeEarnTab: REWARD_TYPES.GEMS,
  setActiveEarnTab: (activeEarnTab: REWARD_TYPES) => set(() => ({ activeEarnTab })),
  activeCommunityTab: COMMUNITY_TABS.INVITE,
  setActiveCommunityTab: (activeCommunityTab: COMMUNITY_TABS) => set(() => ({ activeCommunityTab })),
  activeActivityTab: ACTIVITY_TYPES.GEMS,
  setActiveActivityTab: (activeActivityTab: ACTIVITY_TYPES) => set(() => ({ activeActivityTab })),
}));

export default useStore;
